// Adboard item styles
//----------------------------------------------------

//.adboard-item
.adboard-item {
    height: calc(100vh - 79px);

    //.adboard-item__comments
    &__comments {
        background: rgb(236, 236, 236);

        //.adboard-item__comments-title
        &-title {
            background: $color_secondary;
            height: 50px;
            color: white;
            padding: 12px 6%;
            font-size: 18px;
            @include fontMainBold;
        }

        //.adboard-item__comments-close
        &-close {
            float: right;
            color: white;
            cursor: pointer;
        }

        //.adboard-item__comments-form
        &-form {
            min-height: 110px;
            background: white;
            padding: 20px 6%;
        }

        //.adboard-item__comments-list
        &-list {
            background: rgb(236, 236, 236);
            padding: 1em 6%;
            overflow-y: scroll;
        }
    }

    //.adboard-item__info
    &__info {
        padding: 50px 30px 0;
        position: relative;

        //.adboard-item__info-version
        &-version {

            //.adboard-item__info-version ul
            ul {
                list-style: none;
                padding: 0;
            }

            //.adboard-item__info-version li
            li {
                list-style: none;
            }
        }

        //.adboard-item__info-zinc
        &-zinc {
            margin: 5px 0;
        }

        //.adboard-item__info-group
        &-group {
            margin: 5px 0;
        }

        //.adboard-item__info-description
        &-description {
            margin: 10px 0;
        }

        //.adboard-item__info-actions
        &-actions {
            margin: 40px 0 10px;
        }

        //.adboard-item__info-close
        &-close {
            color: white;
            position: absolute;
            right: 15px;
            top: 10px;
            font-size: 20px;
            text-shadow: 1px 1px 3px #aaa;
            cursor: pointer;
        }
    }

    //.adboard-item__doc
    &__doc {
        background: rgb(103, 107, 108);

        //.adboard-item__doc-content
        &-content {
            padding: 50px;
            text-align: center;
            margin: 0 auto;
            overflow: auto;
        }

        //.adboard-item__doc-image
        &-image {
            display: flex;
            position: relative;
            align-items: flex-start;
            justify-content: center;

            //.adboard-item__doc-image img
            /*
            img {
                max-width: 100%;
                max-height: 100%;
                width: auto;
                height: auto;
                position: absolute;
            }
            */
        }

        //.adboard-item__doc-pins
        &-pins {
            position: absolute;
            cursor: pointer;

            //.adboard-item__doc-pins .popover
            .popover {
                color: #333;
                line-height: 1em;
                background: white;
                max-width: 400px;
                padding: 15px 10px;

                //.adboard-item__doc-pins .popover .arrow,.adboard-item__doc-pins .popover .tooltip-arrow
                .arrow, .tooltip-arrow {
                    display: block;
                }
            }
        }
    }

    //.adboard-item .adboard-item-comment
    .adboard-item-comment {
        margin-bottom: 25px;
        background: white;
    }

    //.adboard-item .adboard-title-container
    .adboard-title-container {
        font-size: 21px;
        background: #4D5355;
        padding: 0.8em 2em;
        height: 62px;
        text-align: left;
        color: white;

        a.zoom-button > i {
            color: #ddd4b0;
        }
    }

    //.adboard-item .adboard-sticky-help-text
    .adboard-sticky-help-text {
        position: absolute;
        bottom: -1.5em;
        color: white;
        text-shadow: 1px 1px 1px #666;
    }

    //.adboard-item .adboard-versions-list
    .adboard-versions-list {

        //.adboard-item .adboard-versions-list .mcm-box-container
        .mcm-box-container {
            background: white;
        }


        .adboard-item-version__download {
            i {
                color: #999;
                transition: 0.2s all;
            }
            &:hover {
                i {
                    color:#333;
                }
            }
        }
    }

    //.adboard-item .adboard-current-version
    .adboard-current-version {

        //.adboard-item .adboard-current-version.mcm-box-container
        &.mcm-box-container {
            transition: 0.4s all;
            color: white !important;
        }
    }

    //.adboard-item .adboard-versions-list-container
    .adboard-versions-list-container {
        overflow-y: scroll;
    }

    //.adboard-item .adboard-item__toggle-info
    .adboard-item__toggle-info {
        text-align: center;
        color: white;
        position: absolute;
        cursor: pointer;
        width: 20px;
        height: 23px;
        padding: 3px;
        right: 0;
        top: 100px;
        text-decoration: none !important;
        @include null(border-top-left-radius border-bottom-left-radius, 3px);

    }

    //.adboard-item__approved-text-in-title
    &__approved-text-in-title {
        float: right;
    }

    //.adboard-item-body
    &-body {
        overflow: hidden;

        //.adboard-item-body .navbar
        .navbar {
            margin-bottom: 0;
        }

        //.adboard-item-body .mcm-navigation-breadcrumb
        .mcm-navigation-breadcrumb {
            margin-bottom: 0;
        }
    }

    //.adboard-item-btns
    &-btns {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

//.adboard-item-pagination
.adboard-item-pagination {

    //.adboard-item-pagination .pagination
    .pagination {
        margin: 0 0;
        font-size: 11px;
    }
}

//.adboard-popover-comment
.adboard-popover-comment {

    //.adboard-popover-comment .adboard-popover-comment__name
    .adboard-popover-comment__name {
        font-weight: bold;
    }

    //.adboard-popover-comment .adboard-popover-comment__message
    .adboard-popover-comment__message {
        margin-bottom: 15px;

        //.adboard-popover-comment .adboard-popover-comment__message p
        p {
            margin-bottom: 0.5em;
        }
    }

}

//.adboard-item__toggler
.adboard-item__toggler {
    position: fixed;
    cursor: pointer;
    text-align: center;
    color: white;
    width: 75px;
    height: 64px;
    top: 160px;
    font-size: 35px;
    padding-top: 5px;
    transition: 0.5s all;
    transition-delay: 0.5s;
    background: $color_primary;
    @include flexCenter();

    //.adboard-item__toggler--left
    &--left {
        left: 0px;
        border-radius: 0 4px 4px 0;

        //.adboard-item__toggler--left.ofhidden
        &.ofhidden {
            transition-delay: 0s;
            left: -100px
        }
    }

    //.adboard-item__toggler--right
    &--right {
        right: 0px;
        border-radius: 4px 0 0 4px;

        //.adboard-item__toggler--right.ofhidden
        &.ofhidden {
            transition-delay: 0s;
            right: -100px
        }
    }
}

//.adboard-item-comments
.adboard-item-comments {
    padding: 0;
    margin: 0 0 50px;

    //.adboard-item-comments li
    li {
        list-style: none;
    }
}

//.adboard-item-comment
.adboard-item-comment {
    color: $color_textgrey;
    position: relative;

    //.adboard-item-comment.has-replies
    &.has-replies {
        margin-bottom: 3px;
    }

    //.adboard-item-comment__sticky-badge
    &__sticky-badge {
        @extend .adboard-item-pin;
        position: absolute;
        margin-left: -26px;
    }

    //.adboard-item-comment__user
    &__user {
        font-size: 16px;
        color: $color_textgrey;
        @include fontMainSemiBold;

    }

    //.adboard-item-comment__reply
    &__reply {
        font-size: 14px;
        float: left;
        cursor: pointer;
        text-decoration: none;
        font-weight: bold;
    }

    //.adboard-item-comment__content
    &__content {
        padding-left: 10px;
    }

    //.adboard-item-comment__subcomments
    &__subcomments {
        margin: 10px 0 0 10px;
        padding: 0;

        //.adboard-item-comment__subcomments p
        p {
            margin: 5px 0;
        }
    }

    //.adboard-item-comment__subcomments-user
    &__subcomments-user {
        @include fontMainSemiBold;
    }

    //.adboard-item-comment__date
    &__date {
        font-size: 11px;
    }

    //.adboard-item-comment .adboard-item-comment__resolve
    .adboard-item-comment__resolve {
        display: inline-block;
        float: right;

        //.adboard-item-comment .adboard-item-comment__resolve .checkbox,.adboard-item-comment .adboard-item-comment__resolve label
        .checkbox, label {
            margin: 0;
        }

        //.adboard-item-comment .adboard-item-comment__resolve label
        label {
            display: flex;
            align-items: center;
            font-size: $font-size-base - 2;

            //.adboard-item-comment .adboard-item-comment__resolve label > span
            > span {
                &:not(.checkbox-material) {
                    margin-right: 10px;
                }
            }
        }
    }

    //.adboard-item-comment.adboard-item-subcomment
    &.adboard-item-subcomment {

        //.adboard-item-comment.adboard-item-subcomment .adboard-item-comment__reply
        .adboard-item-comment__reply {
            font-size: 12px;

            //.adboard-item-comment.adboard-item-subcomment .adboard-item-comment__reply button
            button {
                color: white;
            }
        }

    }

    //.adboard-item-comment__close-reply
    .adboard-item-comment__close-reply {
        font-size: 16px;
        color: #333;
        position: absolute;
        right: 10px;
        cursor: pointer;

        &:hover {
            color: #999;
        }
    }
}

//.adboard-item-pin
.adboard-item-pin {
    background: #f15b40;
    width: 30px;
    height: 30px;
    display: inline-block;
    position: absolute;
    border-radius: 100%;
    cursor: pointer;
    text-align: center;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .31);
    opacity: 0.8;
    color: #ffffff;
    padding-top: 6px;
    @include fontMainBold;

    &:hover {
        opacity: 1;
    }

    //.adboard-item-pin.ui-draggable-dragging
    &.ui-draggable-dragging {
        opacity: 0.5;
    }

    //.adboard-item-pin--new
    &--new {
        opacity: 1;
    }
}

//.adboard-item-versions
.adboard-item-versions {

    //.adboard-item-versions ul
    ul {
        list-style: none;

        //.adboard-item-versions li
        li {
            margin: 10px 0;
            padding: 10px 20px;
            transition: 0.5s all;

            &:hover {
                background: #f5f5f5;

                .adboard-item-versions__download {
                    i {
                        color: #333;
                    }
                }
            }
        }
    }

    //.adboard-item-versions .adboard-item-versions__switch
    .adboard-item-versions__switch {
        margin: 0 0 0 15px;
        float: right;
    }


}

//.comment-is-resolved
.comment-is-resolved {
    color: green;
}