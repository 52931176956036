.top-menu {
  color: #666;
  margin-bottom: 2em;

  .navbar,
  .navbar.navbar-default {
    background: white;
  }

  .navbar .navbar-nav,
  .navbar .navbar-nav > li > a {
    color: #666;
  }

  .navbar-brand {
    &.navbar-brand--superadmin {
      padding: 20px 15px 15px;
    }

    img {
      max-height: 100%;
    }
  }

  .top-menu__title {
    position: relative;
    float: left;
    display: inline-block;
    padding: 20px 0 10px;
    color: $color_textgrey;
    font-size: 21px;
    @include fontMainSemiBold;
  }

  .top-menu__content {
    margin: 20px 0 20px 10px;

    a {
      color: $color_textgrey;
      margin-right: 20px;
    }

    .top-menu__upper {
      font-size: 14px;
      @include fontMainLight;
      .top-menu__upper-list {
        display: inline-block;
        padding: 0;
        li {
          display: inline-block;
          position: relative;
          transition: 0.3s all;
          &:hover {
            a {
              color: $color_primary;
            }
            .sub-menu {
              display: inline-block;

              a {
                color: white;
              }
            }

          }
          .sub-menu {
            display: none;
            transition: 0.3s all;
            padding: 10px 0;
            position: absolute;
            background: $color_primary;
            width: 180px;
            border-top: 2px solid white;
            z-index: 101;
            &:hover {

            }
            li {
              a {
                padding: 5px 20px;
                display: block;
                width: 100%;
                color: white;
                text-decoration: none;
                &:hover {
                  color: #333;
                }
              }
            }
          }
        }
      }
    }

    .top-menu__lower {
      font-size: 14px;
      @include fontMainLight;
    }

    .top-menu__breadcrumb {
      font-size: 14px;
      @include fontMainLight;
      margin: 15px 0 0;

      i {
        color: $color_primary;
        margin-right: 10px;
      }

      a {
        color: $color_primary;
        margin-right: 10px;
        border-bottom: 1px solid $color_primary;
        transition: 0.3s all;
        padding-bottom: 1px;
        text-decoration: none;
        &:hover {
          border-bottom: 1px solid $color_primary+(-40);;
          color: $color_primary + (-40);
        }
      }
    }
  }

  .top-menu__menu-container {
    float: left;
    width: 100%;

    .top-menu__logo-container {
      width: 200px;
    }
  }
}

///*------------------------------------*\
//    Top Menu HCP
//\*------------------------------------*/

.top-menu {
  &.top-menu--hcp {
    height: 170px;
    min-height: 170px;

    @include mq($until: tablet) {
      height: auto;
      min-height: auto;
      border-bottom: 1px solid #ccc;
    }

    .navbar,
    .navbar-default {
      margin-bottom: 0;
      height: 100%;
    }

    .navbar-brand {
      max-height: 100px;
      height: auto;
    }

    .top-menu__menu-container {
      margin-top: 10px;
    }

    .top-menu__logo-container {
      width: 260px;
      padding-top: 00%;
      @include mq($until: tablet) {
        width: 100%;
      }
    }

    .top-menu__title {
      position: relative;
      float: left;
      display: inline-block;
      padding: 0px 0 0px;
      color: $color_textgrey;
      font-size: 28px;
      @include fontMainSemiBold;
      line-height: 1.1;
    }

    .top-menu__subtitle {
      position: relative;
      float: left;
      display: inline-block;
      color: $color_textgrey;
      font-size: 28px;
      line-height: 1.2;
      margin: 0 0 20px;
    }

    .top-menu__content {
      margin-left: 10px;
    }
  }

  .top-menu-mobile__logo {
    text-align: center;
    max-width: 80%;
    margin: 0 auto;
    padding: 1em 0;
  }

  .top-menu-mobile__links {
    text-align: center;
    margin: 10px 0 20px;
    a {
      padding: 0 20px;
      display: inline-block;
      color: $color_text;
    }
  }

  .top-menu-mobile__title {
    color: $color_textgrey;
    text-align: center;
    margin: 0px 0 20px;

  }
}

.language-dd {
  display: inline-block;
  margin-right: 10px;
  position: relative;

  &:hover {
    .language-dd__current {
      font-weight: bold;
    }
    .language-dd__list {
      display: block;
    }

  }

  .language-dd__current {
    cursor: pointer;
    text-decoration: none;
    transition: 0.3s all;

  }
  .language-dd__list {
    display: none;
    position: absolute;
    margin: 0;
    text-align: right;
    right: 20px;
    padding: 10px 0px 10px 10px;
    box-shadow: 1px 1px 3px 0 #999;

    a {
      text-align: right;
      display: inline-block;
    }
    li {
      width: 100%;
    }
  }
  .language-dd__selected {
    font-weight: bold;
  }

}
