.touchpoint-card {

    background: $color_blue;
    text-align: center;
    color: white;
    border: 1px solid $color_blue;
    transition: 0.3s all;

    &:hover {
        background: $color_blue + (-40);
    }

    a {
        padding: 30px 20px;
        color: white;
        display: block;
        text-decoration: none;
    }

    .touchpoint-card__icon {
        font-size: 50px;
        @include flaticonFontSize(50px);
    }

    .touchpoint-card__title {
        margin: 20px 0 10px;
        font-size: 21px;
    }

    .touchpoint-card__type {
        font-size: 12px;
        text-transform:uppercase;
    }

}