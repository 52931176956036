// Font mixins
//-------------------------------------------------------------

@mixin fontMain($weight:400) {
    font-family: "Source Sans Pro", "Helvetica Neue", Helvetica, sans-serif;
    font-weight: $weight;
}

@mixin fontText {
    font-family: 'Roboto', Arial, sans-serif;
    font-weight: 400;
}

@mixin fontMainLight {
    @include fontMain(300);
}

@mixin fontMainSemiBold {
    @include fontMain(600);
}

@mixin fontMainBold {
    @include fontMain(700);
}

@mixin fontTitle {
    @include fontMain(500);
}
