$font-family-sans-serif :"Source Sans Pro", "Helvetica Neue", Helvetica, sans-serif;

$screen-lg:1148px;
$grid-gutter-width:48px;
$container-large-desktop:(1100px + $grid-gutter-width);
$container-lg:                 $container-large-desktop;



//** Modal backdrop background color
$modal-backdrop-bg:           #000;
//** Modal backdrop opacity
$modal-backdrop-opacity:      .8;

$icon-font-path:'/components/bootstrap-sass-official/assets/fonts/';