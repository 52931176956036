// Helper classes
//-------------------------------------------------------------

//Flex Displays
$flex: flex, inline-flex, block, inline-block;
@each $display in $flex {
  .display-#{$display} {
    display: $display;
  }
  .display-#{$display}-force {
    display: $display !important;
  }
}

//Justify Content
$justify: center, flex-end, flex-start, inherit, initial, space-around, space-between;
@each $content in $justify {
  .justify-#{$content} {
    justify-content: $content;
  }
  .justify-#{$content}-force {
    justify-content: $content !important;
  }
}

//Align Items
$align: center, flex-end, flex-start, inherit, initial, baseline, stretch;
@each $items in $align {
  .align-#{$items} {
    align-items: $items;
  }
  .align-#{$items}-force {
    align-items: $items !important;
  }
}

//Align Self
$self: auto, baseline, flex-start, flex-end, initial, inherit, center, stretch;
@each $items in $self {
  .self-#{$items} {
    align-self: $items;
  }
  .self-#{$items}-force {
    align-self: $items !important;
  }
}

//Flex Direction
$direction: column, column-reverse, row, row-reverse, inherit, initial;
@each $items in $direction {
  .flex-#{$items} {
    flex-direction: $items;
  }
  .flex-#{$items}-force {
    flex-direction: $items !important;
  }
}

//Flex Wrap
$wrap: wrap, wrap-reverse, nowrap, inherit, initial;
@each $items in $wrap {
  .flex-#{$items} {
    flex-wrap: $items;
  }
  .flex-#{$items}-force {
    flex-wrap: $items !important;
  }
}

//Cursor
$cursor: pointer, default, none, inherit, initial;
@each $items in $cursor {
  .cursor-#{$items} {
    cursor: $items;
  }
  .cursor-#{$items}-force {
    cursor: $items !important;
  }
}

//Margin/Padding Nulls
$names: padding, margin, border;
$directions: top bottom right left;
@each $prop in $names {
  @each $direction in $directions {
    .#{$prop}-#{$direction}-null {
      #{$prop}-#{$direction}: 0;
    }
    .#{$prop}-#{$direction}-null-force {
      #{$prop}-#{$direction}: 0 !important;
    }
  }
}

//Center margin directions
$centerMargin: (verticle, auto 0), (horizontal, 0 auto), (auto, auto);
@each $name, $value in $centerMargin {
  .#{$name}-center {
    margin: $value;
  }
  .#{$name}-center-force {
    margin: $value !important;
  }
}

//Italic and bold
$style: bold, italic;
@each $text in $style {
  .text-#{$text} {
    @if $text == bold {
      font-weight: $text;
    } @else {
      font-style: $text;
    }
  }
  .text-#{$text}-force {
    @if $text == bold {
      font-weight: $text !important;
    } @else {
      font-style: $text !important;
    }
  }
}

//Font weight
$weight: 100, 200, 300, 400, 500, 600, 700, 800, 900;
@each $font in $weight {
  .text-#{$font} {
    font-weight: $font;
  }
  .text-#{$font}-force {
    font-weight: $font !important;
  }
}

//Reset values : none
$values: float, box-shadow, text-decoration, background-image;
@each $reset in $values {
  .no-#{$reset} {
    #{$reset}: none;
  }
  .no-#{$reset}-force {
    #{$reset}: none !important;
  }
}

//Reset values : 0
$values: margin, padding, border;
@each $reset in $values {
  .no-#{$reset} {
    #{$reset}: 0;
  }
  .no-#{$reset}-force {
    #{$reset}: 0 !important;
  }
}

//Overflow settings
$values: scroll, hidden, visible, inherit, initial, auto;
@each $overflow in $values {
  .overflow-#{$overflow} {
    overflow: $overflow;
  }
  .overflow-#{$overflow}-force {
    overflow: $overflow !important;
  }
}

$colors: (transparent 'transparent')
        ($white, 'white') //#fff
        (#ddd, 'gray') //#ddd
        ($color_text, 'color_text') //#000
        ($color_textgrey, 'color_textgrey') //#4d5254
        ($color_lightgrey 'color_lightgrey') //#eeeded
        ($color_grey 'color_grey') //#E9E7E4
        ($color_bg 'color_bg') //#CBE5D8
        ($color_orange 'color_orange') //#F58207
        ($color_red 'color_red') //#F15B40
        ($color_paleOrange 'color_paleOrange') //#EFC39D
        ($color_green 'color_green') //#00a886
        ($color_darkgreen 'color_darkgreen') //#009e7e
        ($color_yellow 'color_yellow') //#eada86
        ($color_blue 'color_blue') //#67c4f0
        ($color_pastelBlue 'color_pastelBlue'); //#75bee9

$props: color, background-color, border-color, fill, stroke;
@each $color, $prefix in $colors {
  @each $prop in $props {
    .#{$prefix}-#{$prop} {
      #{$prop}: $color;
    }
    .#{$prefix}-#{$prop}-force {
      #{$prop}: $color !important;
    }
  }
}

//Width settings
$values: (auto, auto), (inherit, inherit), (initial, initial), (10%, 10), (20%, 20), (30%, 30), (40%, 40), (50%, 50), (60%, 60), (70%, 70), (80%, 80), (90%, 90), (100%, 100);
@each $width, $classname in $values {
  .width-#{$classname} {
    width: $width;
  }
  .width-#{$classname}-force {
    width: $width !important;
  }
}

//Height settings
$values: (auto, auto), (inherit, inherit), (initial, initial), (10%, 10), (20%, 20), (30%, 30), (40%, 40), (50%, 50), (60%, 60), (70%, 70), (80%, 80), (90%, 90), (100%, 100);
@each $height, $classname in $values {
  .height-#{$classname} {
    height: $height;
  }
  .height-#{$classname}-force {
    height: $height !important;
  }
}


//Elements positions
$positions: relative, absolute, static, fixed;
@each $prop in $positions {
  .position-#{$prop} {
    position: #{$prop};
  }
  .position-#{$prop}-force {
    position: #{$prop} !important;
  }
}

//Hide element untill breakpoint
$breakpoints: (mobile, $screen-xs), (tablet, $screen-sm), (desktop, $screen-md), (large, $screen-lg);
@each $breakpoint, $width in $breakpoints {
  @media (max-width: $width) {
    .hide-#{$breakpoint} {
      display: none;
    }
    .hide-#{$breakpoint}-force {
      display: none !important;
    }
  }
}

//Width, Height, Margin, Padding settings
$props: width, height;
$names: padding, margin, border, flex;
$directions: top bottom right left;
$propValue: em;
@for $value from 0 through 10 {
  //Set Margin and Padding
  @each $prop in $names {
    @if $prop == border {
      $propValue: px solid;
    } @else {
      $propValue: em;
    }
    .#{$prop}-#{$value} {
      #{$prop}: #{$value}#{$propValue};
    }
    .#{$prop}-#{$value}-force {
      #{$prop}: #{$value}#{$propValue} !important;
    }
    @if $prop != flex {
      @each $direction in $directions {
        .#{$prop}-#{$direction}-#{$value} {
          #{$prop}-#{$direction}: #{$value}#{$propValue};
        }
        .#{$prop}-#{$direction}-#{$value}-force {
          #{$prop}-#{$direction}: #{$value}#{$propValue} !important;
        }
      }
    }
  }
  //Set width and height
  @each $prop in $props {
    .#{$prop}-#{$value*10} {
      #{$prop}: #{$value*10}#{'%'};
    }
    .#{$prop}-#{$value*10}-force {
      #{$prop}: #{$value*10}#{'%'} !important;
    }
  }
}

//Specific Values set starting from 1 to 10
$props: flex;
@for $value from 0 through 10 {
  @each $prop in $props {
    .#{$prop}-#{$value} {
      #{$prop}: #{$value};
    }
    .#{$prop}-#{$value}-force {
      #{$prop}: #{$value} !important;
    }
  }
}

//Specific Values set starting from .1 to 10
//more props can be added to list as needed
$altrenativeProps:  width, height, max-width, min-width, max-height, min-height, top, bottom, left, right;
@for $value from 0 through 10 {
  @each $prop in $altrenativeProps {
    @if $prop == padding or $prop == margin {
      @each $direction in $directions {
        .#{$prop}-#{$direction}-alt-#{$value} {
          #{$prop}-#{$direction}: #{$value/10}em;
        }
        .#{$prop}-#{$direction}-alt-#{$value}-force {
          #{$prop}-#{$direction}: #{$value/10}em !important;
        }
      }
    } @else {
      .#{$prop}-alt-#{$value} {
        #{$prop}: #{$value/10}em;
      }
      .#{$prop}-alt-#{$value}-force {
        #{$prop}: #{$value/10}em !important;
      }
    }
  }
}
//same loop as above but this is for only wanted items
//you can add values needed
@each $value in (20,30,40,30,40,50,60,70,80,90,100){
    @each $prop in $altrenativeProps {
        @if $prop == padding or $prop == margin {
            @each $direction in $directions {
                .#{$prop}-#{$direction}-alt-#{$value} {
                    #{$prop}-#{$direction}: #{$value/10}em;
                }
                .#{$prop}-#{$direction}-alt-#{$value}-force {
                    #{$prop}-#{$direction}: #{$value/10}em !important;
                }
            }
        } @else {
            .#{$prop}-alt-#{$value} {
                #{$prop}: #{$value/10}em;
            }
            .#{$prop}-alt-#{$value}-force {
                #{$prop}: #{$value/10}em !important;
            }
        }
    }
}

@for $value from 0 through 10 {
    @each $prop in $altrenativeProps {
        @if $prop == padding or $prop == margin {
            @each $direction in $directions {
                .#{$prop}-#{$direction}-alt-#{$value} {
                    #{$prop}-#{$direction}: #{$value/10}em;
                }
                .#{$prop}-#{$direction}-alt-#{$value}-force {
                    #{$prop}-#{$direction}: #{$value/10}em !important;
                }
            }
        } @else {
            .#{$prop}-alt-#{$value} {
                #{$prop}: #{$value/10}em;
            }
            .#{$prop}-alt-#{$value}-force {
                #{$prop}: #{$value/10}em !important;
            }
        }
    }
}

//from 0.1 to 2em
$props: font-size;
$altrenativeProps: padding, margin;
@for $value from 0 through 20 {
  @each $prop in $props {
    .#{$prop}-#{$value} {
      #{$prop}: #{$value/10}em;
    }
    .#{$prop}-#{$value}-force {
      #{$prop}: #{$value/10}em !important;
    }
  }
  @each $prop in $altrenativeProps {
    @if $prop == padding or $prop == margin {
      @each $direction in $directions {
        .#{$prop}-#{$direction}-alt-#{$value} {
          #{$prop}-#{$direction}: #{$value/10}em;
        }
        .#{$prop}-#{$direction}-alt-#{$value}-force {
          #{$prop}-#{$direction}: #{$value/10}em !important;
        }
      }
    } @else {
      .#{$prop}-alt-#{$value} {
        #{$prop}: #{$value/10}em;
      }
      .#{$prop}-alt-#{$value}-force {
        #{$prop}: #{$value/10}em !important;
      }
    }
  }
}