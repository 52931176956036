// Videos styles
//-------------------------------------------------------------

$gray: #9B9B9B;
$gray-light: #D8D8D8;
$gray-dark: #4A4A4A;
$sanofi-purple: #525CA3;

.tp-edit__back-btn {
  padding: 5px 0;
  .back-btn-wrapper {
    background-color: $sanofi-purple;
    width: 30px;
    height: 30px;
    display: inline-block;
    border-radius: 50%;
    color: $white;
    margin-right: 5px;
    .material-icons {
      line-height: 30px;
    }
    &:hover {
      background-color: darken($sanofi-purple, 10%);
    }
  }
}

@import 'form';
@import 'messages';
@import 'rating';
@import 'request-virtual-event';

//.videos-form-container
.videos-form-container {

  .tabs-container {
    user-select: none;
    align-items: stretch;
    justify-content: space-between;
    margin: 0 auto;
    flex-direction: column;

    .all-videos-tab {
      position: absolute;
      top: 0;
      right: 16px;

      .tooltip.left{
        margin-top: 8px;
        margin-left: -25px;
      }
      .videos-modal {
        .count {
          right: -8px;
          top: -8px;
        }
      }
    }

    &.inner {
      padding-top: 20px;
      display: flex;
      justify-content: center;
      align-items: center;

      > .tp-edit__back {
        left: 30px;
        top: 50px;
      }

      > .tabs {
        ul {
          border-bottom: none;

          a {
            font-size: 16px;
            border-bottom: none;
            padding-bottom: .5em;
            margin-bottom: -1px;
          }

          li {
            margin: 0 20px 0 20px;

            &.is-active {
              border-bottom: 2px solid #4A4A4A;
              a {
                color: black;
                span {
                  color: $sanofi-purple;
                  min-width: 20px;
                  text-align: left
                }
              }
            }
          }
        }
      }

      > .tabs-details {
        width: 60%;
        min-width: 1100px;
        margin: 30px 0 0;
      }
    }

    &:not(.inner) {
      > .tabs {
        border-bottom: 1px solid #dbdbdb;

        ul {
          max-width: 1300px;
          margin: 0;
          padding-inline-start: 0;

          a {
            padding: 1.3em;
          }
        }
      }
    }

    .tabs {
      ul {
        //margin-top: 40px;
        align-items: center;
        display: flex;
        flex-grow: 1;
        flex-shrink: 0;
        list-style: none;
        justify-content: space-between;
      }

      li {
        display: block;
        position: relative;
        cursor: pointer;
        margin-bottom: -2px;
        border-bottom: none;
        border-bottom: solid 2px transparent;
        &.is-active {
          border-bottom: solid 2px $sanofi-purple;
          a {
            font-weight: 700;
            color: $sanofi-purple;
          }
        }
      }

      a {
        align-items: center;
        //border-bottom: 1px solid #dbdbdb;
        color: #4a4a4a;
        display: flex;
        justify-content: center;
        margin-bottom: -1px;
        padding: .5em 1em;
        vertical-align: top;
        font-size: 12px;
        text-decoration: none;
      }

      a:hover {
        color: $sanofi-purple;
      }
    }
  }

  //.expandpanel-transition
  .expandpanel-transition {
    width: 100%;
    float: left;
    margin-right: 0;
    -moz-transition: 0.5s all;
    transition: 0.5s all;
    position: relative;
    background: $color_grey;
    padding: 0 40px 100px;
    min-height: 800px;
  }

  .tp-edit__back-btn {
    margin-bottom: 0;
  }

  .tp-edit__project-name {
    .go-to-website {
      font-size: 33px;
      position: absolute;
      right: 30px;
      color: $gray-light;
      cursor: pointer;
      &:hover{
        color: $sanofi-purple;
      }
    }
  }

  .tp-edit__switches {
    .tp-edit__switch {
      background: transparent;

      .tp-edit__switch-label {
        background: transparent;
      }

      &.tp-edit__switch--selected .tp-edit__switch-label {
        background-color: $sanofi-purple;

        a {
          color: $white;
        }
      }
    }
  }

  //.tp-edit__switch-label-text
  .tp-edit__switch-label-text, .tp-edit__switch-label-text:hover, .tp-edit__switch-label-text:focus {
    background-color: transparent;
    color: $gray-light;
    text-decoration: none;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .tp-edit__switch-label-content, .tp-edit__switch-label-title {
    padding: 0 !important;
    height: 100%;
  }

  .tp-edit__back {
    padding: 15px 0;
  }

  .tab_title {
    display: inline-flex;
    padding: 0 12px;
    font-weight: bold;

    .wrap-tooltip {
      position: relative;
      display: inline-block;

      &::after {
        content: '';
        display: block;
        clear: both;
      }
    }

    .tooltip.left {
      margin-top: 0px;
    }

    .videos-modal-link {
      text-align: right;
      padding-top: 10px;

      .material-icons {
        font-size: 30px;
        color: $sanofi-purple;
        cursor: pointer;
        &:hover{
          color: $gray;
        }
      }

      .count {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 19px;
        width: 19px;
        background: $white;
        top: -8px;
        right: -8px;
        border-radius: 50%;
        font-size: 11px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
        font-weight: bolder;
      }
    }
  }

  .line {
    margin: 0;
    position: absolute;
    top: 50px;
    left: 0;
    right: 0;
    border-color: $gray-light;
    background-color: $gray-light;
    color: $gray-light;
    height: 0.1px;
  }

  // NEW .thumbnail-container
  .thumbnail-container {
    margin-left: -10px;
    margin-right: -10px;
    height: 250px;
    position: relative;
    transition: box-shadow .3s;
    box-shadow: 0 0 5px 4px rgba($gray-dark, 0.1);
    background-size: cover;

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(black, 0.2);
      z-index: 0;
      opacity: 0;
      transition: opacity 0.3s ease;
    }

    .bottom-content {
      position: absolute;
      z-index: 1;
      width: 100%;
      bottom: 0;
      left: 0;
      padding: 10px;
      background-color: $white;

      .subtitle {
        font-size: 13px;
        color: $sanofi-purple;
        margin: 31px 0 0;

        &:before {
          content: '';
          position: absolute;
          width: 25px;
          height: 4px;
          background: $sanofi-purple;
          top: 50px;
          z-index: 2;
        }

        .spacer {
          margin: 5px;
        }
      }

      .title {
        color: black;
        height: 1.2em;
        margin-bottom: 5px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 1.2em;
        line-height: 1.2em;
      }

      .uploaded,
      .status {
        font-size: 0.9em;
      }

      .uploaded {
        padding-bottom: 10px;
      }

      .status {
        span {
          font-weight: 700;
        }
      }
    }

    .action-buttons {
      display: none;
      opacity: 0;
      position: absolute;
      z-index: 1;
      right: 0;
      top: 0;
      padding: 10px;
      width: 100%;
      color: $white;
      text-align: right;
      transition: opacity 0.3s ease;

      .action-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        vertical-align: top;
        width: 26px;
        height: 26px;
        margin-left: 5px;
        font-size: 18px;
        text-align: center;
        line-height: 24px;
        background-color: rgba($black, 0.5);
        border-radius: 50%;
        color: $white;
        cursor: pointer;
        transition: background-color 0.3s ease;
        text-decoration: none !important;

        .material-icons {
          font-size: 18px;
          line-height: 26px;
        }

        &:hover {
          background-color: rgba($black, 0.8);
        }
      }
    }

    .center-buttons {
      display: none;
      opacity: 0;
      position: absolute;
      z-index: 1;
      bottom: 130px;
      color: $white;
      text-align: center;
      width: 100%;
      transition: opacity 0.3s ease;

      .outline-btn {
        border: solid 2px $white;
        padding: 5px 10px;
        color: $white;
        text-transform: uppercase;
        background-color: rgba($black, 0.5);
        transition: background-color 0.3s ease;

        &:hover {
          background-color: rgba($black, 0.8);
        }
      }
    }

    &:hover {
      &:after {
        opacity: 1;
      }

      .action-buttons,
      .center-buttons {
        opacity: 1;
        display: flex;
      }

      .center-buttons {
        justify-content: center;
      }

      .action-buttons {
        justify-content: flex-end;
      }
    }
  }

  .child-tabs div:first-of-type {
    max-width: 200px;
  }

  .child-tabs a, .child-tabs a:hover, .child-tabs a:focus {
    color: $gray-dark;
    text-decoration: none;
    cursor: pointer;
  }

  .child-tabs a.active {
    text-decoration: underline;
    font-weight: bold;
  }

  .tabs-body:not(.active) {
    display: none;
  }

  .tabs-body .tp-edit__back-btn {
    margin-top: 18px;
  }

  .centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -10%);
    width: 100%;
  }

  .bg-black {
    background-color: $black;
  }

  .d-inline {
    display: inline !important
  }

  .trumbowyg-box, .trumbowyg-editor {
    width: 100%;
    background-color: $white;
  }

  .edit-video-container{
    .form-group {
      margin: 0;

      .control-label {
        margin: 0;
      }
    }
  }
  .fixed-bottom-submit{
    .add-area-form-container__btn-area {
      position: fixed;
      left: 0;
      bottom: 0;
      width: 100vw;
      margin: 0;
      background-color: $white;
      box-shadow: 0px -2px 10px 0px rgba(74, 74, 74, 0.3);
      padding-top: 18px;
      padding-bottom: 18px;

      .btn {
        min-width: 130px;
        margin: 0 5px;
        &:focus{
          background-color: darken($sanofi-purple, 10%);
        }
      }
    }
  }
  .row-sm-gutter {
    margin-right: -7px;
    margin-left: -7px;

    [class*="col-"] {
      padding-left: 7px;
      padding-right: 7px;
    }
  }

  .edit-video-uploaded {
    text-align: center;
    padding-top: 20px;
  }
}

.videos-delete-modal,
.invite-to-watch-modal,
.target-lists-modal,
.user-change-country-modal {
  display: flex;
  flex-direction: column; // IE FIX
  height: 100%;

  .modal-content {
    border-radius: 0;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    margin: auto;
  }

  .modal-body {
    padding-top: 40px;
    padding-bottom: 40px;
    color: $gray-dark;
    font-size: 20px;
    font-style: italic;
    font-weight: 200;
    text-align: center;

    .material-icons {
      padding-bottom: 15px;
    }

    .video-title {
      display: inline-block;
      padding-bottom: 15px;
      font-weight: 700;

      &:first-of-type {
        padding-top: 15px;
      }
    }
  }

  .icon-delete, .icon-error {
    font-size: 6em;
    color: #D0021B;
  }

  .icon-import-confirm, .icon-check {
    font-size: 6em;
    color: #525CA3;
  }

  .buttons {
    margin-top: 50px;
    font-style: normal;

    .button {
      cursor: pointer;
      border: 1px solid;
      line-height: 40px;
      font-size: 15px;
      font-weight: 300;

      &.delete {
        color: #AAAAAA;
        border-color: #AAAAAA;
      }

      &.cancel {
        background-color: $gray-light;
        border-color: $gray-light;
        color: $white;
      }

      &.confirm {
        color: $sanofi-purple;
        border-color: $sanofi-purple;
        max-width: 200px;
        margin: 0 auto;
        float: none;
      }
    }
  }

  .spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -10%);
    width: 100%;
    margin: auto;
  }

  .deleted {
    .icon-check {
      position: absolute;
      top: 36%;
      //left: 46%;
      left: 0;
      right: 0;
      font-size: 1.7em;
      color: $white;
    }
  }
}

.videos-view-modal {
  display: flex;
  flex-direction: column; // IE FIX
  height: 100%;

  .video-details {
    padding-bottom: 20px;
    .video-title {
      font-size: 16px;
      font-weight: 600;
      padding-right: 30px;
    }
  }

  .modal-content {
    border-radius: 0;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    width: 100%;
    margin: auto;

    .modal-body {
      padding-top: 0;
    }
  }
}

.all-videos-modal {
  /* The Modal (background) */
  .modal {
    display: block;
    position: fixed;
    z-index: 11;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 0;
  }

  /* Modal Content/Box */
  .modal-content {
    background-color: #fefefe;
    padding: 23px 23px 0;
    border: 1px solid #888;
    max-width: 1110px;
    margin: 50px auto;
    border-radius: 0;

    .wrap-loader{
      background-color: rgba($white, 0.8);
      height: calc(100% - 60px);
      width: 100%;
      position: absolute;
      top: 60px;
      left: 0;
      z-index: 9;
    }

    .modal-header {
      padding-top: 0;
      .title {
        font-size: 18px;
        font-weight: 700;
        margin: 0 0 20px;
        text-align: center;
      }

      /* The Close Button */
      .close {
        position: absolute;
        top: 23px;
        right: 23px;
        width: 32px;
        height: 32px;

        &:before, &:after {
          position: absolute;
          left: 15px;
          content: ' ';
          height: 33px;
          width: 2px;
          background-color: $gray-dark;
        }
        &:before {
          transform: rotate(45deg);
        }
        &:after {
          transform: rotate(-45deg);
        }
      }
    }

    .modal-body {
      padding: 23px 23px 0;
      overflow-y: auto;
      height: calc(100vh - 170px);

      &.has-pagination {
        height: calc(100vh - 230px);
      }

      .video-item-row {
        border: solid 1px $gray-light;
        margin-bottom: 15px;

        .row.row--no-gutter {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
        }

        .image-container {
          span {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            background: rgba(0, 0, 0, 0.5);

            i {
              font-size: 40px;
            }
          }
        }

        .video-item-content {
          padding: 0 20px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          text-align: left;

          .video-item-info {
            flex-basis: 0;
            flex-grow: 1;
            max-width: 100%;

            .item-title {
              font-size: 16px;
              font-weight: bold;
              margin: 0 0 5px;
              line-height: 1.2em;
              color: black;
            }

            .item-date {
              font-size: 0.9em;
            }
          }

          .video-item-actions {
            margin-left: 23px;
            flex-basis: 200px;
            min-width: 200px;
            color: $sanofi-purple;
            text-align: center;
            font-size: 16px;

            .loader-text {
              font-size: 14px;
              padding-bottom: 5px;
            }

            .spinner > div {
              background-color: $sanofi-purple;
            }

            .item-state {
              .icon {
                display: block;
                margin-bottom: 5px;
                font-size: 30px;
              }

              .text {
                font-size: 14px;
              }
            }
          }
        }
      }
    }

    .modal-footer {
      padding: 0;
      height: 60px;
      margin-right: -23px;
      margin-left: -23px;
      text-align: center;
      box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.25);
      .pagination {
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }

    &.loading-videos{
      .modal-body{
        overflow: hidden;
      }
    }
  }
}

//SPINNER
.spinner {
  text-align: center;
  z-index: 100;
}

.spinner > div {
  width: 18px;
  height: 18px;
  background-color: $white;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0)
  }
  40% {
    -webkit-transform: scale(1.0)
  }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
}

//pagination
.pagination {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination input {
  width: 50px;
  color: black;
  float: left;
  padding: 9px 16px;
  text-decoration: none;
  transition: background-color .3s;
  border: 1px solid #ddd;
  border: none;
  border-color: transparent;
}

.pagination span {
  color: black;
  float: left;
  text-decoration: none;
  transition: background-color .3s;
  border: 1px solid #ddd;

  &.first {
    border-left-color: white;
  }

  &.last {
    border: none;
    color: $sanofi-purple;
    padding: 9px 16px;
  }
}

.pagination div {
  color: black;
}

.pagination {
  a {
    color: $sanofi-purple;
    float: left;
    padding: 9px 16px;
    text-decoration: none;
    transition: background-color .3s;
    border: 1px solid #ddd;

    &.next {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    &.previous {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    &.hover {
      background-color: #4CAF50;
      color: white;
      border: 1px solid #4CAF50;
    }

    &:hover:not(.active) {
      background-color: #ddd;
    }

    &.disabled {
      cursor: not-allowed;
    }
  }
}

.tab_title_edit_video {
  text-align: center;
  margin: 0;
  padding: 25px 0 80px;
  font-weight: bold;
}

.video-platform-modal {
  .close {
    width: 35px;
    height: 35px;
    //border: solid 1px red;
    position: relative;
    span {
      display: none;
    }
    &:before, &:after {
      position: absolute;
      top: 0;
      left: 15px;
      content: ' ';
      height: 35px;
      width: 2px;

      background-color: $gray;
    }
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
  }
}

.video-platform-home-page-management {
  @import 'video-platform-accordion';
  .enable-select-btn {
    float: right;
    margin-right: 12px;
    margin-top: 14px;
    color: $sanofi-purple;
    cursor: pointer;
    &:hover{
      color: $gray;
    }
    &.is-active {
      color: $gray;
      &:hover{
        color: $sanofi-purple;
      }
    }
    .icon {
      font-size: 30px;
    }
  }

  .select-action-wrapper {
    z-index: 9;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 20px;
    background-color: $white;
    box-shadow: 0 -2px 6px 0 rgba(0, 0, 0, 0.2);
    text-align: center;
    transition: all 0.3s ease;
    &.hide-wrapper {
      bottom: -300px;
    }
    .text {
      color: $sanofi-purple;
      min-height: 40px;
      line-height: 40px;
      font-size: 18px;
    }
    .btn {
      background-color: $sanofi-purple;
      margin: 0;
      padding: 0 25px;
      font-size: 18px;
      line-height: 40px;
      height: 40px;
      color: $white;
      border-radius: 0;
      &:hover {
        background-color: darken($sanofi-purple, 10%);
      }
    }
  }

  .enable-select {
    position: relative;
    cursor: pointer;
    &:after {
      //content: '';
      display: block;
      width: 22px;
      height: 22px;
      position: absolute;
      top: 10px;
      right: 24px;
      border: solid 2px $white;
      border-radius: 50%;
      font-family: 'Material Icons';
      line-height: 18px;
      font-size: 16px;
      text-align: center;
      color: $white;
      content: '\E876';
      background-color: $sanofi-purple;
    }
    &.not-selected {
      .thumbnail-container {
        outline: none;
      }
      &:after {
        content: '';
        background-color: rgba(0, 0, 0, 0.5);
      }
      &:hover{
        &:after {
          background-color: $sanofi-purple;
        }
      }
    }

    .thumbnail-container {
      cursor: pointer;
      outline: 2px solid $sanofi-purple;
      &:after {
        transition: all 0.3s ease;
        opacity: 1;
        background-color: rgba(0, 0, 0, 0.6);
      }
      .center-buttons,
      .action-buttons {
        display: none;
      }
    }
    &:hover{
      &:after {
        background-color: rgba(0, 0, 0, 0.5);
      }
      .thumbnail-container{
        box-shadow: 0 0 6px 4px rgba(74, 74, 74, 0.3);
        &:after {
          background-color: rgba(0, 0, 0, 0.7);
        }
      }
    }
  }

}
