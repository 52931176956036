.add-touchpoint-form-container {
  .gr-container {
    background: white;
  }

  .add-touchpoint-form__sticky-row {
    height: 75px;

    &.fixed {
      position: fixed;
      width: 1100px;
      z-index: 110;
      top: 0;
      box-shadow: 0px 7px 4px #999;
      border-bottom: 1px solid #333;
    }

    .form-section {
      padding: 10px 0;
    }
  }
  .add-touchpoint-form__action-buttons {
    .btn {
      text-transform: none;
    }
  }

  .add-touchpoint-form__status {
    margin-right: 2em;
  }

  .add-touchpoint-form-container__services {
    .add-touchpoint-form-container__services-title {
      font-size: 18px;
      color: $color_textgrey;
      @include fontMainSemiBold;
    }

    .checkbox {
      margin-top: 3px;
      margin-bottom: 3px;
    }

    .form-group {
      margin: 0;
    }

    .form-group label.control-label {
      margin: 0;
      font-size: 14px;
    }

    .checkbox .checkbox-material {
      margin-right: 5px;
    }
  }

  .add-touchpoint-form-container__add-more {
    margin: 10px 0;
    display: inline-block;
    color: $color_secondary;
    cursor: pointer;

    &.add-touchpoint-form-container__add-more--dark {
      color: $color_text;
    }
  }

  .add-touchpoint-form-container__btn-area {
    background: #E9E7E4;
    padding: 20px 0;
  }
  //extending form-sections
  .form-section {
    .form-section__plain-button {
      text-transform: none;
      color: #5cb85c;
      cursor: pointer;
      margin: 10px 0 0;
      display: inline-block;
    }

    .form-section__logo-container {
      margin: 10px 0;
    }
  }
  //Selector
  span.select2-selection.select2-selection--multiple {
    background: transparent;
    border: 0;
    background-size: 0 2px, 100% 1px;
    background-repeat: no-repeat;
    background-position: center bottom, center calc(100% - 1px);
    background-color: transparent;
    background-image: linear-gradient(#333, #333), linear-gradient(#333, #333);
    color: white;
  }

  .select2-container--default .select2-selection--multiple .select2-selection__choice {
    background: $color_secondary;
    color: white;
  }

  .select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
    color: white;
  }

  .select2-container {
    width: 90% !important;
  }

  .date-selectors {
    .select2-container {
      width: 32% !important;
      min-width: 32% !important;
      margin-right: 1%;
      margin-top: 10px;
    }
  }

  .url-preview-container {
    color: #666;
    background-color: white + (-40);
    cursor: default;
    padding: 3px 10px;
    margin: 5px 0;
  }

  .preview-user-list {
    margin: 20px 0;
    cursor: pointer;
    position: relative;
  }

  .form-group--published {
    display: inline-block;
    margin: 0 20px;
    position: relative;
    top: 12px;
    label {
      margin: 0;
    }
  }
}
