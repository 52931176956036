.create-password-page-container {
    min-width: 100%;
    width: 100%;
    height: 100%;
    min-height: 100%;
    background-image: url("/img/sample/create-pass.jpg");
    background-size: cover;
    background-position: center center;
    position: absolute;
}

.create-password {
    width: 580px;
    min-height: 600px;
    position: fixed;
    top: 50%;
    margin-top: -300px;
    left: 50%;
    right: 50%;
    margin-left: -290px;
    background: white;

    .checkbox label, .radio label, label {
        margin:0;
    }

    @include mq($until: tablet) {
        width: 96%;
        margin: 0 2%;
        position: relative;
        top: 20px;
        left: auto;
        right: auto;
    }

    .create-password__title {
        color: white;
        font-size: 30px;
        padding: 30px 0;
        text-align: center;
        background: $color_green;
        @include fontMainSemiBold;

        @include mq($until: tablet) {
            font-size: 20px;

        }
    }
    .create-password__login-logo-area {
        margin: 20px 0;
    }

    .create-password__logo-area {
        text-align: center;
        padding: 20px 0 10px;
        background: white;
        img {
            max-height: 50px;
        }

    }
    .create-password__form-area {
        padding: 20px 70px;
        @include mq($until: tablet) {
            padding: 20px 1em;

        }
    }

    .create-password__form-title {
        color: #4d5254;
        font-size: 21px;
        text-align: center;
        @include fontMainSemiBold;
    }

}
