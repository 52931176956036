//---------------------------------------
// Action menu generally below top menu
// that has action buttons etc on
//---------------------------------------
.mcm-action-menu {
    height: 62px;
    border-bottom: 1px solid #dcdcdc;
    background: $color_grey;
}

.mcm-action-menu {
    font-size: 20px;
    padding-top: 13px;
    color: #9a9a9a;
    background: #F8F8F8;
    position: fixed;
    z-index: 2;
    width: 100%;
    box-shadow: inset 0 4px 6px -2px rgba(0, 0, 0, 0.3);
    a {
        color: #9a9a9a;
        margin-right: 25px;

    }

    i.mcm-action-menu__top-icon {
        font-size: 30px;
    }
}

.body--has-left-menu {

    .mcm-action-menu {
        font-size: 20px;
        padding-top: 13px;
        color: #9a9a9a;
        background: #F8F8F8;
        position: fixed;
        z-index: 2;
        width: 100%;
        box-shadow: inset 0 4px 6px -2px rgba(0, 0, 0, 0.3);
        a {
            color: #9a9a9a;
            margin-right: 25px;

            i {
                font-size: 30px;
            }
        }
        @include mq($from: tablet, $until: desktop) {
            padding-left: 60px;
            padding-right: 5%;
        }
        @include mq($from: desktop) {
            padding-left: 400px;
            padding-right: 5%;
            &--wider {
                padding-left: 116px;
            }
        }
    }
}