.color-circle {
  width: 22px;
  height: 22px;
  display: inline-block;
  border: 1px solid #e5e5e5;
  border-radius: 100%;
  margin-right: 10px
}

.full-height {
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  overflow: hidden;
}

.gr-container {
  background: white;
  box-shadow: -1px 2px 11px #ccc;
}

.dashed-sep-line {
  height: 1px;
  border-top: 1px dashed #ccc;
}

.board-listing {

  &__left {
    float: left;
    margin-right: 4%;
    transition: none;
    width: 36%;

  }

  &__right {
    float: left;
    width: 60%;
  }

}
