// Adboard Calendar Modal Styles

// .meetings
.meetings {

    border: 0px solid $black;
    padding: 0px 0px 0px 30px;
    color: $color_text;

    // .meetings__title
    &__title {
        color: $color_text;
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 40px;
        margin-top: 0px;
    }

    // .meetings__remaining
    &__remaining {
        margin: 20px 0px 10px 0px;
        font-size: 15px;
    }

    // .meetings__list
    &__list {
        list-style-type: none;
        border: 0px solid $black;
        padding: 0px;
        overflow-y: auto;
        overflow-x: hidden;
        height: 500px;
    }

    // .meetings__list-item
    &__list-item {
        border: 1px solid $color_lightgrey;
        padding: 13px 30px 10px 20px;
        margin: 0px 20px 10px 0px;
        cursor: pointer;
        &:hover {
            border: 1px solid $color_lightgrey + (-50);

        }
    }

    // .meetings__meeting-content
    &__meeting-content {

        border: 0px solid $black;
        float: left;

        // .meetings__meeting-content-subject
        &-subject {
            b {
                font-weight: 700;
            }
        }

        // .meetings__meeting-content-date
        &-date {
            font-weight: 700;
        }

    }

    // .meetings__meeting-detail
    &__meeting-detail {

        border: 0px solid $black;

        // .meetings__meeting-detail-line
        &-line {
            b {
                font-weight: 700;
            }
        }

        // .meetings__meeting-detail-button
        &-button {
            width: 120px;
            border-radius: 20px;
            display: inline-block;
            margin: 0px 0px 10px 0px;
            padding: 5px 10px 5px 10px;
            border: 1px solid $republic-m-green;
            color: $republic-m-green;
            background-color: $white;
            font-size: 12px;
            font-weight: 600;

            &:hover {
                text-decoration: none;
                color: $republic-m-green;
            }

            &:visited {
                text-decoration: none;
                color: $republic-m-green;
            }
        }

    }

    // .meetings__status
    &__status {
        float: right;
        border: 0px solid $black;
    }

    .meetings__meeting-actions {
        font-size: 15px;
        i {
            cursor: pointer;
        }
    }

    .meetings__meeting-status {
        width: 8px;
        height: 8px;
        border-radius: 100%;
        background: black;
        display: inline-block;
    }
    .meetings__meeting-status--0 {
        background: $status_pending;
    }
    .meetings__meeting-status--1 {
        background: $status_accept;
    }
    .meetings__meeting-status--2 {
        background: $status_decline;
    }

    .meetings__meeting-attendees {
        list-style: none;
        padding: 0;
        li {
            display: inline-block;
            margin: 0px 5px 5px;
            padding: 2px 10px;
            font-size: 10px;
            border-radius: 5px;
            border: 1px solid #CECECE;
        }
    }
}

// fullCalendar Custom Styles
// ----------------------------------
.adboard-group-meeting-calendar {
    font-size: 12px;
    margin-right: 20px;
    margin-left: 20px;

    button {
        background: transparent;
        border: 0px solid $black;
        color: $color_text;
        padding: 10px 32px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 20px;
        margin: 4px 2px;
        cursor: pointer;
    }

    .fc-day-header {
        font-size: 15px;
    }
}

.fc-day-number {
    font-size: 17px;
    font-weight: 600;
    color: $color_text;
}

.fc td, .fc th {
    border: 0px solid $color_text !important;
}

td.fc-sun > span.fc-day-number, th.fc-sun {
    color: $republic-m-green;
}

.fc th {
    padding-bottom: 15px !important;
}

.fc-day-header, .fc-header-toolbar h2 {
    color: $color_calendar_day;
    text-transform: uppercase;
}

.fc-header-toolbar h2 {
    font-size: 20px;
    margin-top: 4px !important;
    margin-bottom: 20px !important;
    font-weight: 600;
}

.fc-event {
    border-radius: 0px !important;
    font-size: 11px;
    font-weight: 400;
    cursor: pointer;
    time {
        font-weight: 700;
        font-size: 12px;
    }
}

a.fc-more, a.fc-more:hover {
    color: $color_text;
}

.fc-day-header {
    font-weight: 500;
}

.fc-today {
    background-color: $republic-m-lightgreen !important;
}

// colored radio buttons
.meetings__status-list li {
    color: #AAAAAA;
    position: relative;
    list-style-type: none;
    margin-left: 5px;
    text-align: center;
}

.meetings__status-list li input[type=radio] {
    position: absolute;
    visibility: hidden;
}

.meetings__status-list li label {
    position: relative;
    font-weight: 300;
    height: 10px;
    font-size: 10px;
    margin-left: -40px;
    padding: 0px;
    z-index: 9;
    cursor: pointer;

}

.meetings__status-list li .check {
    display: block;
    position: absolute;
    border: 2px solid #AAAAAA;
    border-radius: 100%;
    height: 15px;
    width: 15px;
    top: 20px;
    left: 10px;
    z-index: 5;
}

.meetings__status-list li .check::before {
    display: block;
    position: absolute;
    content: '';
    border-radius: 100%;
    height: 5px;
    width: 5px;
    top: 3px;
    left: 3px;
    margin: auto;
}

// accept option
.meetings__status-list input[id^='accept-']:checked ~ .check {
    border: 2px solid $status_accept;
}

.meetings__status-list input[id^='accept-']:checked ~ .check::before {
    background: $status_accept;
}

// decline option
.meetings__status-list input[id^='decline-']:checked ~ .check {
    border: 2px solid $status_decline;
}

.meetings__status-list input[id^='decline-']:checked ~ .check::before {
    background: $status_decline;
}

// pending option
.meetings__status-list input[id^='pending-']:checked ~ .check {
    border: 2px solid $status_pending;
}

.meetings__status-list input[id^='pending-']:checked ~ .check::before {
    background: $status_pending;
}
