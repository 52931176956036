// Load ie styles
//-------------------------------------------------------------

//IE 10 + FIXES
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .line .mcm-navigation-dropdown--link {
        flex: 0 39.6%;
        img {
            width: 90%;
        }
    }

    .mcm-loader {
        margin: 20px 0;
    }

    .speakers-overview .mcm-icon-wrapper {
        padding: 3px;
        margin-right: 0;
    }

    #list-speakers-options .toggle-btn-container {
        margin-left: 30%;
    }
}

//IE 10 ONLY FIXES
html[data-useragent*='MSIE 10.0'] {

}

html.no-websqldatabase.no-cssreflections {
    //.adboard-item__doc-image
    .adboard-item__doc-image {

        //.adboard-item__doc-image img
        img {
            top:0;
            left:0;
        }
    }
}