.video-platform-request{
  position: relative;
  z-index: 9;
  .show-form-btn{
    position: fixed;
    bottom: 50px;
    right: 30px;
    width: 60px;
    background-color: $white;
    color: $sanofi-purple;
    border-radius: 50%;
    text-align: center;
    box-shadow: 0 0 5px 4px rgba(74, 74, 74, 0.2);
    cursor: pointer;
    .icon{
      line-height: 60px;
      font-size: 40px;
    }
    &:hover{
      background-color: darken($white, 5%);
    }
  }
  .request-block{
    padding: 30px 30px 30px;
    position: fixed;
    bottom: 50px;
    right: 30px;
    width: 450px;
    background-color: $white;
    box-shadow: 0 0 10px 4px rgba(74, 74, 74, 0.2);
    font-size: 14px;
    .close-btn-block{
      position: absolute;
      top: 20px;
      right: 20px;
      width: 32px;
      height: 32px;
      cursor: pointer;
      z-index: 2;
      .close-btn{
        width: 32px;
        height: 32px;
        &:before, &:after {
          position: absolute;
          left: 15px;
          content: ' ';
          height: 33px;
          width: 2px;
          background-color: $gray;
        }
        &:before {
          transform: rotate(45deg);
        }
        &:after {
          transform: rotate(-45deg);
        }
      }
    }
    .wrap-message{
      position: relative;
      padding-top: 20px;
      min-height: 500px;
      .title-text{
        font-weight: bold;
      }
      .info-text{
        padding-bottom: 30px;
      }
      .signature{
        .signature-text{
          padding-bottom: 20px;
        }
      }
      .signature-img{
        display: block;
        margin-bottom: 30px;
        max-height: 35px;
      }
      .textarea-request-message{
        width: 100%;
        height: 200px;
        resize: vertical;
        border: solid 1px $gray-light;
        padding: 20px;
        margin-bottom: 50px;
        border-radius: 5px;
        &:focus{
          &::-webkit-input-placeholder {
            color: transparent;
          }
          &:-moz-placeholder {
            color: transparent;
          }
          &::-moz-placeholder {
            color: transparent;
          }
          &:-ms-input-placeholder {
            color: transparent;
          }
        }
      }
      .bottom-buttons{
        position: absolute;
        bottom: 0;
        width: 100%;
      }
      .btn-primary-round{
        width: 100%;
        display: block;
        padding: 10px;
        text-align: center;
        font-size: 20px;
        text-transform: uppercase;
        color: $white;
        background-color: $sanofi-purple;
        border-radius: 25px;
        cursor: pointer;
        &.disable{
          background-color: $gray-light;
        }
      }
      &.success-message{
        .success-img{
          display: block;
          margin: 60px auto 40px;
        }
        .success-message-text{
          font-size: 20px;
          line-height: 1.6em;
          text-align: center;
          min-height: 250px;
        }
      }
    }
    .wrap-loader{
      min-height: 500px;
    }
  }
}