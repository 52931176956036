.users-overview {
    background: white;
    padding: 20px 0;

    .users-overview__title {
        padding: 0 20px;
        margin: 10px 0 20px;
        font-size: 24px;
    }

    .users-overview__touchpoints {
        padding: 0px 20px 20px;
    }

    .user-overview__list-count {
        margin: 10px 0;
        color: $color_textgrey;
        @include fontMainSemiBold;
    }

    .user-overview__filter {
        padding: 0;
        .user-overview__filter-roles {
            padding: 0;
            li {
                list-style: none;
                display: inline-block;
                font-size: 16px;
                a {
                    padding: 8px 20px 9px;
                    text-decoration: none;
                    color: $color_text;
                }
                &.current-item,
                &:hover {
                    a {
                        background: $color_primary;
                        color: white;
                    }
                }
            }
        }
        .user-overview__search {
            position: relative;
            .form-group {
                margin: 0;
                position: relative;
                margin-top: -10px;
            }
        }

        .user-overview__search-button {
            border: 0;
            background: transparent;
            position: absolute;
            right: 0;
            top: 10px;
            color: $color_primary;
            transition: 0.3s all;
        }
        .is-focused {

            .user-overview__search-button {
                color: #337ab7;
            }
        }
    }

    .user-overview__table {
        &.user-overview__table--sm {

            td {

                font-size: 12px;
            }
        }

        td {
            &.user-overview__fullname-cell {
                a {
                    color: $color_secondary;
                }
                @include fontMainSemiBold;
            }
            &.user-overview__fullname-cell,
            &.user-overview__email-cell {
                width: 36%;
            }
            &.user-overview__areas-cell {
                width: 28%;
            }

            &.user-overview__delete-cell {
                a {
                    color: #f44336;
                }
            }
        }
    }
    .sep-line {
        height: 1px solid $color_lightgrey;
        width: 100%;
    }

    .user-overview__approval {
        margin:10px 0;
        font-size: 12px;
        a {
            color:#999 !important;
        }
    }
    .user-overview__approve-btn {

    }
}
