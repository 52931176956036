.video-platform-home-page-management {

  .view-email-tab {
    padding-bottom: 10px;

    input {
      width: 100%;
      padding: 7px;
      border: 1px solid #D8D8D8;

      &.has-error {
        border-color: $color_red;
      }
    }

    .top-label {
      font-size: 12px;
      color: #9B9B9B;
    }

    .selected-hcps {
      background-color: $white;
      min-height: 35px;
      padding: 4px 7px 0;
      border: 1px solid #D8D8D8;
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .selected-hcp-box {
        display: flex;
        padding: 2px 2px 2px 6px;
        border: 1px solid #D8D8D8;
        margin-right: 10px;
        border-radius: 3px;
        margin-bottom: 4px;

        i {
          margin-left: 10px;
          font-size: 13px;
          margin-top: -2px;
          cursor: pointer;
          height: 1em;
        }
      }
    }
  }

}