// Adboard calendar styles
//-------------------------------------------------------------

//.adboard-calendar
.adboard-calendar {

    @mixin setDatePickerHeader() {
        font-weight: 300;
        color: $white;
        background-color: $republic-m-blue;
    }

    margin-bottom: 4em;

    //.adboard-calendar .ui-datepicker
    .ui-datepicker {
        padding: 0;
        width: 100%;
        max-width: 300px;
        margin:0 auto;

        @include mq($until: tablet) {
            margin: auto;
        }

        //.adboard-calendar .ui-datepicker-header
        &-header {
            border-radius: 0;
            @include setDatePickerHeader();
        }

        //.adboard-calendar .ui-datepicker table
        table {
            margin-bottom: 1em;

            //.adboard-calendar table td
            td {

                //.adboard-calendar table td span, .adboard-calendar table td a
                span, a {
                    text-align: center;
                    border-radius: 3px;
                    border: 0;
                }
            }

            //.adboard-calendar table th
            th {
                font-weight: 300;
            }
        }

        //.adboard-calendar .ui-datepicker-calendar
        &-calendar {
            //.adboard-calendar .ui-datepicker-calendar thead
            thead {
                @include setDatePickerHeader();
            }
        }

        //.adboard-calendar .ui-datepicker .ui-datepicker-title
        .ui-datepicker-title {
            font-size: 1.2em;
            margin: auto;
            line-height: inherit;
        }

        //.adboard-calendar .ui-datepicker .ui-datepicker-prev, .adboard-calendar .ui-datepicker .ui-datepicker-next
        .ui-datepicker-prev, .ui-datepicker-next {
            position: static;
            display: block;
            height: auto;
            cursor: pointer;

            //.adboard-calendar .ui-datepicker .ui-datepicker-prev span, .adboard-calendar .ui-datepicker .ui-datepicker-next span
            span {
                margin: auto;
                position: static;
            }
        }

        //.adboard-calendar .ui-datepicker-next
        &-next {
            order: 1;
        }

        //.adboard-calendar .ui-datepicker-year
        &-year {
            font-size: .7em;
        }
    }

    //.adboard-calendar .ui-widget
    .ui-widget {
        @include fontMain();

        //.adboard-calendar .ui-widget-header
        &-header {
            text-transform: uppercase;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: .6em .4em;
            border: 0;
        }
    }

    //.adboard-calendar .ui-corner-all
    .ui-corner-all {
        border-radius: 0;
    }

    .ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default, .ui-button, html .ui-button.ui-state-disabled:hover, html .ui-button.ui-state-disabled:active {
        background: transparent;
    }

    .ui-state-highlight, .ui-widget-content .ui-state-highlight, .ui-widget-header .ui-state-highlight {
        background: $republic-m-green;
        color: $white;
    }

    .ui-state-active, .ui-widget-content .ui-state-active, .ui-widget-header .ui-state-active, a.ui-button:active, .ui-button:active, .ui-button.ui-state-active:hover {
        background: darken($republic-m-blue, 10%);
    }

    .ui-datepicker-next, .ui-datepicker-prev {
        &.ui-corner-all.ui-state-hover {
            background: transparent;
            border: 0;
        }
    }

    &__label {
        width:100%;
        text-align:center;
    }

}