$input-bg: $white;
$input-border: $color_lightgrey;
$input-text-color: $gray-dark;
$input-label-color: $gray-dark;
$input-border-radius: 0;
$input-padding: 7px;
$form-primary-color: $sanofi-purple;
$input-disabled: #C9C6C6;

.form-section__content {

  .form-group {
    &.mt-0{
      margin-top: 0;
    }
    .form-control {
      background: $input-bg;
      border: solid 1px $input-border;
      color: $input-text-color;
      border-radius: $input-border-radius;
      padding: $input-padding;
      height: auto;
      line-height: 1.4;
      &:focus {
        border: solid 1px darken($input-border, 10%);
      }
      &::placeholder{
        color: lighten($input-text-color, 30%);
        font-weight: 100;
      }
    }
    &.is-focused {
      .form-control {
        background: $input-bg;
      }
    }
    .control-label {
      margin: 0;
      color: $input-label-color;
      padding-bottom: 0.3em;
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      .help-tooltip{
        font-size: 14px;
        line-height: 16px;
        display: inline-block;
        position: relative;
        bottom: -2px;
        padding-left: 3px;
        padding-right: 3px;
        cursor: pointer;
        .icon{
          display: inline-block;
          font-size: 13px;
          line-height: 0px;
        }
      }
    }
    .help-block {
      margin-top: -7px;
      line-height: 1.1em;
    }
    .overflow-ellipsis{
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &.no-label {
      padding-top: 16px;
      padding-bottom: 0;
    }
    .select {
      margin-bottom: 7px;
    }

    .trumbowyg-box {
      margin-top: 0;
    }
    &.not-valid{
      label.control-label{
        color: #d9534f;
      }
    }
  }

  .form-control + .select2 {
    margin-top: 0;
    .select2-selection {
      border: solid 1px $input-border;
      padding: 2px 7px 7px;
      .select2-selection__choice {
        background-color: transparent !important;
        border: solid 1px $input-border;
        color: $input-text-color !important;
        border-radius: $input-border-radius;
      }
    }
  }

  .custom-checkbox-btn{
    position: relative;
    label{
      position: relative;
      padding-left: 22px;
      cursor: pointer;
      line-height: 16px;
      font-size: 14px;
      display: inline-block;
      margin: 0 0 15px 0;
      color: $text-color;
      .checkmark{
        &:before{
          content: '';
          position: absolute;
          left: 0;
          top: 2px;
          width: 14px;
          height: 14px;
          border: 1px solid $gray;
          border-radius: 50%;
          background: $white;
        }
        &:after{
          opacity: 0;
          content: '';
          width: 6px;
          height: 6px;
          background: $sanofi-purple;
          position: absolute;
          top: 6px;
          left: 4px;
          border-radius: 50%;
          transition: all 0.2s ease;
        }
      }
      [type="checkbox"]{
        position: absolute;
        left: -9999px;
      }
      [type="checkbox"]:checked ~ .checkmark{
        font-weight: 600;
        &:after{
          opacity: 1;
        }
      }
    }

  }

  &.request-edit-form{
    .video-cut-options{
      padding-bottom: 26px;
    }
    .time-range-row{
      display: none;
      &.show-time-option{
        display: block;
      }
    }

    .time-range{
      .form-group{
        margin-top: 0;
      }
      .time-input{
        padding: 10px;
        border-color: $text-color;
        text-align: center;
        &.not-valid{
          border-color: red;
          color: red;
        }
      }
      small {
        color: red;
        font-size: 10PX;
      }
    }

    .label-text{
      font-size: 16px;
      padding-bottom: 10px;
      display: inline-block;
    }
    .row-pt{
      padding-top: 20px;
    }
    .textarea-wrapper{
      padding-top: 10px;
      .form-group{
        margin-top: 0;
      }
      textarea{
        min-height: 250px;
        background: #fff;
        border: solid 1px $gray-light;
        border-radius: 0;
        padding: 7px;
        height: auto;
        line-height: 1.4;
        resize: vertical;
        &:focus{
          box-shadow: 0 0 3px rgba($sanofi-purple, 0.6);
        }
      }
    }
    .select-options{
      padding: 10px;
      border-color: $text-color;
    }
    .floating-btn{
      background-color: $sanofi-purple;
      width: 30px;
      height: 30px;
      text-align: center;
      color: $white;
      border-radius: 50%;
      display: inline-block;
      cursor: pointer;
      box-shadow: 0 0 8px 2px rgba(0, 0, 0, .2);
      transition: all 0.3s ease;
      &:hover{
        background-color: darken($sanofi-purple, 10%);
        box-shadow: 0 0 8px 2px rgba(0, 0, 0, .3);
      }
      &.remove-fields-button{
        background-color: $white;
        color: $sanofi-purple;
        &:hover{
          background-color: darken($white, 20%);
        }
      }
      .icon{
        line-height: 30px;
      }
    }
  }

}

.form-btn-primary:not(.btn-raised):not(.btn-link):hover,
.form-group .btn.form-btn-primary,
.btn.form-btn-primary {
  margin: 0;
  border-radius: $input-border-radius;
  padding: $input-padding $input-padding*3;
  font-size: 16px;

  &:not(:disabled) {
    color: $white;
    background-color: $form-primary-color;

    &:hover, &:active, &:focus {
      background-color: darken($form-primary-color, 10%);
    }
  }

  &:disabled {
    color: $white !important;
    background-color: $input-disabled !important;
  }
}

.btn.form-btn-outline-primary,
.btn.form-btn-white {
  margin: 0;
  padding: $input-padding $input-padding*3;
  font-size: 16px;
  border-radius: $input-border-radius;

  &:not(:disabled) {
    outline: 1px solid $form-primary-color;
    color: $form-primary-color;
    background-color: $white;
    &:focus,
    &:hover {
      color: $white;
      background-color: $form-primary-color !important;
    }
  }

  &:disabled {
    color: $input-disabled !important;
    background-color: transparent !important;
    border: 1px solid $input-disabled;
  }
}

.select2-container--default {
  .select2-dropdown {
    border-top: none;
    border-bottom: none;
    border-left: solid 1px $input-border;
    border-right: solid 1px $input-border;
    max-width: 430px;
  }

  .select2-results {
    > .select2-results__options {
      max-height: 250px;
    }
  }
  .select2-results__option {
    border-bottom: solid 1px $input-border;
    font-size: 16px;
    padding: 10px 10px 10px 15px;
    &:after {
      margin-top: -5px;
      font-size: 24px;
      float: right;
      font-family: "Material Icons";
      content: "check_circle_outline";
      font-feature-settings: 'liga';
      color: $input-border;
    }

    &:hover {
      color: $input-label-color;
      font-weight: 600;
    }
  }
  .select2-results__option--highlighted[aria-selected] {
    background-color: lighten($input-border, 15%);
    color: $input-label-color;
  }
  .select2-results__option[aria-selected=true] {
    background-color: transparent;
    color: $input-label-color;
    font-weight: 600;
    &:after {
      color: $form-primary-color;
    }
  }
}

.edit-video-request-container form{
  position: relative;
  .sending-request-loader{
    display: none;
    background-color: rgba(248, 248, 248, 0.8);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    &.show{
      display: block;
    }
  }
}

.checkbox.checkbox-primary input[type="checkbox"]:checked + .checkbox-material .check::before {
    color: $form-primary-color !important;
}

.bg-form-primary {
    background-color: $form-primary-color;
}
