/*
* Info cards about clients on dashboard screens
*/
.client-info-card {
    background: white;
    margin-bottom: 20px;
    box-shadow: -1px 2px 11px #ccc;
    .client-info-card__title {
        text-align: center;
        font-size: 18px;
        @include fontMainLight;
        padding: 15px 30px 0;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .client-info-card__image-container {
        display: inline-block;
    }

    .client-info-card__image {
        height: 85px;
        margin: 10px 0;
        display: table-cell;
        vertical-align: middle;
        text-align: center;
        width: 100%;
        a {
            img {
                max-height: 100%;
                max-width: 90%;
            }
        }
    }

    .client-info-card__links {
        background: #00a886;
        padding: 30px 0;
        list-style: none;
        font-size: 12px;
        position: relative;
        color: white;
        .client-info-card__links-arrow {
            position: absolute;
            left: 50%;
            right: 50%;
            font-size: 30px;
            color: white;
            top: -20px;
            display: inline-block;
            margin-left: -9px;
        }
        a {
            color: white;
        }
    }
    .client-info-card__link {
        margin: 10px 0;
    }
    .client-info-card__link-label {
        width: 30px;
        height: 20px;
        background: white;
        color: #00a886;
        text-align: right;
        display: inline-block;
        border-radius: 0 10px 10px 0;
        padding: 1px 5px 0 0;
    }
    .client-info-card__content {
        padding: 20px;
        min-height: 100px;
        .client-info-card__meta {
            table {
                tr {
                    vertical-align: top;

                    td {
                        vertical-align: top;
                        width: 50%;
                    }
                }
            }
        }
    }
    .client-info-card__button {
        margin-right: 10px;
        background-color: #00a886;
        color: white;
        padding: 5px 0;
    }
    .client-info-card__buttons {
        width: 100%;
        border-top: 1px solid #ccc;
        margin: 0;
        padding: 20px 35px;
    }
}
