.adboard-member-card {
    margin: 15px 0;
    cursor: pointer;
    @include sidebarItemPaddings;

    //.adboard-member-card--link
    &--link {
        @include resetAnchor();
    }

    //.adboard-member-card--add-new
    &--add-new {
        cursor: pointer;
    }

    //.adboard-member-card .adboard-member-card__avatar
    .adboard-member-card__avatar {
        width: 46px;
        height: 46px;
        position: relative;

        &--popup {
            width: 130px;
            height: 130px;
            margin: 10px auto;
        }

        //.adboard-member-card .adboard-member-card__avatar img
        img, &--popup > img {
            border-radius: 100%;
        }
    }

    //.adboard-member-card .adboard-member-card__avatar-new-btn
    .adboard-member-card__avatar-new-btn {
        height: 21.1px;
        width: 21px;
        background-color: #78A12E;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
        color: white;
        display: inline-block;
        border-radius: 100%;
        text-align: center;
        font-size: 17px;
        line-height: 22px;
        position: absolute;
        right: 0;
        bottom: -6px;
    }

    //.adboard-member-card .adboard-member-card__title
    .adboard-member-card__title {
        font-size: 15px;
        margin: 5px 0;
    }

    .adboard-member-card__subtitle--popup{
        color: $republic-m-green;
        margin-bottom: 20px;
    }

    .adboard-member-card__popup {
        background-color: $white;
        border: 1px solid $color_lightgrey;
        color: $black;
        z-index: 4;
        width: 300px;
        height: 350px;
        position: fixed;
        top: 20px;
        left: 200px;
        text-align: center;
        -webkit-border-radius: 6px;
        -moz-border-radius: 6px;
        border-radius: 6px;
        padding: 10px;
        box-shadow: -1px -1px 10px -2px rgba(0, 0, 0, 0.5);
    }

    .adboard-member-card__info--popup {
        color: $color_textgrey;
    }

    .adboard-member-card__remove-button {
        width: 120px;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        border-radius: 30px;
        display: inline-block;
        margin-top: 30px;
        padding: 10px 15px 10px 15px;
        border: 1px solid $republic-m-green;
        color: $republic-m-green;
        font-size: 18px;
        font-weight: 600;
    }
}