///*------------------------------------*\
//    Template Package Card
//\*------------------------------------*/

.tp-edit__overview {
  .template-package-card {
    width: 165px;
  }
}

//.template-package-card
.template-package-card {
  margin: 0 0 10px;
  @extend .shadow-for-box;

  //.template-package-card a
  a {
    color: $color_textgrey;
    text-decoration: none;

    &:hover {
      color: $color_text;
    }
  }

  //.cards-item .template-package-card
  .cards-item & {
    min-height: 240px;
  }

  //.template-package-card__title
  &__title {
    background: white;
    text-align: center;
    padding: 22px 0;
    width: 100%;
    color: $color_textgrey;
  }

  //.template-package-card__image
  &__image {

    //.template-package-card__image
    img {
      max-height: 250px;
      display: block;
      margin: auto;
    }
  }

}