.touchpoint-meta-details {
    padding: 20px 0;

    //.touchpoint-meta-details__title
    &__title {
        @include fontMainSemiBold;
        font-size: 16px;
        display: inline-block;
        &:hover {
            color:black;
        }
    }
    &__key {
        color:#666;
    }

    //.touchpoint-meta-details__default
    &__default {

    }

    //.touchpoint-meta-details__current
    &__current {

    }

    //.touchpoint-meta-details__editlink
    &__editlink {
        margin: 10px 0;
        display: inline-block;
    }
    &__editlink--close {
        color: #a70000;
    }

    &__actions {
        a {
            margin-right:20px;
        }
    }
}