.hcp-messages-wrapper{
    padding: 50px 0;
    .messages-col{
        .search-container{
            margin: 0 0 20px;
            padding: 10px;
            background-color: $white;
            box-shadow: 0px 2px 10px 0px rgba(74,74,74,0.3);
            display: flex;
            justify-content: space-between;
            .search-field{
                font-size: 16px;
                &:focus::-webkit-input-placeholder { color:transparent; }
                &:focus:-moz-placeholder { color:transparent; }
                &:focus::-moz-placeholder { color:transparent; }
                &focus:-ms-input-placeholder { color:transparent; }
            }
            .icon{
                color: $sanofi-purple;
            }
        }

        .messages-list-wrapper{
            overflow-y: auto;
            max-height: 600px;
            .messages-list{
                list-style-type: none;
                padding: 0;
                margin: 0;
                .messages-item{
                    background-color: lighten($gray-light, 7%);
                    border: solid 1px $gray-light;
                    box-shadow: 0px 2px 10px 0px rgba(74,74,74,0.2);
                    margin-bottom: 10px;
                    cursor: pointer;
                    transition: box-shadow 0.3s ease;
                    &:hover{
                        box-shadow: 0px 2px 12px 0px rgba(74,74,74,0.4);
                    }
                    .message-info{
                        display: flex;
                        padding-bottom: 10px;
                        align-items: center;
                        line-height: 1.2em;
                        .avatar{
                            flex: 0 0 50px;
                            max-width: 50px;
                            padding: 10px 10px 0;
                            .icon{
                                display: inline-block;
                                width: 35px;
                                height: 35px;
                                border: solid 1px $gray;
                                background-color: $white;
                                text-align: center;
                                line-height: 35px;
                                border-radius: 10px;
                            }
                        }
                        .base-info{
                            padding: 10px 10px 0;
                            .user-name{
                                font-size: 16px;
                            }
                            .date{
                                text-transform: uppercase;
                                font-size: 12px;
                                opacity: 0.6;
                            }
                        }
                        .mark-new{
                            margin: 10px 10px auto auto;
                            color: $sanofi-purple;
                            text-transform: uppercase;
                            font-weight: 600;
                        }
                    }
                    .message-body{
                        font-size: 16px;
                        padding: 0 10px 10px;
                        .subject{
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            font-size: 12px;
                        }
                        .message-text{
                            opacity: 0.6;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }
                    &.unread{
                        background-color: $white;
                        border-color: $white;
                    }
                    &.active{
                        color: $white;
                        background-color: $sanofi-purple;
                        border-color: $sanofi-purple;
                        .message-info{
                            .avatar{
                                .icon{
                                    color: $gray-dark;
                                    border: solid 1px $sanofi-purple;
                                }
                            }

                        }
                    }

                }
            }
        }

    }

    .messages-content{
        background-color: $white;
        box-shadow: 0px 2px 5px 0px rgba(74,74,74,0.1);
        padding: 20px 30px 40px;
        min-height: 670px;
        &.no-messages{
            min-height: 300px;
        }
        .message-header{
            padding: 0 50px 30px 0;
            position: relative;
            .replay-btn{
                position: absolute;
                right: 0;
                top: 0;
                cursor: pointer;
                .icon{
                    //color: $sanofi-purple;
                    font-size: 30px;
                }
                &.active{
                    color: $sanofi-purple;
                }
            }
            .subject{
                font-size: 20px;
                font-weight: 600;
            }
            .user-name{
                color: $gray;
            }
        }
        .message-text{
            font-size: 18px;
        }
    }

    .reply-body{
        .leave-reply-wrapper{
            margin-top: 30px;
            border-top: solid 1px $gray-light;
            padding: 30px 0;

            &.sending-reply{
                opacity: 0;
            }
            .editor-textarea{
                border: none;
                min-height: 380px;
            }
            .trumbowyg-box{
                margin: 20px 0;
            }
            .submit-reply-btn{
                display: inline-block;
                padding: 10px 35px;
                color: $white;
                background-color: $gray-light;
                cursor: not-allowed;
                font-size: 16px;
                &.active-btn{
                    cursor: pointer;
                    background-color: $sanofi-purple;
                }
            }
            .rare-disease {
                color: $sanofi-purple;
            }
        }
        .replied-text{
            margin-top: 30px;
            padding: 20px 0 0 60px;
            border-top: solid 1px $gray-light;
            color: $gray;
            font-style: italic;
            .replied-date-wrap{
                color: $gray-dark;
                padding-bottom: 10px;
            }
        }
    }
}