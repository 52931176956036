.appointment-overview {
  padding: 1em 0;
  @media(min-width: $screen-sm) {
    display: flex;
    justify-content: space-between;
    &__left {
      width: 40%;
      margin-right: 2em;
    }
    &__right {
      width: 60%;
    }
  }
  @media(min-width: $screen-md) {
    &__left {
      width: 25%;
    }
    &__right {
      width: 75%;
    }
  }
}

.appointment-controls {
  background-color: $white;
  @extend .shadow-for-box;
  @media(min-width: $screen-sm) {
    min-height: 500px;
  }

  &__container {
    padding: 1em 2em;
    border-bottom: 1px solid #ddd;
    &:last-child {
      border-bottom: 0;
    }
    h4 {
      font-weight: 300;
    }
    .btn {
      width: 100%;
      .badge {
        position: absolute;
        right: 10px;
        top: 9px;
        background: $color_red;
        color: $white;
      }
    }
  }
}