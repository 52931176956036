.img-full-width {
    max-width: 100%;
    width: 100%;
}


///*------------------------------------*\
// Separator line for product listing etc.
//\*------------------------------------*/


@mixin sep-line {
    background: $color_lightgrey;
    height: 1px;
    width: 100%;
    clear: both;
}

@mixin sep-line-border {
    border-bottom:1px solid $color_lightgrey;
}

.sep-line {
    @include sep-line;
}
.sep-line-border {
    @include sep-line-border;
    &.sep-line-border--dotted {
        border-style: dotted;
        height: 1px;
        float: left;
        border: 1px dotted #e0e0e0;
        display: inline-block;
        width: 100%;

    }
}


.standart_h1 {
    @include fontMainLight;
    font-size: 28px;
    margin: 35px 0 25px;
}

//@include mq($until: wide) {

    img {
        max-width: 100%;
    }
//}


.bx-viewport li { min-height: 1px; min-width: 1px; }


[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {
    margin-left: 0px;
}


.color-white {
    color:white;
}