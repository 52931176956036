.listing-columns-container {
    //width: 1000px;
    //overflow: auto;
}

.listing-columns {
    width: 10000px;
}

.listing-column {
    float: left;
    display: inline-block;
    width: 243px;
    overflow: hidden;
    border: 1px solid #ccc;
    margin: 20px 20px 20px 0;
    padding: 10px;
    transition: 0.5s all;

    select {
        background: white;
        width: 100%;
        height: 30px;
    }

    &.listing-column--selected {
        border:1px solid $color_secondary;
        background-color: $color_secondary;
        color:white;
        select {
            color:black;
            background: white !important;
        }
    }

    .listing-column__title {
        @include fontMainSemiBold;
        margin: 10px 0;
        font-size: 18px;
    }

    .listing-column__columntitle {
        font-size: 12px;
        font-style: italic;
    }

    .listing-column__actions {}

    .listing-column__preview-title {
        //@include fontMainSemiBold;
        margin: 10px 0 5px;
        font-size: 16px;
    }

    .listing-column__preview {
        list-style: none;
        padding: 0;

        li {
            height: 2em;
            overflow: hidden;
            border-top: 1px solid #eee;
            padding: 5px 10px;
            font-size: 12px;
        }
    }
}


.form-filter-name {
    font-size: 12px;
    @include fontMainLight;
    margin: 5px 0;
    color:#ccc;
}
