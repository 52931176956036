// Adboard schedule survey styles
//-------------------------------------------------------------

//.adboard-schedule-survey
.adboard-schedule-survey {

    color: $color_text_survey;
    margin-bottom: 20px;

    //.adboard-schedule-survey__title
    &__title {
        font-weight: 600;
        font-size: 20px;
    }

    &__description {
        font-size: 15px;
        line-height: 18px;
    }

    &__create-edit-button {
        width: auto;
        -webkit-border-radius: 30px;
        -moz-border-radius: 30px;
        border-radius: 30px;
        display: inline-block;
        margin-bottom: 10px;
        margin-top: 10px;
        padding: 10px 15px 10px 15px;
        border: 1px solid $republic-m-green;
        background-color: $white;
        color: $republic-m-green;
        font-size: 15px;
        font-weight: 500;
        text-decoration: none;

        &:hover {
            text-decoration: none;
        }
    }

}

//.adboard-schedule-survey-modal
.adboard-schedule-survey-modal {

    &__add-button {
        font-size: 20px;
        margin: 10px;

        a {
            text-decoration: none;
            color: $republic-m-green;
        }
    }

    &__plus {
        color: $republic-m-green;
    }

    &__divider {
        border: 1px solid $color_lightgrey;
    }
}