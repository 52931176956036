///*------------------------------------*\
//    Detail page of a HCP
//\*------------------------------------*/
.hcp-detail-page-avatar {
    position: relative;
    top: 40px;
}

.hcp-detail-page {
    padding: 2em 0;
    font-size: 16px;

    ul {
        list-style: none;
        padding: 0;
        margin: 40px 0;
    }

    .hcp-detail-page__title {
        font-size: 21px;
        margin: 40px 0;
        @include fontMainSemiBold;

        .hcp-detail-page__email {
            font-size: 16px;

        }
    }

    .hcp-detail-page__sep-line {
        display: block;
        border-top: 1px dashed $color_darkgreen;
    }

    .hcp-detail-page__details {
        font-size: 14px;
        li {
            margin: 5px 0;
        }
    }

    .hcp-detail-page__optins {

        li {
            margin: 0 0 10px 0;
            .fa-check {
                color:$color_green;
            }
            .fa-times {

                color:red;
            }
        }
    }


    .hcp-detail-page__buttons {
        margin:40px 0;
        .btn {
            margin: 0 20px 0 0;
        }
    }
}