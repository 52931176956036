// Adboard manage user pages styles
//-------------------------------------------------------------

//.adboard-new-user-form
.adboard-new-user-form {

    padding: 0px 0 40px;
    margin: 40px 0;
    border-bottom: 2px solid #C1C1C1;
    border-radius: 2px;

}