.touchpoint {
    @extend .shadow-for-box;

    &-headline {
        position: relative;
        text-align: center;
        min-height: 250px;
        @include mq($until: tablet) {
            min-height: inherit;
        }

        img {
            min-width: 100%;
        }
    }

    &-headline__big-embed-area {
        height: 400px;
        background: black;
        overflow: hidden;
    }

    .touchpoint-headline__content {
        color: white;
        text-align: center;
        position: absolute;
        top: 50%;
        margin-top: -60px;
        width: 100%;
        text-shadow: #666 1px 1px 8px;

        .touchpoint-headline__title {
            font-size: 42px;
            @include fontMainBold;
        }

        .touchpoint-headline__subtitle {
            font-size: 32px;
        }

        @include mq($until: desktop) {
            position: relative;
            position: relative;
            top: auto;
            margin: 0;
            color: $color_text;
            text-shadow: none;
            .touchpoint-headline__title {
                font-size: 30px;
                @include fontMainBold;
            }

            .touchpoint-headline__subtitle {
                font-size: 20px;
            }
        }
    }

    .touchpoint-invitation-area {
        min-height: 170px;
        background: white;
        border-bottom: 2px solid $color_grey;
        padding: 60px 0;
        &.touchpoint-invitation-area--grey {
            background: $color_grey;
        }
    }

    .touchpoint-invitation {

        .touchpoint-invitation__text {
            font-size: 28px;
        }

        &__form {
            display: inline-block;
        }

        @include mq($until: desktop) {
            text-align: center;
            .touchpoint-invitation__text {
                display: block;
                margin-bottom: 20px;
            }
        }

        button {
            padding: 10px 50px;
        }
    }

    .touchpoint-content-area {
        background: white;
        padding: 2em 0;
    }

    .touchpoint-content__date {
        font-size: 30px;
        color: inherit;
        @include fontMainSemiBold;
    }
    .touchpoint-speakers__title {
        color: white;
        text-align: center;
    }

    .touchpoint-content__time {
        font-size: 24px;
        color: inherit;
    }

    .touchpoint-content__desc {
        padding: 1em 2em;
        @include mq($from: desktop) {
            padding: 1em 4em;
        }

        ol {
            padding-left: 20px;
        }
    }

    .touchpoint-speakers {
        padding: 2em 0;
    }

    .touchpoint-speakers__list {
        $speakerImgSize: 125px;
        $speakerImgSizeMob: 80px;
        text-align: center;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin-top: 2em;

        //.touchpoint-speakers__list.flex
        &.flex {
            text-align: left;
            flex-wrap: wrap;
            justify-content: flex-start;

            //.touchpoint-speakers__list.flex.open-speaker-detail-modal
            .open-speaker-detail-modal {
                display: flex;
                align-items: flex-start;
                flex-wrap: wrap;
                margin: 0;
                margin-bottom: 1em;

                @include mq($until: tablet) {
                    flex: 0 0 100%;
                    align-items: center;
                }

                @include mq($from: mobile, $until: desktop) {
                    flex-wrap: nowrap;
                }

                &:not(:last-child) {
                    margin-right: 1em;
                }

                //.touchpoint-speakers__list.flex.open-speaker-detail-modal.touchpoint-speakers__name
                .touchpoint-speakers__name {
                    padding-left: 0;
                    font-weight: 400;
                    margin-top: 0;
                }

                //.touchpoint-speakers__list.flex.open-speaker-detail-modal.touchpoint-speakers__title
                .touchpoint-speakers__title {
                    text-align: left;
                }

                //.touchpoint-speakers__list.flex.open-speaker-detail-modal.touchpoint-speakers__image
                .touchpoint-speakers__image {
                    flex: 0 0 $speakerImgSizeMob;

                    @include mq($from: desktop) {
                        flex: 0 0 $speakerImgSize;
                    }

                }

                //.touchpoint-speakers__list.flex.open-speaker-detail-modal.touchpoint-speakers__content
                .touchpoint-speakers__content {
                    margin-top: 1em;

                    @include mq($from: mobile, $until: desktop) {
                        margin-top: 0;
                        margin-left: 1em;
                    }
                }
            }
        }

        .open-speaker-detail-modal {
            cursor: pointer;
            margin: 2em;
            flex: 1 1 $speakerImgSizeMob;

            @include mq($from: desktop) {
                margin: 2em 4em;
                flex: 1 1 $speakerImgSize;
            }
        }

        img {
            border-radius: 100%;
            @include size($speakerImgSizeMob);

            @include mq($from: desktop) {
                @include size($speakerImgSize);
            }
        }

        .touchpoint-speakers__name {
            padding-left: 10px;
            margin-top: 20px;
            margin-bottom: 10px;
            font-size: 1.2em;
            color: white;
        }

        .touchpoint-speakers__title {
            text-align: center;
            color: white;
            font-size: 14px;
            @include fontMainLight;
        }
    }

    .touchpoint-teaser-area {
        background: #E8E7E3;
        padding: 80px 0;
    }

    .touchpoint-teaser__details {
        color: white;
    }

    .touchpoint-teaser__title {
        font-size: 24px;
        color: inherit;
        @include fontMainSemiBold;
    }

    .touchpoint-teaser__duration {
        font-style: italic;
        color: inherit;
    }

    .touchpoint-teaser__desc {
        color: inherit;
        margin: 20px 0 0;

    }

    .touchpoint-invitation__accept {
        text-transform: capitalize;
        position: relative;
        margin: 0 30px;
        top: -5px;
    }

    .touchpoint-teaser__video {
        iframe {
            max-width: 100%;
            width: 100%;
        }
    }
}

.touchpoint-program {
    background: $color_grey;
    padding: 90px 0;

    &.touchpoint-program--admin {
        background: white;
        padding: 0;

        .touchpoint-program__preview {
            background: #e5e5e5;
        }

        .touchpoint-program__list {
            list-style: none;
            li {
                list-style: none;
                background: #e5e5e5;
                padding: 10px 20px;
                margin: 5px 0;
                &.ui-sortable {
                    border: 1px dashed #333;
                }
                &.ui-sortable-helper {
                    background: $color_secondary;
                    color: $white !important;
                }
            }
        }

        .touchpoint-program-item {
            position: relative;
        }

        .touchpoint-program__day {
            .touchpoint-program__day-title {
                font-size: 21px;
                margin-top: 0;
                @include fontMainSemiBold;
            }
        }

    }

    .touchpoint-program__title {
        text-align: center;
        font-size: 30px;
        margin-bottom: 50px;
        @include fontMainSemiBold;
    }

    .touchpoint-program__day {
        .touchpoint-program__day-title {
            font-size: 21px;
            margin-top: 60px;
            @include fontMainSemiBold;
        }

        .touchpoint-program__day-table {
            tr {
                td {
                    padding: 10px 0;
                    vertical-align: top;
                }
            }

            .touchpoint-program__table-hours {
                font-size: 18px;
                padding-right: 30px;
                width: 150px;
                @include fontMainSemiBold;
            }

            .touchpoint-program__table-title {
                font-size: 18px;
                margin-bottom: 3px;
                @include fontMainSemiBold;
            }

            .touchpoint-program__table-name {
                font-size: 16px;
            }

            .touchpoint-program__table-tick {
                margin-right: 20px;
                .check {
                    @extend .margin-right-1;
                }
            }
        }
    }
}

.touchpoint-footer {
    margin-top: 20px;
    color: #e5e5e5;

    a {
        color: white;
    }

    .touchpoint-footer__copyright {
        display: inline-block;
        float: left;

        .touchpoint-footer__address {
            font-size: 13px;
            margin-top: 10px;
        }
    }

    .touchpoint-invitation--grey-bg {
        background-color: $color_grey;
    }

    .touchpoint-footer__jobcode {
        float: right;
        display: inline-block;
        margin-top: -20px;
        font-size: 13px;
        @include mq($until: desktop) {
            margin-top: 0;
        }
    }
    .touchpoint-footer__pages {
        display: inline-block;
        float: right;
        list-style: none;

        li {
            display: inline-block;
            float: left;
        }
    }

    .touchpoint-footer__sponsor-text {
        @include fontMainSemiBold;
    }

}

.touchpoint-video-embed {
    iframe {
        max-width: 100%;

    }
}

.touchpoint-top-menu {
    background: white;
    text-align: center;
    padding: 30px 0;
    @extend .shadow-for-box;

    img {
        max-height: 90px;
        max-width: 90%;
    }
}

.message-box__tick {
    width: 100px;
    height: 100px;
    background: rgb(141, 198, 63);
    color: white;
    text-align: center;
    padding-top: 15px;
    display: inline-block;
    margin: 30px 0px;
    border-radius: 100%;
    font-size: 45px;
}

.add-new-program-entry-box {
    label, label.control-label {
        color: #333;
    }
}