//Create new Project Modal
.create-new-project {

    margin: 50px 0;
    .create-new-project__text {
        margin: 0 0 50px;
        font-size: 20px;
    }

    .action-box {
        position: relative;
    }

    .info-popover {
        position: absolute;
        top: 3px;
        right: 6px;
        font-size: 16px;
        color: white;
        &:hover {
            color: #f5f5f5;
        }

    }
}

//Create new TP modal
.create-new-touchpoint {
    .form-group .control-label {
        color: #333;
    }

    .create-new-touchpoint__text {
        font-size: 20px;
        margin: 20px 0;
        text-align: center;
    }
}

///*------------------------------------*\
//    Project stakeholders
//\*------------------------------------*/

.project-stakeholder-form {

    .form-group {
        .control-label {
            color: #333;
            font-weight: bold;
            margin-bottom: 5px;
            font-size: 16px;
        }
    }

}

///*------------------------------------*\
//    Easy flow modal
//\*------------------------------------*/

.tp-ef-broadcast-settings {
    .bx-wrapper {
        margin-bottom: 30px;
        img {
            display: inline-block;
        }
        .bx-viewport {
            border: 0;
            left: 0;
            box-shadow: none;
        }
    }
}

.touchpoint-easy-flow-container
.tp-ef-stakeholders__selection {
    width: 60%;
    margin: 0 auto;
    .select2-container {
        width: 100% !important;
    }
}

.touchpoint-easy-flow-container {
    margin-bottom: 20px;
    .select2-container {
        width: 60% !important;
    }
}

.touchpoint-easy-flow-container {
    label, .form-group .control-label {
        color: #333;
        margin-bottom: 5px;
        font-size: 16px;

    }

    .select2-container--default .select2-selection--multiple {
        border: 0;
        border-bottom: 1px solid #333;
        border-radius: 0px;
    }

    .select2-container--default .select2-selection--single {
        border-radius: 0px;
        border-color: #333;

    }
}

.broadcast-settings-slider {
    .broadcast-settings-slider__item {
        text-align: center;
        position: relative;
        .broadcast-settings-slider__selected {
            position: absolute;
            width: 150px;
            height: 150px;
            display: inline-block;
            background: rgb(141, 198, 63);
            border-radius: 100%;
            top: 50%;
            margin-top: -75px;
            left: 50%;
            right: 50%;
            margin-left: -75px;
            color: white;
            font-size: 60px;
            padding-top: 30px;
        }
    }
}

.template-package-label {

    position: relative;
    cursor: pointer;
    .template-package__checked {
        display: none;
        position: absolute;
        text-align: center;
        top: 45px;
        position: absolute;
        right: 50%;
        left: 50%;
        margin-left: -25px;
        background: rgb(141, 198, 63);
        width: 50px;
        height: 50px;
        border-radius: 100%;
        padding-top: 14px;
        color: white;
        text-shadow: 1px 1px 5px #999;
    }

    &.template-package-label--selected {
        .template-package__checked {
            display: inline-block;
        }

    }
}

.service-package-column {

    &.service-package-column--1 {
        .service-package-column__title {
            background: #f15b40;
        }

    }
    &.service-package-column--2 {
        .service-package-column__title {
            background: #f15b40;
        }
    }
    &.service-package-column--3 {
        .service-package-column__title {
            background: #f15b40;
        }
    }

    .service-package-column__checked {
        position: absolute;
        text-align: center;
        bottom: 5px;
        right: 5px;
        background: rgb(141, 198, 63);
        width: 30px;
        height: 30px;
        border-radius: 100%;
        padding-top: 4px;
        color: #fff;
        text-shadow: 1px 1px 5px #999;
        font-size: 16px;
    }

    .service-package-column__title {
        font-size: 23px;
        text-align: center;
        color: white;
        padding: 20px 0;
        margin: 20px 0 0px;
        position: relative;
    }
    .service-package-column__price {
        font-size: 16px;
    }

    .service-package-column__list {
        background: white;
        padding: 10px 10px;
    }
 
    .service-package-column__cat-title {
        margin: 15px 0 5px;
        font-size: 16px;
    }
}

.spc-serv-name {
    font-size: 12px;
    color: #bbb;
    &.spc-selected {
        color: #333;
    }
}

.spc-serv-sep {
    color: #333;
    margin: 0 3px;
}

.modal-body, .modal-footer {
    &.modal-body--grey {
        background: $color_lightgrey;
    }
}

.service-compare {
    .service-compare__pushed {
        padding: 0 40px;
    }
    .service-compare__title {
        font-size: 22px;
        font-weight: bold;
        margin: 0 0 20px;
        min-height: 50px;

    }
    .service-compare__side {
        float: left;
        width: 50%;

        &.service-compare__side--left {
            border-right: 1px solid black;
            text-align: right;
            .service-compare__on-stick {
                right: 0;
            }
            .service-compare__on-dot {
                right: 10px;
                position: relative;
            }
        }
        &.service-compare__side--right {
            text-align: left;
        }
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }
    .service-compare__item-list {
        list-style: none;
    }
    .service-compare__item {
        margin: 5px 0;
    }
    .service-compare__onoff {
        width: 40px;
        display: inline-block;
    }

    .service-compare__on {
        position: relative;
        .service-compare__on-stick {
            width: 15px;
            height: 1px;
            background: black;
            display: inline-block;
            top: 8px;
            position: absolute;
        }
        .service-compare__on-dot {
            display: inline-block;
            width: 8px;
            height: 8px;
            border-radius: 100%;
            background: black;
            margin-left: 15px;
        }
    }
    .service-compare__passive {
        color: $color_textgrey + 100;
    }
}


.email-auto-campaign-table {
    border: 1px solid #C9C6C6;  
    border-radius: 5px; 
    background-color: #FFFFFF;  
    box-shadow: 0 0 4px 0 rgba(0,0,0,0.1);
    margin-bottom: 10px;
    padding-top: 5px;
    padding-bottom: 5px;

    .email-auto-campaign-table-cell {
        display: inline-block;
        vertical-align: middle;
    }

    .email-auto-campaign-table__checkbox {
        width: 3%;
        padding-left: 1em !important;
    }
    .email-auto-campaign-table__name {
        width: 38%;
    }
    .email-auto-campaign-table__status {
        width: 15%;
        text-align: left;
    }
    .email-auto-campaign-table__clicked {
        width: 9%;
        text-align: left;
        font-size: 12px;
    }
    .email-auto-campaign-table__opened {
        width: 9%;
        text-align: left;
        font-size: 12px;
    }
    .email-auto-campaign-table__duplicate {
        width: 23%;
        text-align: center;
    }
    .email-auto-campaign-table__edit {
        width: 5%;
        text-align: center;
    }
    .email-auto-campaign-table__send {
        width: 8%;
        text-align: center;
    }
    .padding-1 {
        padding-right: 5px;
        padding-left: 5px;
    }

    .preview-email i {
        color:#6b6b6b !important;
    }
}

.email-campaign-table {
    border: 1px solid #C9C6C6;  
    border-radius: 5px; 
    background-color: #FFFFFF;  
    box-shadow: 0 0 4px 0 rgba(0,0,0,0.1);
    margin-bottom: 10px;

    .email-campaign-table-cell {
        display: inline-block;
        vertical-align: middle;
    }

    .email-campaign-table__checkbox {
        width: 3%;
        padding-left: 1em !important;
    }
    .email-campaign-table__name {
        width: 38%;

        .email-campaign-created {
            font-size: 12px;
        }
    }
    .email-campaign-table__status {
        width: 15%;
        text-align: left;
    }
    .email-campaign-table__clicked {
        width: 9%;
        text-align: left;
        font-size: 12px;
    }
    .email-campaign-table__opened {
        width: 9%;
        text-align: left;
        font-size: 12px;
    }
    .email-campaign-table__duplicate {
        width: 18%;
        text-align: right;
    }
    .email-campaign-table__edit {
        width: 25%;
        text-align: right;
    }
    .email-campaign-table__send {
        width: 5%;
        text-align: center;
        float: right;
        position: relative;
        right: 10px;
        top: 10px;
    
    }
    .padding-1 {
        padding-right: 5px;
        padding-left: 5px;
    }

    .preview-email i {
        color:#6b6b6b !important;
    }
}

.edit-on-touchpoint-form {

    .form-group {
        margin: 10px 0 0 0;
    }

    .ics-button {
        margin-bottom: 20px;
    }

    .created-date {
        margin-top:15px;
    }

    .form-group.is-focused .form-control {
        background-image: none !important;
    }

    .delete-from-touchpoint {
        text-align: center;
    }
    .form-control, .form-group .form-control {
        height: auto;
        border: 1px solid #D8D8D8;
        border-radius: 6px !important;
        padding: 10px;
        background: none;
        box-shadow: none;
        font-style: normal;
        font-size: 15px;
        color: #616161;
    }

    .form-group label.control-label {
        color: #4A4A4A;
        font-style: normal;
    }


    .form-group .selectric {
        border: 1px solid #D8D8D8;
        border-radius: 6px;
        padding-left: 10px;
        .label {
            padding-top:0;
        }
    }

    .btn-blue {
        height: 27px;   
        width: 99px;    
        border-radius: 5px; 
        background-color: #36A9E3;
        padding: 11px 31px;
        color: #ffffff;
        font-style: normal;
        height: 43px;
        width: 110px;
        display: inline-block;
    }

    .btn-blue:hover {
        color:#464646 !important;
    }

    .btn-blue[disabled] {
        color: rgba(0, 0, 0, 0.56);
        background: #e6e6e6;
    }

    .btn-white {
        height: 23px;   
        width: 95px;    
        border: 2px solid #36A9E3;
        border-radius: 5px; 
        background-color: #ffffff;
        padding: 11px 31px;
        color: #36A9E3;
        font-style: normal;
        height: 43px;
        width: 110px;
        display: inline-block;
        margin-left: 10px;
    }

    .btn-white:hover {
        color:#ffffff !important;
        background-color: #36A9E3 !important;
    }

    .schedule-btn {
        margin-top: 32px;
    }

    .togglebutton label .toggle, .togglebutton label input[type=checkbox][disabled] + .toggle {
        width: 40px;
    }

    .togglebutton label input[type=checkbox]:checked + .toggle:after {
        left: 20px;
    }

    .togglebutton label .toggle:after {
        width: 31px;
        height: 31px;
        top: -9px;
    }

    .togglebutton label input[type=checkbox]:checked + .toggle {
        background-color: #ffffff;
        border: 1px solid #d4d4d4;
    }

    .togglebutton label input[type=checkbox]:checked+.toggle:after {
        background-color: #36A9E3 !important;
    }


}

.modal-with-title .modal-header .modal-title {

    position: relative;
    top: 3px;
    color: #4A4A4A;
    font-style: normal;
    font-size: 21px;
}

.modal-with-title .modal-header {
    border-bottom: 0px !important;
}
.campaign-calendar {
    /* DateTime picker design */
    &.xdsoft_datetimepicker {


    }

}

.campaign-calendar {
   transform: translateX(-35%);
   // display: block !important;
   font-family: raleway;
   font-size: 13px;
}

.campaign-calendar .xdsoft_calendar, .campaign-calendar .xdsoft_datepicker {
    width: 300px;
}

.campaign-calendar .xdsoft_label i {
    display: none;
}

.campaign-calendar .xdsoft_calendar {
    margin-top: 45px;
}

.campaign-calendar .xdsoft_label {
    font-weight: normal;
}

.campaign-calendar .xdsoft_today_button {
    margin-left: 40px;
}

.campaign-calendar .xdsoft_datepicker.active+.xdsoft_timepicker {
    transform: translateY(25%);
}

.campaign-calendar .xdsoft_timepicker .xdsoft_time_box >div >div {
    border: 0px;
    background: transparent;
}

.campaign-calendar .xdsoft_datepicker.active, .campaign-calendar .xdsoft_timepicker.active {
    text-align: center;
}

.campaign-calendar .xdsoft_calendar td:hover, .campaign-calendar .xdsoft_timepicker .xdsoft_time_box >div >div:hover {
    background: #49a487 !important;
}

.campaign-calendar .xdsoft_timepicker .xdsoft_time_box {
    border:0px;
    border-bottom: 0px;
}
.campaign-calendar .xdsoft_calendar th {
    text-transform: uppercase;
    color:#545454;
}

.campaign-calendar .xdsoft_calendar td, .campaign-calendar .xdsoft_calendar th {
    border: 0px;
    background: transparent;
    padding: 10px 4px;
    display: inline-block;
    width: 41px;
    height: 40px;
}

.campaign-calendar .xdsoft_calendar td > div {
    padding-right: 0px;
    text-align: center;
}

.campaign-calendar .xdsoft_calendar td:hover, .campaign-calendar .xdsoft_timepicker .xdsoft_time_box >div >div:hover {
    border-radius: 20px;
}

.campaign-calendar .xdsoft_calendar td.xdsoft_default, .campaign-calendar .xdsoft_calendar td.xdsoft_current, .campaign-calendar .xdsoft_timepicker .xdsoft_time_box >div >div.xdsoft_current {
     border-radius: 20px;
}