// Global styles
//-------------------------------------------------

body {
    //background: $color_grey;
    background-color: #fffff;
    color: #4D5254;
    letter-spacing: 1px;
    @include fontMain;
}

html, body {
    height: 100%;
}

b, strong {
    font-weight: 700;
}

table, .table {
    border-spacing: 0;
    border-collapse: collapse;
}

.btn {
    font-weight: 400;
}

[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {
    display: inline-block;
    font: normal normal normal 17px/1 Flaticon;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.site-content {
    min-height: 100%;
    height: auto;
    margin: 0 auto -90px;
    @include mq($until: desktop) {
        margin: 0 auto 0px;
    }
}

.footer, .site-content-push {
    height: 90px;
    @include mq($until: desktop) {
        height: auto;
    }
}

.container-full-width {
    width: 100%;
    @include null(margin-right margin-left, auto);
}

.small-form-container, .small-form-sidetext {
    padding: 50px;
}

#app-instance {
    position: relative;
}

#trigger-controls {
    z-index: 99;
}

body, h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4 {
    font-weight: 400;
}
