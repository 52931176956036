.hcp-meetingpoint-banner {
  background-repeat: no-repeat !important;
  background-position: 50% 100% !important;
  background-size: cover !important;
}

.hcp-meetingpoint {
  margin-top: 2em;
  &__upcomingevent {
    background: red;
    height: 250px;
    margin-bottom: 2em;
    position: relative;
    @extend .hcp-meetingpoint-banner;
    @extend .shadow-for-box;
    &-content {
      background: rgba(0, 0, 0, 0.4);
      color: $white;
      padding: 2em;
      @include abCenter;
      @media(max-width: $screen-sm) {
        padding: 1em;
        .btn {
          margin: 5px 0;
        }
      }
    }
    &-title, &-speaker {
      @media(max-width: $screen-sm) {
        font-size: 1.1em;
      }
      font-weight: 300;
    }
    &-speaker, &-date {
      .details & {
        margin: 0 !important;
      }
    }
    &.details {
      margin-bottom: 0;
    }
    &-alert {
      margin-top: 10px;
      margin-bottom: 0;
    }
  }
  &__events {
    color: inherit !important;
    display: block;
    overflow: auto;
    overflow-x: hidden;
    position: relative;
    margin-bottom: 2em;
    background-color: #fff;
    @extend .shadow-for-box;
    @media(min-width: $screen-xs) {
      min-height: 274px;
    }
    &-container {
      overflow: auto;
      .col-xs-6 {
        @media(max-width: $screen-xs) {
          width: 100%;
        }
      }
    }
    &-banner {
      height: 80px;
      @extend .hcp-meetingpoint-banner;
      @media(max-width: $screen-sm) {
        background-position-y: 20% !important;
      }
      @media(min-width: $screen-sm) {
        height: 150px;
      }
    }
    &-content {
      background: $white;
      padding: 1em;
      max-height: 164px;
      height: 164px;
      overflow: auto;
    }

    &-title {
      font-size: 1em;
      @media(min-width: $screen-sm) {
        font-size: 1.2em;
      }
    }
    &-speaker {
      font-size: .9em;
      @media(min-width: $screen-sm) {
        font-size: 1em;
      }
    }
    &-date {
      font-weight: 600;
    }

    .hcp-meetingpoint__events-buttons {
      text-align: right;
      position: absolute;
      bottom: 0;
      right: 0;
      padding: 0 40px;
      width: 100%;
    }
  }
  &__cards {
    height: 150px;
    padding: 1em;
    text-align: center;
    color: $white !important;
    text-decoration: none !important;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    @extend .shadow-for-box;
    @media(min-width: $screen-sm) {
      margin-bottom: 1em;
      padding: 2em 1em;
    }
    img {
      margin-bottom: 20px;
      max-width: 35px;
    }
    &-description {
      margin: 0;
    }
    &-container {
      margin-bottom: 2em;
      @media(max-width: 765px) {
        display: flex;
        .row {
          flex: 1;
        }
      }
      @media(max-width: $screen-xs) {
        flex-direction: column;
      }
    }
    &.empty {
      height: auto !important;
      padding: 1em !important;
      background: $white !important;
      color: $color_blue !important;
    }
  }
  &__footer {
    padding: 35px 0;
    &-links {
      display: inline-block;
      float: left;
      &.right {
        float: right;
      }
    }
    &-link {
      padding: 0 10px;
      border-right: 1px solid #337ab7;
      &:last-child {
        @include null(border-right padding-right);
      }
    }
    &-copyright {
      color: $color_lightgrey;
      font-weight: 300;
      margin-bottom: 0;
    }
    @media(max-width: $screen-xs) {
      display: flex;
      flex-direction: column;
      &-links {
        font-size: .8em;
        &:first-child {
          margin-bottom: 5px;
        }
      }
      &-link {
        &:first-child {
          padding-left: 0;
        }
      }
    }
  }
  &__details {
    background-color: $white;
    padding: 2em;
    @extend .shadow-for-box;
    &-content {
      margin: 0;
      margin-bottom: 10px;
    }
    &-container {
      margin-bottom: 2em;
    }
  }
  &__accept {
    .btn {
      margin: 0;
    }
  }
  &__survey {
    &-question {
      &:not(:last-child) {
        border-bottom: 1px solid #ddd;
      }
    }
    &-label {
      font-weight: 300;
      font-size: 12px;
      color: #333;
      display: inline-block;
      .checkbox & {
        margin-left: 15px;
      }
    }
  }
}
