// Ariana template layout styles
//-------------------------------------------------------------

//.template-layout
.template-layout {
    $headerHeight: 80px;
    $bannerHeight: 300px;
    $videoHeightDesktop: 700px;
    $videoHeightMobile: $videoHeightDesktop / 2.2;
    $videoPlayBtnSize: 6em;
    $speakersThumbnailSize: 100px;
    $speakersThumbnailPadding: .8em;
    $breakpointMain: tablet;
    $headerBoxShadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.3);
    $widthVideoContainer: 80%;

    @mixin headerAndFooterStyles() {
        background: $white;
        @include flexCenter();
        @include size(100%, $headerHeight);

        > div {
            display: flex;
            align-items: center;
            font-size: .9em;
            width: $widthVideoContainer;
            margin: auto;
        }

        img {
            max-height: 50px;
        }
    }

    @mixin hideHeaderItemsForMobile() {
        display: none;

        @include mq($from: $breakpointMain) {
            display: inherit;
        }
    }

    //.template-layout--ariana
    &--ariana {
        background: $white;

        .tp-about-video--title {
            margin-top: 100px;
        }

        //.template-layout--ariana .footer, .template-layout--ariana .site-content-push
        .footer, .site-content-push {
            display: none;
        }

        //.template-layout--ariana .header
        .header {
            box-shadow: $headerBoxShadow;
            position: fixed;
            z-index: 123;
            @include headerAndFooterStyles();
            @include null(top right left);

            > div {
                justify-content: space-between;
            }
        }

        //.template-layout--ariana .touchpoint
        .touchpoint {
            box-shadow: none;
            margin-top: $headerHeight;

            //.template-layout--ariana h1
            h1 {
                font-weight: 400;
                text-align: center;

                //.template-layout--ariana h1 span
                span {
                    display: block;
                    height: 4px;
                    border-radius: 4px;
                    width: 100px;
                    margin: 10px auto 0;
                }
            }

            //.template-layout--ariana .touchpoint-program
            &-program {
                background: $white;
                padding: 0;
            }

            //.template-layout--ariana .touchpoint-speakers
            &-speakers {

                //.template-layout--ariana .touchpoint-speakers__image
                &__image {
                    overflow: hidden;
                    margin: auto;
                    border-radius: 100%;
                    padding: $speakersThumbnailPadding;
                    @include size($speakersThumbnailSize);

                    @include mq($from: $breakpointMain) {
                        padding: $speakersThumbnailPadding + .2;
                        @include size($speakersThumbnailSize * 1.5);
                    }

                    @include mq($from: desktop) {
                        padding: $speakersThumbnailPadding + .4;
                        @include size($speakersThumbnailSize * 2);
                    }

                    //.template-layout--ariana .touchpoint-speakers__image img
                    img {
                        border-radius: 100%;
                        @include size(100%);
                    }
                }

                //.template-layout--ariana .touchpoint-speakers__content
                &__content {
                    margin-top: 1em;
                }

                //.template-layout--ariana .touchpoint-speakers__title
                &__title {
                    color: inherit;
                    font-weight: 300;
                }

                //.template-layout--ariana .touchpoint-speakers__name
                &__name {
                    text-transform: uppercase;
                    text-align: center;
                    font-weight: 500;
                    font-size: 1.2em;
                }
            }
        }

        //.template-layout--ariana .page
        .page {

            //.template-layout--ariana .page-footer
            &-footer {
                color: $white;
                justify-content: center;
                @include headerAndFooterStyles();

                //.template-layout--ariana .page-footer a
                a {
                    color: $white;
                }

                //.template-layout--ariana .page-footer--container
                &--container {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;
                }

                //.template-layout--ariana .page-footer--copyright
                &--copyright {
                    margin-right: 1em;
                }

                //.template-layout--ariana .page-footer--links
                &--links {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    //.template-layout--ariana .page-footer--links-container
                    &-container {
                        @include mq($from: $breakpointMain) {
                            margin-right: 2em;
                        }
                    }
                }

                //.template-layout--ariana .page-footer .client-logo-custom
                .client-logo-custom {
                    @include hideHeaderItemsForMobile();
                }
            }

            //.template-layout--ariana .page-content
            &-content {
                display: flex;
                justify-content: space-between;
                min-height: 500px;
                margin-top: 4em;
                padding: 4em;

                @include mq($until: tablet) {
                    margin-top: 2em;
                    padding: 2em 1em;
                    flex-wrap: wrap;
                }

                //.template-layout--ariana .page-content-header
                &-header {
                    @include flexCenter();

                    @include mq($until: tablet) {
                        flex-wrap: wrap;
                    }

                    //.template-layout--ariana .page-content-header__image
                    &__image {
                        margin-right: 2em;
                        height: $bannerHeight;
                        @include flexCenter();

                        @include mq($until: tablet) {
                            margin-right: 0;
                            margin-bottom: 2em;
                        }
                    }

                    //.template-layout--ariana .page-content-header__content
                    &__content {
                        align-self: flex-start;

                        //.template-layout--ariana .page-content-header__content h3
                        h3 {
                            margin-top: 0;
                            font-weight: 400;
                            line-height: 1.5;
                        }
                    }
                }

                //.template-layout--ariana .page-content-register
                &-register {
                    width: 30%;

                    @include mq($until: tablet) {
                        width: 100%;
                    }

                    //.template-layout--ariana .page-content-register h3
                    h3 {
                        margin-top: 0;
                    }
                }

                //.template-layout--ariana .page-content-description
                &-description {
                    width: 70%;

                    @include mq($until: tablet) {
                        width: 100%;
                        margin-top: 2em;
                    }

                    //.template-layout--ariana .page-content-description article
                    article {
                        width: 100%;

                        &:not(:last-child) {
                            padding-bottom: 2em;
                            margin-bottom: 2em;
                            border-bottom: 2px solid #ddd;
                        }

                        //.template-layout--ariana .page-content-description article p
                        p {
                            &:first-child {
                                margin: 0;
                            }
                        }
                    }
                }
            }

        }

        //.template-layout--ariana .tp
        .tp {

            //.template-layout--ariana .tp-content-container
            &-content-container {
                position: relative;
                flex-direction: column;
                width: 100%;
                padding: 4em 2em;
                @include flexCenter();
            }

            //.template-layout--ariana .tp--banner-button
            &--banner-button {
                display: flex;
                justify-content: center;
                position: absolute;
                bottom: -28px;
                @include null(right left);

                //.template-layout--ariana .tp--banner-button .btn
                .btn {
                    padding-top: 15px;
                    padding-bottom: 15px;
                    text-transform: uppercase;
                }
            }

            //.template-layout--ariana .tp-banner-container
            &-banner-container {
                padding-bottom: 2em;

                @include mq($from: $breakpointMain) {
                    padding-bottom: 15em;
                }
            }

            //.template-layout--ariana .tp-about
            &-about {

                //.template-layout--ariana .tp-about-video
                &-video {
                    margin: auto;
                    margin-bottom: 4em;
                    display: block;
                    position: relative;
                    @include size(100%, $videoHeightMobile);

                    @include mq($from: $breakpointMain) {
                        position: absolute;
                        width: $widthVideoContainer;
                        top: -100px;
                        margin-bottom: auto;
                        left: 0;
                        right: 0;
                        height: $videoHeightDesktop;
                    }

                    //.template-layout--ariana .tp-about-video iframe
                    iframe {
                        @include size(100%);
                    }

                    //.template-layout--ariana .tp-about-video-overlay
                    &-overlay {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        background-color: rgba(0, 0, 0, 0.5);
                        flex-direction: column;
                        z-index: 100;
                        @include flexCenter();

                        //.template-layout--ariana .tp-about-video-overlay .fa
                        .fa {
                            cursor: pointer;
                            font-size: $videoPlayBtnSize;
                            color: $white;

                            @include mq($from: $breakpointMain) {
                                font-size: $videoPlayBtnSize * 2.2;
                            }
                        }

                        //.template-layout--ariana .tp-about-video-overlay h1
                        h1 {
                            color: $white;

                            @include mq($until: tablet) {
                                font-size: 1.5em;
                            }

                            @include mq($until: desktop) {
                                margin-top: 100px;
                            }
                        }
                    }

                    //.template-layout--ariana .tp-about-video--content
                    &--content {
                        margin: auto;
                        margin-top: 2em;

                        @include mq($from: $breakpointMain) {
                            width: $widthVideoContainer / 2;
                        }
                    }
                }

                //.template-layout--ariana .tp-about-container
                &-container {
                    background: #ddd;

                    @include mq($from: $breakpointMain) {
                        padding-top: 650px;
                    }
                }
            }

            //.template-layout--ariana .tp-ondemand
            &-ondemand {

                //.template-layout--ariana .tp-ondemand-thumbs
                &-thumbs {
                    flex: 0 1 300px;
                    margin-top: 2em;

                    &:not(:last-child) {
                        margin-right: 1em;
                    }

                    //.template-layout--ariana .tp-ondemand-thumbs--iframe
                    &--iframe {

                        //.template-layout--ariana .tp-ondemand-thumbs--iframe.disabled
                        &.disabled {
                            pointer-events: none;
                            cursor: pointer;
                        }

                        //.template-layout--ariana .tp-ondemand-thumbs--iframe iframe
                        iframe {
                            @include size(100%, 150px);
                        }
                    }

                    //.template-layout--ariana .tp-ondemand-thumbs--iframe--content
                    &--content {
                        overflow: auto;
                        padding: 1em 0;
                        max-height: 200px;
                    }
                }

                //.template-layout--ariana .tp-ondemand-container
                &-container {
                    flex-wrap: wrap;
                    padding: 2em 4em;
                    @include flexCenter();
                }
            }

            //.template-layout--ariana .tp-speakers
            &-speakers {

                //.template-layout--ariana .tp-speakers-container
                &-container {
                    background: $white;
                }
            }

            //.template-layout--ariana .tp-speaker
            &-speaker {

                //.template-layout--ariana .tp-speaker-container
                &-container {
                    flex-wrap: wrap;
                    margin-top: 2.5em;
                    @include flexCenter();
                }
            }

            //.template-layout--ariana .tp-header
            &-header {

                //.template-layout--ariana .tp-header-links
                &-links {
                    @include hideHeaderItemsForMobile();

                    //.template-layout--ariana .tp-header-links a
                    a {
                        color: #4D5254;
                        font-size: 1.1em;
                        font-weight: 400;
                        text-decoration: none !important;

                        &:not(:last-child) {
                            padding-right: 1.2em;
                        }
                    }
                }

                //.template-layout--ariana .tp-header-actions
                &-actions {
                    @include hideHeaderItemsForMobile();
                }

                //.template-layout--ariana .tp-header-mobile
                &-mobile {
                    font-size: 2em;
                    cursor: pointer;

                    @include mq($from: $breakpointMain) {
                        display: none;
                    }

                    //.template-layout--ariana .tp-header-mobile .fa-close
                    .fa-close {
                        display: none;
                    }

                    //.template-layout--ariana .tp-header-mobile.active
                    &.active {

                        //.template-layout--ariana .tp-header-mobile.active .fa-close
                        .fa-close {
                            display: inherit;
                        }

                        //.template-layout--ariana .tp-header-mobile.active .fa-bars
                        .fa-bars {
                            display: none;
                        }
                    }
                }

                //.template-layout--ariana .tp-header-sidemenu
                &-sidemenu {
                    position: fixed;
                    z-index: 1000;
                    width: 200px;
                    right: 1.5em;
                    top: $headerHeight + 10;
                    background-color: $white;
                    box-shadow: $headerBoxShadow;

                    @include mq($from: $breakpointMain) {
                        display: none;
                    }

                    //.template-layout--ariana .tp-header-sidemenu a
                    a {
                        padding: 1em;
                        border-bottom: 1px solid #ddd;
                        @include resetAnchor();

                        &:hover {
                            background-color: #ddd;
                        }
                    }

                    //.template-layout--ariana .tp-header-sidemenu .btn
                    .btn {
                        margin-left: 1em;
                    }
                }
            }
        }

        //.template-layout--ariana .open-speaker-detail-modal
        .open-speaker-detail-modal {
            cursor: pointer;
            flex: 0 0 $speakersThumbnailSize * 3;
            margin-top: 1em;

            &:not(:last-child) {
                @include mq($from: $breakpointMain) {
                    margin-right: 1.5em;
                }
            }
        }

        //.template-layout--ariana .accreditation_exams_modal_content
        .accreditation_exams_modal_content {
            display: none;
        }

        //.template-layout--ariana .modal
        .modal {

            //.template-layout--ariana .modal .accreditation_exams_modal_content
            .accreditation_exams_modal_content {
                display: block;
            }

            //.template-layout--ariana .modal-header
            &-header {
                text-align: center;
            }

            //.template-layout--ariana .modal-content
            &-content {
                overflow: hidden;
                border-radius: 10px;
            }
        }

        //.template-layout--ariana .btn
        .btn {
            outline: none !important;

            &:not(.btn-raised) {
                color: $white;
            }

            //.template-layout--ariana .btn-rounded
            &-rounded {
                border-radius: 100px
            }
        }

        //.template-layout--ariana .container
        .container {
            @include mq($until: desktop) {
                width: 100%;
                padding: 0;
            }
        }

        //.template-layout--ariana .content-container
        .content-container {
            @include mq($until: desktop) {
                padding: 0 4em;
            }

            @include mq($until: tablet) {
                padding: 0 1em;
            }
        }

        //.template-layout--ariana .touchpoint-program .touchpoint-program__day .touchpoint-program__day-table .touchpoint-program__table-hours
        .touchpoint-program .touchpoint-program__day .touchpoint-program__day-table .touchpoint-program__table-hours {
            @include mq($until: tablet) {
                width: 50px;
            }
        }
    }
}