//.add-speaker-form-container
.add-speaker-form-container {

  //.add-speaker-form-container .togglebutton
  .togglebutton {
    margin: 10px 0;
    display: inline-block;

    //.add-speaker-form-container .togglebutton label input[type=checkbox]:checked+.toggle:after
    label input[type=checkbox]:checked + .toggle {
      background-color: rgba(255, 255, 255, 1);

      &:after {
        left: 15px;
        background-color: #28D228;
      }
    }
  }
}

//.speaker-card
.speaker-card {
  font-size: 14px;
  color: $color_textgrey;

  //.speaker-card__image
  &__image {
    width: 80px;
    height: 80px;
    margin-right: 20px;

    //.speaker-card__image img
    img {
      border-radius: 100%;
      width: 100%;
      height: 100%;
    }
  }

  //.speaker-card__name
  &__name {
    @include fontMainSemiBold;
  }

  //.speaker-card__edit
  &__edit {
    font-size: 11px;
  }
}