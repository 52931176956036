// Generic Colors
//-------------------------------------------------------------

$white: #fff;
$black: #000;
$color_text: #9a9a9a;

$color_textgrey: #4d5254;
$color_lightgrey: #eeeded;
$color_grey: #f8f8f8;
$color_bg: #CBE5D8;
$color_orange: #F58207;
$color_red: #F15B40;
$color_paleOrange: #EFC39D;
$color_green: #00a886;
$color_darkgreen: #009e7e;
$color_yellow: #eada86;
$color_blue: #67c4f0;
$color_pastelBlue: #75bee9;
$color_buttonBlue: #5556B5;
$color_overview: #9a9a9a;
$color_overview_item: #3CAD00;
$color_text_survey: #9B9B9B;
$color_button: #6FA23A;
$color_calendar_day: #9B9B9B;
$color_textLight: #9B9B9B;

$color_primary: #9E9978;
$color_secondary: #58595B;

$republic-m-blue: #5FA7E5;
$republic-m-green: #78A12E;
$republic-m-lightgreen: #C6E889;
$login-box-gray: #ADAFB1;
$login-box-gray_dark: #7B7C80;

$status_pending: #F5A623;
$status_accept: #6FA23A;
$status_decline: #D0021B;
