// Define login box scss
//-------------------------------------------------------------

//.login-app
.login-app {
    $loginBoxWidth: 500px;
    $loginBoxHeight: 350px;
    $marginTopDistanceLoginBox: 3em;

    padding: 1em;
    @include flexCenter;

    width:100%;
    display:block;

    //.login-app__box
    &__box {
        padding: 2em;
        border-radius: 20px;
        max-width: $loginBoxWidth;
        background-color: rgba($white, .6);
        min-height: $loginBoxHeight;
        margin: $marginTopDistanceLoginBox auto auto auto;
        @include flexCenter;
        @include size(100%);

        @include mq($from: desktop) {
            margin-top: $marginTopDistanceLoginBox * 2;
        }

        //.modal-body .login-app__box
        .modal-body & {
            margin-top: 0;
            min-height: initial;
        }
    }

    //.login-app__form
    &__form {
        display: flex;
        width: 100%;
        @include mq($from: desktop) {
            margin-top: $marginTopDistanceLoginBox * 2;
            width: $loginBoxWidth;
        }
        //.login-app__form > div
        > div {
            width: 100%;
            margin: auto;
            max-width: $loginBoxWidth / 1.3;
        }

        //.login-app__form label.control-label
        label.control-label {
            font-weight: 300;
            color: $login-box-gray;
        }

        //.login-app__form .btn
        .btn {
            text-transform: uppercase;
            font-size: 1.5em;
            margin-top: 3em;
            font-weight: 300;
        }

        //.modal-body .login-app__form
        .modal-body & {

            //.modal-body .login-app__form .btn
            .btn {
                margin-top: 2em;
            }
        }

        //.login-app__form .alert
        .alert {
            width: 100%;
            margin-bottom: 3em;
        }

        //.login-app__form-inputs
        &-inputs {
            display: flex;
            justify-content: space-between;

            &:not(:first-child) {
                margin-top: 2em;
            }

            //.login-app__form-inputs .fa
            .fa {
                display: flex;
                align-items: center;
                margin-right: .8em;
                font-size: 2em;
                color: $login-box-gray_dark;
            }

            //.login-app__form-inputs .form-group
            .form-group {
                width: 100%;
                margin: 0;

                //.login-app__form-inputs .form-group a
                a {
                    position: absolute;
                    bottom: -20px;
                    font-weight: 300;
                    color: $login-box-gray_dark;
                    @include resetAnchor();
                }
            }
        }
    }
}