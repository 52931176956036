.appointment-content {
  display: flex;
  flex-direction: column-reverse;
  @media(min-width: $screen-md) {
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
  }
  &__container {
    padding: 1.2em;
    background-color: $white;
    overflow: auto;
    @extend .shadow-for-box;
    @media(min-width: $screen-sm) {
      min-height: calc(500px - 63px);
    }
    @media(min-width: $screen-md) {
      padding: 2em;
      width: 70%;
      margin-right: 2em;
      min-height: 500px;
    }
    &.loading {
      display: flex;
      justify-content: center;
      align-items: center;
      @media(min-width: $screen-sm) {
        height: calc(500px - 63px);
      }
      @media(min-width: $screen-md) {
        height: 500px;
      }
    }
  }
  &__title {
    margin-bottom: 25px;
    h3, h4, h5 {
      font-weight: 300;
    }
    h3 {
      font-size: 1.5em;
      margin: 10px 0 20px;
    }
    h4 {
      font-size: 1.15em;
    }
    table {
      width: 100%;
      b {
        margin-right: 10px;
      }
    }
  }
  &__content {
    article {
      a {
        display: inline-block;
        margin-top: 1em;
      }
    }
  }
  &__section-title {
    font-size: 14px;
    font-weight: 600;
    margin: 10px 0 5px;
  }
  &__section-participants {
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }
  }
  &__section-area {
    margin: 20px 0;
  }
  &__options {
    @media(max-width: $screen-md) {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
    }
    @media(min-width: $screen-md) {
      width: 30%;
    }
    .btn {
      @media(min-width: $screen-sm) and (max-width: $screen-md) {
        margin-top: 0;
      }
      width: 100%;
      border-radius: 0;
      padding: 1em;
      &:first-child {
        margin-top: 0;
      }
      img {
        max-width: 20px;
        margin-right: 5px;
      }
      .fa {
        margin-right: 5px;
      }
    }
  }
  &__report {
    padding: 1em 0;
  }
  &__table {
    width: 100%;
    border-collapse: collapse;
    h3 {
      margin: 0;
    }
    th, td {
      padding: 1em 0;
      border-bottom: 1px solid #ddd;
    }
  }
}

.appointment-speakers {
  display: flex;
  margin-bottom: 1em;
  &__content {
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    p {
      margin: 0;
      &:first-child {
        font-weight: bold;
      }
    }
  }
  &__image {
    @include size(70px);
    border-radius: 50%;
    display: block;
    overflow: hidden;
  }
}