.btn {
    text-transform: none;
    color: white;
    .btn-info {
        color: white;
        background: $color_primary;
    }
}


//OVERRIDE
.btn.btn-raised.btn-info {
    background-color: $color_primary;
}

.btn-group-raised .btn:not(.btn-link).active.btn-info,
.btn-group-raised .btn:not(.btn-link):active.btn-info,
.btn-group-raised .btn:not(.btn-link):focus.btn-info,
.btn-group-raised .btn:not(.btn-link):hover.btn-info,
.btn-group-raised .input-group-btn .btn:not(.btn-link).active.btn-info,
.btn-group-raised .input-group-btn .btn:not(.btn-link):active.btn-info,
.btn-group-raised .input-group-btn .btn:not(.btn-link):focus.btn-info,
.btn-group-raised .input-group-btn .btn:not(.btn-link):hover.btn-info,
.btn.btn-raised:not(.btn-link).active.btn-info,
.btn.btn-raised:not(.btn-link):active.btn-info,
.btn.btn-raised:not(.btn-link):focus.btn-info,
.btn.btn-raised:not(.btn-link):hover.btn-info,
.input-group-btn .btn.btn-raised:not(.btn-link).active.btn-info,
.input-group-btn .btn.btn-raised:not(.btn-link):active.btn-info,
.input-group-btn .btn.btn-raised:not(.btn-link):focus.btn-info,
.input-group-btn .btn.btn-raised:not(.btn-link):hover.btn-info {
    background-color: $color_primary + 20;
}

.round-button {
    width: 40px;
    height: 40px;
    display: inline-block;
    text-align: center;
    border: 0;
    background: $color_grey;
    border-radius: 100%;
    font-size: 20px;
}

.clear-file-input {
    color: red;
    cursor: pointer;
    &:hover {
        color: red + 40;
    }
}

.tooltip-inner {
    background-color: #151515;
    padding: 8px 15px;
    line-height: 1.3;
}

.btn-no-transform {
    text-transform: none;
}