.community-container {
    margin: 0px 0;
}

.community-threads, .community-group {
    .table.mcm-table td, .table.mcm-table th {
        &.community-threads--row-l {
            padding-left: 20px;
        }
    }

    .community-threads--row-l {
        width: 45%;
        padding: 15px 5%;
    }
}

.community-post-input {

    width: 95%;
    height: 45px;
    border: 1px solid #4D5254;
    border-radius: 3px;
    margin-top: 10px;
    resize: none;
}

.community-post-submit {
    width: 100%;
    height: 45px;

}

.community-posts {
    margin: 1em 0;
}

.community-post {
    margin-bottom: 1em;

    .community-post__title {
        margin-bottom: 3px;
    }
    .community-post__date {
        @include fontMain;
        font-size: 12px;
        line-height: 13px;
        color: #BFBFBF;
        margin-left: 10px;
    }

    .community-post__body {
        margin: 12px 0;
        font-size: 16px;
        line-height: 1.5;
    }

    //for child comments
    .community-post {
        .community-post__title {
            font-size: 14px;
            font-weight: 500;
            font-style: italic;
            line-height: 19px;
        }

        .community-post__msg-box {
            width: 80%;
            margin: 0 auto;
        }
        .community-post__body {
            font-size: 14px;
            font-style: italic;
            line-height: 20px;
        }
    }

}

.community-thread__header {
    margin-bottom: 1em;
}

/**
 * New interface for community
 */

.community-layout {
    //temp
    min-height: 850px;

    .mcm-sidebar-flex {
        @include mq($from: desktop) {
            display: flex;
            width: 390px;

            .mcm-sidebar {
                width: 100%;
            }
        }

    }

    .community-layout__sidebar {
        background-image: url('/img/default_pictures/community-sidebar.jpg');
        background-size: cover;
        background-position: center center;
    }
    .community-layout__content {
        padding: 48px;
        overflow: auto;
        height: 90vh;
    }
}

.community-container {
    font-family: $font-family-sans-serif;
}

.community-sidebar-box {

    width: 294px;
    min-height: 216px;
    background-color: #FFFFFF;
    margin: 55px auto 0;
    padding: 24px 32px;
    .community-sidebar-box__title {

        font-size: 36px;
        font-weight: bold;
        line-height: 42px;

        margin-bottom: 20px;
    }
    .community-sidebar-box__text {

        font-size: 18px;
        line-height: 28px;
        color: #000000;
    }

}

.community-sidebar-detail-box {
    color: white;
    width: 294px;
    background-color: #000;
    margin: 55px auto 0;
    padding: 24px 32px;

    //.community-sidebar-box__title
    &__title {
        font-size: 24px;
        font-weight: bold;
        line-height: 40px;
        color: #FFFFFF;
        margin-bottom: 20px;
    }

    //.community-sidebar-box__content
    &__content {
        a {
            color: white;
        }
    }

    &__content-item {
        margin: 10px 0;
    }
}

.community-layout__sidebar {

    &.mcm-sidebar {
        box-shadow: none;
    }

    .mcm-sidebar-back-btn {
        width: 294px;
        background: white;
        margin: 0 auto;
        text-align: left;
        padding-left: 30px;
        padding-right: 30px;
    }
}

.community-title {
    font-size: 24px;
    font-weight: bold;
    line-height: 42px;
    color: #FFF;
    margin-bottom: 20px;
}

/**
* Card
 */
.community-group-card {

    position: relative;
    width: 250px;
    height: 200px;
    cursor: pointer;
    float: left;
    margin: 0 20px 20px 0;
    box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.09);
    .community-group-card__title {
        position: absolute;
        bottom: 0;
        background: rgba(234, 101, 13, 0.85);
        width: 100%;
        padding: 9px 20px;
        font-size: 16px;
        line-height: 24px;
        color: #FFFFFF;
    }
}

/**
    Popover details
 */
.community-group-card-detail {
    position: absolute;
    top: -60px;
    width: 400px;
    background-color: #FFFFFF;
    box-shadow: 0 0 27px 0 rgba(0, 0, 0, 0.31);
    z-index: 10;
    left: 200px;

    .community-group-card-detail__title {
        padding: 20px 30px;
        font-weight: bold;
        margin: 0;
    }

    .community-group-card-detail__list {
        list-style: none;
        padding: 0;
        margin: 0;
        li {
            border-top:1px solid #ccc;
            padding: 14px 30px;
            &:last-child {
                border-bottom:1px solid #ccc;
            }
            a {
            }
        }
    }

    &__arrow {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 20px 20px 20px 0;
        border-color: transparent #ffffff transparent transparent;
        position: absolute;
        top: 50%;
        left: -20px;
        margin-top: -20px;
    }
}

/**
Threads
 */
.community-threads {
    .mcm-overview-row {
        padding: 0;
        border: 0;
        cursor: inherit;
    }
    .mcm-overview-row--wrapper {
        margin-bottom: 10px;
        padding: 12px 30px;
        margin-left: 0;
    }

    .community-thread-title {
        font-size: 18px;
        font-weight: bold;
        line-height: 29px;
    }

    .community-thread-date {
        font-size: 12px;
    }
}

.community-sidebar-topics {
    .community-sidebar-topic {
        border-top: 1px solid #ccc;
        color: black;
        font-weight: 300;
        &.community-sidebar-topic--current {
            color: white;
        }
    }
}