// Adboard schedule styles
//-------------------------------------------------------------

//.adboard-schedule
.adboard-schedule {

    //.adboard-schedule--wrapper
    &--wrapper {
        display: flex;
        justify-content: space-between;
        margin: 3em auto;

        @include mq($until: tablet) {
            flex-wrap: wrap;
        }
    }

    //.adboard-schedule .mcm-title-area__search
    .mcm-title-area__search {

        //.adboard-schedule .mcm-title-area__search .form-group
        .form-group {
            width: 100%;
        }
    }

    //.adboard-schedule--container
    &--container {
        width: 100%;
    }

    //.adboard-schedule--body
    &--body {
        background-color: $white;

        //.adboard-schedule--body .container
        .container {
            > h3, > h5 {
                @include mq($until: tablet) {
                    text-align: center;
                }
            }
        }
    }

    //.adboard-schedule--subject
    &--subject {
        @include flexCenter();

        //.adboard-schedule--subject .form-group
        .form-group {
            width: 100%;
            max-width: 450px;
        }
    }

    //.adboard-schedule--time
    &--time {
        display: flex;
        justify-content: space-between;
        align-items: center;

        //.adboard-schedule--time span
        span {
            position: relative;
            height: 3px;
            width: 20px;
            margin: 0 10px;
            background-color: $login-box-gray;
        }

    }

    //.adboard-schedule--members
    &--members {

        //.adboard-schedule--members .mcm-overview-row--item
        .mcm-overview-row--item {
            &:first-child, &:last-child {
                flex: 0;
            }
        }
    }

    //.adboard-schedule .form-group
    .form-group {

        //.adboard-schedule .form-group .control-label
        .control-label {
            color: $login-box-gray;
            font-size: $font-size-base;
            font-weight: 300;
        }

        //.adboard-schedule .form-group .form-control
        .form-control {
            background-image: linear-gradient($login-box-gray, $login-box-gray), linear-gradient($login-box-gray, $login-box-gray);
        }
    }

    //.adboard-schedule--submit
    &--submit {
        text-align: center;

        //.adboard-schedule--submit.btn
        .btn {
            text-transform: uppercase;
            font-weight: 300;
            width: 100%;
            max-width: 300px;
        }
    }

}