// Accreditation styles
//-------------------------------------------------------------

//.accreditation
.accreditation {

  //.accreditation-timeline
  &-timeline {
    $borderWidth: 2px;

    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    margin-bottom: 2em;

    //.accreditation-timeline--item
    &--item {
      position: relative;
      cursor: pointer;
      border-radius: 100%;
      background-color: $white;
      border: $borderWidth solid $gray;
      font-size: 1.1em;
      @include flexCenter();
      @include size(35px);

      &:not(:last-child) {
        margin-right: 1em;
      }
    }

    //.accreditation-timeline--line
    &--line {
      position: absolute;
      display: block;
      margin: auto;
      width: 100%;
      height: $borderWidth;
      background-color: $gray;
      @include null(top bottom right left);
    }
  }
}