.tp-participant-lists {
    &.mcm-div-table {
        // We reset padding because this table has a row element
        // to make hover available
        li {
            padding: 4px 2px;
        }
    }

    .tp-participant-lists__visible-on-hover {
        pointer-events: none;
        opacity: 0;
        transition: 0.3s all;
    }

    .tp-participant-lists__row {
        cursor: pointer;
        padding: 16px 10px;
        transition: 0.3s all;
        .tp-participant-lists__visible-on-hover {
            i {
                font-size: 20px;
                color: #75bee9;
            }
        }

        &:hover {
            background-color: #ECECEC;
            box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.25);
            border-radius: 3px;
            .tp-participant-lists__visible-on-hover {
                pointer-events: all;
                opacity: 1;
            }

        }
    }
}