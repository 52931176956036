.hcp-register {

    .form-group {
        .control-label {
            color: #333;
        }
    }

    .checkbox {
        label {
            color: #333333;
        }
    }


    .label-tooltip {
        margin-left: 10px;
        font-size: 14px;
    }

    .tooltip {
        width: 300px;
        .tooltip-inner {

            max-width: 300px;
            text-align: left;
            line-height: 1.5;
            .hasline {
                border-top: 1px solid #e5e5e5;
                padding-top: 10px;
            }
        }
    }

}

.after-label-content {
    display: inline;
}
