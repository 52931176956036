.gr-container:after {
  content: "";
  display: table;
  clear: both;
}

.gr-container {
  margin: 0 auto;
  max-width: 1100px;
}
