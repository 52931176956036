/*
* Info cards about clients on dashboard screens
*/
.area-info-card {
    background: white;
    margin-bottom: 20px;
    box-shadow: -1px 2px 11px #ccc;
    padding-bottom: 20px;

    .area-info-card__type {
        font-size: 12px;

    }
    .area-info-card__btn {
        width: 60%;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    &.area-info-card--not-owner {

        .area-info-card__title,
        .area-info-card__links-arrow {
            opacity: 0.6;
        }
    }

    &.area-info-card--empty {
        background: white;
        border: 1px solid white;
        transition: 0.3s all;
        &:hover {

            border: 1px dashed $color_text;
            a {
                color: $color_text;
            }
        }

        a {
            display: block;
            padding: 100px 0;
            text-align: center;

            color: #666;
            text-decoration: none;

        }
    }

    .area-info-card__title {
        text-align: center;
        font-size: 18px;
        @include fontMainLight;
        color: white;
        padding: 15px 0;
        background: $color_secondary;
        min-height: 70px;
        align-items: center;
        a {
            color: white;
            text-decoration: none;
            &:hover {
                color: white;
            }
        }
    }

    .area-info-card__links {
        background: white;
        padding: 30px 0 20px;
        list-style: none;
        font-size: 12px;
        position: relative;
        color: $color_secondary;
        letter-spacing: 0.5px;

        .area-info-card__links-arrow {
            position: absolute;
            left: 50%;
            right: 50%;
            font-size: 30px;
            color: inherit;
            top: -20px;
            display: inline-block;
            margin-left: -9px;
        }

        a {
            color: $color_secondary;
            margin-left: 3px;
            font-size: 14px;
        }

        .button-space {
            height: 59px;
        }
    }

    .area-info-card__link {
        margin: 10px 0;
    }

    .area-info-card__link-label {
        width: 30px;
        height: 24px;
        background: $color_secondary;
        color: white;
        text-align: right;
        display: inline-block;
        border-radius: 0 10px 10px 0;
        padding: 1px 5px 0 0;
        font-size: 15px;

    }

    .area-info-card__content {
        padding: 10px 35px;

        .area-info-card__meta {
        }
    }

    .area-info-card__meta-list {
        //height: 80px;
        list-style: none;
        padding-left: 0;

        li {
            margin-bottom: 4px;
        }
    }

    .area-info-card__meta-item-title {
        font-weight: bold;
    }

    .area-info-card__button {
        margin-right: 10px;
        background-color: $color_secondary;
        color: white;
        padding: 5px 0;
    }

    .area-info-card__buttons {
        width: 100%;
        margin: 0;
        padding: 20px 35px;
    }

}

.area-info-card {
    &.area-info-card--video {
        cursor: pointer;

        .area-info-card__title {
            padding: 15px;
            font-size: 16px;
            background: $color_secondary;
            h3 {
                margin: 0;
            }
        }

        //video card images
        .area-info-card__video-image {
            height: 185px;
            width: 100%;
            background-size: cover;
            background-position: center center;
        }
    }
}
