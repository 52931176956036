.appointment-cards{
    margin-bottom: 2em;
    &:last-child{
        margin-bottom: 0;
    }
    @media(min-width:$screen-sm){
        display:flex;
        align-content:flex-start;
        flex-wrap:wrap;
    }
    &__wrapper{
        margin-top:2em;
        @media(min-width:$screen-sm){
            margin-top:0;
        }
    }
    &__card{
        display:block;
        overflow:auto;
        position: relative;
        background:$white;
        margin-bottom:1em;
        height:190px;
        flex: 0 100%; 
        text-decoration: none !important ;
        color: inherit !important ;
        @extend .shadow-for-box; 
        @media(min-width:$screen-sm){
            &:last-child{
                margin-bottom:0;
            }
        }
        @media(min-width:$screen-md){
            flex:0 198px;
            margin-right: 1em;
            &:nth-child(3n),&:last-child{
                margin-right:0;
            }
            &:last-child{
                margin-bottom:1em;
            }
        }
        @media(min-width:1200px){
            flex:0 240px;
            flex: 0 32%;
        }
    }
    &__title{
        h5{
            text-transform: uppercase;
        }
    }
    &__container{
        padding:.5em 1em;
        &:first-child{
            background-color:$color_blue;
            padding: 15px 25px;
            p{
                display:flex;
                justify-content:space-between;
                align-items:center;
                margin:auto;
                span{
                    color:$white;
                    &:first-child{
                        font-size:1.8em;
                        @include fontMainSemiBold;
                    }
                    &:last-child{
                        line-height: 1;
                        font-size: 1.1em;
                        font-weight:300;
                    }
                }
            }
        }
        &:last-child{
            padding-top:1em;
        }
        article{
            p{
                &:last-child{
                    font-size:.85em;
                }
            }
        }
    }
}