.accordion-item{
  padding-bottom: 30px;
  &:after{
    content: '';
    display: block;
    clear: both;
  }
  .section-title{
    padding: 20px 12px 0;
    font-weight: 700;
    max-width: 250px;
    position: relative;
    font-size: 20px;
    .toggle-btn{
      position: absolute;
      top: 20px;
      right: 0;
      width: 32px;
      height: 32px;
      line-height: 32px;
      border: solid 1px $gray;
      border-radius: 50%;
      text-align: center;
      color: $sanofi-purple;
      cursor: pointer;
      .icon{
        transition: transform 0.2s ease;
        font-size: 30px;
      }
      &.closed{
        color: $gray;
        .icon{
          transform: rotate(180deg);
        }
      }
    }
  }
}
