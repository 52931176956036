//.project-card
.project-card {
    background: $color_primary;
    text-align: center;
    color: white;
    border: 1px solid $color_primary;
    transition: 0.3s all;
    margin-bottom: 20px;
    min-height: 270px;
    @extend .shadow-for-box;

    &:hover {
        background: $color_primary + (-40);
    }


    //.project-card a
    a {
        padding: 40px 15px 50px;
        color: white;
        display: block;
        text-decoration: none;
    }

    //.project-card.project-card--campaign
    &.project-card--campaign {
        background: $color_secondary;
        border: 1px solid $color_secondary;

        &:hover {
            background: $color_secondary + (-40);
        }
    }

    //.project-card__icon
    &__icon {
        font-size: 50px;
        @include flaticonFontSize();
    }

    //.project-card__title
    &__title {
        margin: 20px 0 10px;
        font-size: 21px;
        line-height: 1.5;
    }

    //.project-card__type
    &__type {
        font-size: 12px;
        text-transform: uppercase;
    }

}