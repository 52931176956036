.action-box {
    text-align: center;
    width: 165px;
    height: 165px;
    //default color
    background: $color_primary;
    display: inline-block;
    color: white;
    text-decoration: none;
    padding: 30px 0 0;
    margin: 0 10px;
    box-shadow: 1px 1px 8px #999;
    cursor: pointer;
    transition: 0.3s all;

    &:hover {
        text-decoration: none;
        box-shadow: 1px 1px 8px #666;
        color: white;
        background: $color_primary +(-40);
    }


    &.action-box--sm {
        width: 110px;
        height: 110px;
        padding: 20px 0 0;
    }

    &.action-box--green {
        background: $color_secondary;
        &:hover {

            background: $color_secondary +(-40);
        }
    }

    &__icon {
        font-size: 50px;
        margin-bottom: 10px;
        @include flaticonFontSize(50px);
    }
    &__title {

    }
}