//Projects overview template

//.area-projects-container
.area-projects-container {
    background: #fff;
    width: 100%;
    display: block;
    float: left;
    margin: 20px 0;
    padding: 10px 20px;

    //.area-projects-container .area-projects__title
    .area-projects__title {
        margin: 20px 0;
    }

    //.area-projects-container .area-info-card, .area-projects-container .area-info-card__links
    .area-info-card, .area-info-card__links {
        box-shadow: none;
        background: $color_lightgrey;
    }
}

//.projects-touchpoints
.projects-touchpoints {

    //.projects-touchpoints span
    span {
        display: inline-block;
    }

    //.projects-touchpoints--container
    &--container {
        padding: 2em;

        @include mq($from: desktop) {
            padding: 2em 4em;
        }
    }

    //.projects-touchpoints--label
    &--label {
        display: inline-block;
        padding: 1em;
        color: #fff;
        margin: 0 0 1em;
    }

    //.projects-touchpoints--upcoming
    &--upcoming {
        display: flex;

        //.projects-touchpoints--upcoming.alt
        &.alt {
            flex-wrap: wrap;
            margin-top: 1em;
        }

        @include mq($until: desktop) {
            flex-wrap: wrap;
        }
    }

    //.projects-touchpoints--item
    &--item {
        $itemHeight: 150px;
        $itemDistance: 10px;
        @mixin verticleTpItem() {
            flex-direction: column;
            flex: 0 0 280px;

            &:not(.first):not(:last-child) {
                margin-right: 1em;
            }

            .mcm-template--background, .projects-touchpoints--item-content {
                height: $itemHeight;
            }

            @include mq($until: mobile) {
                flex-basis: 100%;
            }
        }

        width: 100%;
        overflow: hidden;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        @include resetAnchor($display: flex);
        @extend .shadow-for-box;

        &:not(.first):not(:last-child) {
            margin-bottom: $itemDistance + 10px;
        }

        //.projects-touchpoints--item.first
        &.first {
            margin-right: 1em;

            .projects-touchpoints--item-content, .mcm-template--background {
                //height: $itemHeight * 2 !important;
            }

            .projects-touchpoints--item-excerpt {
                margin: 1em 0;
                line-height: 1.5;
                font-size: 15px;
                font-weight: 300;
            }
            .projects-touchpoints--item-content {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                margin-top:10px ;

                .btn {
                    margin: 20px 0 1em 0;
                }
            }

            @include mq($until: desktop) {
                margin-right: 0;
                margin-bottom: $itemDistance + 10;
            }

            @include mq($until: mobile) {
                @include verticleTpItem();

                .projects-touchpoints--item-image {
                    width: 100%;
                }

                .mcm-template--background {
                    height: $itemHeight !important;
                }
            }
        }

        //.projects-touchpoints--item.verticle
        &.verticle {
            @include verticleTpItem();
        }

        //.projects-touchpoints--item-image
        &-image {
            width: 100%;

            //.projects-touchpoints--item.verticle .projects-touchpoints--item-image
            .projects-touchpoints--item.verticle & {
                width: 100%;
            }
        }

        //.projects-touchpoints--item-content
        &-content {
            width: 100%;
            padding: 1em 2em;
            background-color: $white;
            overflow: auto;
           // height: $itemHeight - $itemDistance;
        }

        //.projects-touchpoints--item .mcm-template--background
        .mcm-template--background {
            height: $itemHeight - $itemDistance;
        }

    }

    //.projects-touchpoints--sidemenu
    &--sidemenu {
        $sidemenuWidth: 300px;

        position: fixed;
        width: $sidemenuWidth;
        height: 100%;
        background-color: $white;
        right: -$sidemenuWidth;
        z-index: 9999999;
        box-shadow: -1px 0px 1px 0px rgba(0, 0, 0, 0.3);
        overflow: auto;
        @include null(top bottom);

        //.projects-touchpoints--sidemenu div
        div {
            width: 100%;
        }

        //.projects-touchpoints--sidemenu .close
        .close {
            text-align: right;
            font-weight: 300;
            cursor: pointer;
            margin: auto;
        }

        //.projects-touchpoints--sidemenu-title
        &-title {
            display: flex;
            padding: 2em;
            justify-content: space-between;
            border-bottom: 1px solid #ddd;
        }

        //.projects-touchpoints--sidemenu-items
        &-items {
            padding: .5em 2em;
            border-bottom: 1px solid #ddd;
            @include resetAnchor();

            &:not(.no-hover) {
                &:hover {
                    background-color: #ddd;
                }
            }
        }
    }
}