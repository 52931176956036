//.mcm-navigation
.mcm-navigation {
    $navHeight: 76px;
    $navToBodyDistance: 20px;
    $breakpointMenuDisplay: desktop;

    display: flex;
    align-items: center;
    width: 100%;
    background: $white;
    height: $navHeight;
    margin-bottom: $navToBodyDistance;

    border-bottom: 1px solid #AAAAAA;

    //.mcm-navigation .btn
    .btn {
        outline: none !important;
    }

    .navigation-item {
        margin:0px;
        .dropdown-menu .mcm-navigation-links--link {
            text-transform: none;
        }

        .dropdown-menu li {
            padding: 0px;
        }

    }


    //.mcm-navigation-wrapper
    &-wrapper {

        //.mcm-navigation-wrapper.mcm-navigation-wrapper--fixed
        &.mcm-navigation-wrapper--fixed {
            width: 100%;
            position: fixed;
            box-shadow: none;
            z-index: 3;
        }
    }

    //.mcm-navigation-row
    &-row {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        @include mq($from: $breakpointMenuDisplay) {
            justify-content: center;
        }
    }

    //.mcm-navigation-logo
    &-logo {
        // margin-right: 40px;
        // @include null(min-width max-width, 130px);

        //.mcm-navigation-logo img
        img {
            width: 120px;
        }
    }

    //.mcm-navigation-links
    &-links {
        display: none;
        @include mq($from: $breakpointMenuDisplay) {
            display: flex;
        }

        .sublink {
            margin-right:0px !important;
            border-bottom:1px solid #D8D8D8;

            padding: 12px 0px 12px 20px;
            width: 300px;
        }

        .sublink:last-child {
            border-bottom: 0px;
        }

        a.sublink {
            color: #4A4A4A !important;
        }

        a.sublink:first-child {
            //font-weight: 600;
        }

        //.mcm-navigation-links--link
        &--link {
            text-transform: uppercase;
            text-decoration: none !important;
            font-weight: 300;
            letter-spacing: 1px;
            font-size: 13px;
            color: #4A4A4A !important;

            &:not(:last-child) {
                margin-right: 30px;
            }

            &.video-platform-active {
                font-weight: bold;
            }
        }
    }

    &-right {
        text-align: right;
    }

    //.mcm-navigation-mods
    //.mcm-navigation-profile
    &-mods, &-profile, &-links.mobile {
        line-height: 1;
        cursor: pointer;
        position: relative;
        display: inline-block;

        i:before {
            font-size: 35px;
        }

        //.mcm-navigation-mods .dropdown-menu
        .dropdown-menu {
            margin-top: -8px;
            border: 1px solid #ddd;
            @include null(padding border-radius);

            .fa.fa-play::before {
                text-shadow: 2px 0px 1px #ddd;
                font-size: 25px;
            }
        }

        //.mcm-navigation-mods .btn
        .btn {
            padding: 10px;
        }

        //.mcm-navigation-mods .fa-play
        .fa-play {
            position: absolute;
            top: -20px;
            right: 10px;
            transform: rotate(-90deg);
            z-index: -1;

            &:before {
                color: #fff;
            }
        }
    }

    //.mcm-navigation-profile
    &-profile {

        //.mcm-navigation-profile .dropdown-toggle
        .dropdown-toggle {
            display: flex;
            align-items: center;
            justify-content: space-between;

            //.mcm-navigation-profile .dropdown-toggle h4
            h4 {
                margin: 0;
                color: $gray-light;
            }
        }

        //.mcm-navigation-profile--picture
        &--picture {
            border-radius: 50%;
            overflow: hidden;
            @include size(40px);
        }

        //.mcm-navigation-profile--picture
        &--name {
            padding: 0 10px;
        }

        //.mcm-navigation-profile .lnr-chevron-down
        .lnr-chevron-down {
            font-size: 1.5em;
        }
    }

    //.mcm-navigation-dropdown
    &-dropdown {

        //.mcm-navigation-dropdown.line
        &.line {
            display: flex;
            flex-wrap: wrap;
            min-width: 274px;
        }

        //.mcm-navigation-dropdown--link
        &--link {
            text-align: center;
            @include resetAnchor();

            &:hover {
                background: #eee;
            }

            //.mcm-navigation-dropdown.line .mcm-navigation-dropdown--link
            .line & {
                flex: 0 50%;
                padding: 3em 1em;

                &:nth-child(odd) {
                    border-right: 1px solid #BFBFBF;
                }
                &:not(:nth-last-child(-n+2)) {
                    border-bottom: 1px solid #BFBFBF;
                }
                &:nth-child(even) {
                    border-bottom: 1px solid #BFBFBF !important;
                }
                &:last-child {
                    border-bottom: 0 !important;
                }

                //.mcm-navigation-dropdown--link .mcm-icon-wrapper
                .mcm-icon-wrapper {
                    margin: auto auto 10px auto;

                    //.mcm-navigation-dropdown--link .mcm-icon-wrapper.lg
                    &.lg {
                        @include size(40px);
                    }
                }
            }

            //.mcm-navigation-dropdown.column .mcm-navigation-dropdown--link
            .column & {
                padding: 12px 0px 12px 20px;
                text-align: left;

                &:not(:last-child) {
                    border-bottom: 1px solid #BFBFBF;
                }
            }

            //.mcm-navigation-dropdown.column .mcm-navigation-dropdown--link.mobile
            &.mobile {
                display: none;
                @include mq($until: $breakpointMenuDisplay) {
                    display: block;
                }
            }
        }
    }

    //.mcm-navigation-breadcrumb
    &-breadcrumb {
        margin-top: -$navToBodyDistance;
        margin-bottom: ($navToBodyDistance / 2);
        height: $navToBodyDistance;
        align-items: center;
        display: flex;
        box-shadow: inset 0 4px 6px -2px rgba(0, 0, 0, 0.3);

        //.mcm-navigation-breadcrumb--container
        &--container {
            width: 100%;

            //.mcm-navigation-breadcrumb--container a
            a {
                font-weight: 300;
                color: $white;
                text-decoration: none;
            }

            //.mcm-navigation-breadcrumb--container .fa
            .lnr-chevron-right {
                margin: 0 7px;
                color: $white;
            }
        }
    }

    //.mcm-navigation-container
    &-container {
        display: flex;
        align-items: center;

        @include mq($until: $breakpointMenuDisplay) {
            width: 100%;
        }
    }

}



.mcm-navigation-wrapper--no-breadcrumb {
    .mcm-navigation {
        margin-bottom: 0;
    }
}

//this is a body class
.no-breadcrumb {
    .mcm-has-fixed-nav {
        padding-top: 76px;
    }
}
