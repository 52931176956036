.selection-boxes {
    text-align: center;
}
.selection-box {
    text-align: center;
    width: 110px;
    display: inline-block;
    color: white;
    text-decoration: none;
    margin: 0 10px;
    cursor: pointer;
    transition: 0.3s all;
    position: relative;
    .selection-box__checked {
        display:none;
        position: absolute;
        text-align: center;
        top: 75px;
        right: 5px;
        background: rgb(141, 198, 63);
        width: 30px;
        height: 30px;
        border-radius: 100%;
        padding-top: 6px;
        color: white;
        text-shadow: 1px 1px 5px #999;

    }

    &.selection-box--selected {
         .selection-box__checked {
            display: inline-block;
         }
    }

    &:hover, &.selection-box--selected {

        text-decoration: none;
        .selection-box__boxed-part {
            transition: 0.3s all;

            text-decoration: none;
            box-shadow: 1px 1px 8px #666;
            color: white;
            background: $color_primary +(-40);
        }
        .selection-box__title {
            transition: 0.3s all;
            color: $color_primary +(-40);
        }
    }

    &__boxed-part {
        background: $color_secondary;
        width: 110px;
        height: 110px;
        padding: 25px 0 0;
        box-shadow: 1px 1px 8px #999;
    }

    &__icon {
        font-size: 40px;
        margin-bottom: 10px;
        @include flaticonFontSize(40px);
    }

    &__title {
        color: $color_primary;
        margin: 10px 0 0;
    }

    input {
        display:none;
    }
}