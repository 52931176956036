//.tp-details
.tp-details {

  //.tp-details__left
  &__left {
    display: inline-block;
    float: left;
    width: 30%;
    height: 100%;
  }

  //.tp-details__right
  &__right {
    display: inline-block;
    float: left;
    width: 70%;
    height: 100%;
  }

  //.tp-details-top
  &-top {
    height: 60px;
    background: $color_darkgreen;

    //.tp-details-top .tp-details__left
    .tp-details__left {
      border-right: 1px dashed white;
      padding: 20px 0 0 30px;
    }

    //.tp-details-top .tp-details__right
    .tp-details__right {
      padding: 10px 0 0 30px;
    }

    //.tp-details-top__step-name
    &__step-name {
      color: white;
      font-size: 16px;
    }
  }

  //.tp-details-page
  &-page {
    background: white;
    color: #4d5254;
    position: relative;
    overflow: hidden;
    min-height: 1300px;

    //.tp-details-page .tp-details__left
    .tp-details__left {
      border-right: 1px dashed $color_darkgreen;
      position: absolute;
      width: 30%;
      height: 100%;
    }

    //.tp-details-page .tp-details__right
    .tp-details__right {
      width: 70%;
      height: 100%;
      left: 30%;
      position: relative;
    }

    //.tp-details-page .tp-details__left, .tp-details-page .tp-details__right
    .tp-details__left, .tp-details__right {
      padding: 30px;
    }

    //.tp-details-page.tp-details-page--easy-flow
    &.tp-details-page--easy-flow {

      //.tp-details-page.tp-details-page--easy-flow a
      a {
        color: $color_pastelBlue;
      }

      //.tp-details-page.tp-details-page--easy-flow .tp-details-content__table
      .tp-details-content__table {
        font-size: 14px;
      }

      //.tp-details-page.tp-details-page--easy-flow .tp-details__section
      .tp-details__section {
        margin: 30px 0;

        //.tp-details-page.tp-details-page--easy-flow .tp-details__section .tp-details-content__title
        .tp-details-content__title {
          font-size: 18px;
          margin-bottom: 5px;
          @include fontMain();
        }

        //.tp-details-page.tp-details-page--easy-flow .tp-details__section .tp-details-content__detail
        .tp-details-content__detail {
          font-size: 16px;
        }
      }

      //.tp-details-page.tp-details-page--easy-flow .tp-details__preview-list
      .tp-details__preview-list {
        list-style: none;
        padding: 0;

        //.tp-details-page.tp-details__preview-list a
        a {
          cursor: pointer;
        }
      }

    }
  }

  //.tp-details-general
  &-general {

    //.tp-details-general__project-type
    &__project-type {
      font-size: 16px;
    }

    //.tp-details-general__type
    &__type {
      font-size: 21px;
      margin-bottom: 30px;
      @include fontMainSemiBold;
    }

    //.tp-details-general__table
    &__table {
      width: 100%;

      //.tp-details-general__table tr
      tr {

        //.tp-details-general__table td
        td {
          width: 69%;
          text-align: left;

          &:first-child {
            width: 30%;
            font-weight: bold;
          }
        }
      }
    }

    //.tp-details-general__edit-link-container
    &__edit-link-container {
      margin-bottom: 20px;

      //.tp-details-general__edit-link-container a
      a {
        color: $color_blue;
      }
    }

    //.tp-details-general__actions
    &__actions {

      //.tp-details-general__actions .btn
      .btn {
        background: $color_pastelBlue;
        color: white;

        &:hover {
          background: $color_pastelBlue + (-40);
        }

        //.tp-details-general__actions .btn.btn-raised
        &.btn-raised {
          width: 100%;
          padding: 15px 0;
          margin-bottom: 15px;
        }
      }

      //.tp-details-general__actions .justify-content
      .justify-content {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;

        //.tp-details-general__actions .justify-content a
        a {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      //.tp-details-general__action-delete
      &__action-delete {
        color: $color_red;
      }
    }

    //.tp-details-general__action-delete
    &__action-delete {
      color: $color_red;

      &:hover {
        color: $color_red +(-40);
      }
    }
  }

  //.tp-details__purchase-section
  &__purchase-section {
    margin: 20px 0 40px;

    //.tp-details__purchase-section .purchase-page__status
    .purchase-page__status {
      font-size: 18px;
    }
  }

  //.tp-details__absolute
  &__absolute {
    position: absolute;
    @include null(right left top);
  }

  //.tp-details-content
  &-content {
    margin-bottom: 25px;

    //.tp-details-content.tp-details-content__settings
    &.tp-details-content__settings {

      //.tp-details-content.tp-details-content__settings .form-control,
      //.tp-details-content.tp-details-content__settings .control-label
      .form-control, .control-label {
        margin-top: 3px;
      }
    }

    //.tp-details-content__title
    &__title {
      font-size: 24px;
      margin-top: 3px;
      color: $color_textgrey;
      @include fontMainSemiBold;
    }

    //.tp-details-content__detail
    &__detail {
      margin-top: 5px;

      //.tp-details-content__detail .control-label
      .control-label {
        color: $color_textgrey;
      }

      //.tp-details-content__detail textarea
      textarea {
        background: #e5e5e5;
      }

      //.tp-details-content__detail .trumbowyg-box
      .trumbowyg-box {
        background: white;
        margin: 10px 0 0;
        width: 100%;
      }
    }

    //.tp-details-content__table
    &__table {
      width: 500px;
      margin-bottom: 15px;

      //.tp-details-content__table tr
      tr {

        //.tp-details-content__table tr td
        td {
          width: 69%;
          text-align: left;
          vertical-align: top;

          &:first-child {
            padding-left: 0;
            width: 30%;
          }
        }
      }
    }

    //.tp-details-content__link
    &__link {
      color: $color_blue;
    }

    //.tp-details-content__detail-section
    &__detail-section {
      margin: 20px 0;
    }

    //.tp-details-content .add-touchpoint-form-container__services
    .add-touchpoint-form-container__services {

      //.tp-details-content .add-touchpoint-form-container__services .control-label
      //.tp-details-content .add-touchpoint-form-container__services .form-group
      .control-label, .form-group {
        margin-top: 3px;
      }

      //.tp-details-content .add-touchpoint-form-container__services .checkbox
      .checkbox {
        margin-top: 2px;
        margin-bottom: 2px;
      }
    }

    //.tp-details-content__explanation
    &__explanation {
      margin: 1.5em 0 1em;
    }

    //.tp-details-content__tp-packages
    &__tp-packages {

      //.tp-details-content__tp-packages ul
      ul {
        list-style: none;
        padding-left: 10px;
        margin: 0;
      }

      //.tp-details-content__tp-packages label
      label {
        color: #666;
        cursor: pointer;
      }
    }
  }

  //.tp-details__keyvalue-list
  &__keyvalue-list {
    color: $color_textgrey;
    list-style: none;
    @include null(padding margin);

    //.tp-details__keyvalue-list td
    td {
      vertical-align: top;
      padding: 5px 10px 5px 0;
    }

    //.tp-details__keyvalue-list tr > td:first-child
    tr > td:first-child {
      @include fontMainSemiBold;
    }

    //.tp-details__keyvalue-list ul
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }

    //.tp-details__keyvalue-list li
    li {
      display: inline;

      &:after {
        content: ',';
        margin-right: 3px;
      }

      &:last-child:after {
        content: "\00a0";
      }
    }
  }

  //.tp-details__dashed-line
  &__dashed-line {
    height: 1px;
    margin: 30px 0;
    border-top: 1px dashed $color_textgrey;
  }

  //.tp-details__approval-icon
  &__approval-icon {
    float: left;
    color: white;
    text-align: center;
    background: $color_darkgreen;
    font-size: 20px;
    border-radius: 100%;
    padding-top: 6px;
    @include size(40px);
    @include flaticonFontSize(20px);
  }

  //.tp-details__speaker-list
  &__speaker-list {
    list-style: none;
    padding: 0;
    margin: 15px 0;

    //.tp-details__speaker-list li
    li {
      float: left;
      width: 45%;
      margin-right: 4%;
      margin-bottom: 40px;
    }

    //.tp-details__speaker-list a
    a {
      cursor: pointer;
    }
  }



  //.tp-details__overview
  &__overview {

    &--left {
      width: 50%;
    }

    .form-group.is-focused .form-control {
        background-image: none !important;
    }

    .form-control, .form-group .form-control {
        height: auto;
        border: 1px solid #D8D8D8;
        border-radius: 6px !important;
        padding: 10px;
        background: #ffffff;
        box-shadow: none;
        font-style: normal;
        font-size: 15px;
        color: #616161;
    }

    .form-group input:disabled {
      background: none;
      cursor: auto;
    }

    .form-group {
      margin: 5px 0 0 0 ;
    }

    .form-group label.control-label {
        color: #4A4A4A;
        font-style: normal;
    }

    .btn-blue {
        height: 27px;   
        width: 99px;    
        border-radius: 5px; 
        background-color: #36A9E3;
        padding: 11px 31px;
        color: #ffffff;
        font-style: normal;
        height: 43px;
        width: 110px;
        display: inline-block;
    }

    .btn-blue:hover {
        color:#464646 !important;
    }

    .btn-blue[disabled] {
        color: rgba(0, 0, 0, 0.56);
        background: #e6e6e6;
    }

    .btn-white {
        height: 23px;   
        width: 95px;    
        border: 2px solid #36A9E3;
        border-radius: 5px; 
        background-color: #ffffff;
        padding: 11px 31px;
        color: #36A9E3;
        font-style: normal;
        height: 43px;
        width: 110px;
        display: inline-block;
        margin-left: 10px;
    }

    .btn-white:hover {
        color:#ffffff !important;
        background-color: #36A9E3 !important;
    }

    //.tp-details__overview-dates
    &-dates {

      //.tp-details__overview-dates .tp-details__overview-date
      .tp-details__overview-date {
        margin: 5px 0;

        //.tp-details__overview-dates .tp-details__overview-date span
        span {
          margin-left: 10px;
        }
      }
    }

    //.tp-details__overview-preview
    &-preview {
      margin: 30px 0;
      a {
        word-break: break-all;
      }
    }

    //.tp-details__overview-actions
    &-actions {
      position: absolute;
      padding: 0 3em;
      bottom: 3em;
      @include null(right left margin);

      //.tp-details__overview-actions .btn
      .btn {
        text-transform: none;
        width: 100%;
        @include null(padding-top padding-bottom, 15px);
      }
    }

    //.tp-details__overview-title
    &-title {
      font-size: 22px;
      margin: 10px 0 20px;
      @include fontMainSemiBold;
    }
  }


    &__settings {

    &--left {
      width: 90%;
    }

    .padding-1 {
        padding: 0px 20px 0px 0px;
    }

    .radio label span {
      left: 5px;
    }

    .radio label {
      padding-left: 35px;
    }

    .checkbox label, .radio label, label {
      margin: 0 0px;
    }

    .radio label .circle {
      border-radius: 100%;
      box-sizing: border-box;
      height: 17px;
      width: 17px;
      border: 1px solid #979797;
      background-color: #FFFFFF;
    }

    .radio label .check {
      height: 17px;
      width: 17px;
    }

    .form-group .checkbox label, .form-group .radio label, .form-group label {
      color: #4D5254;
    }

    .radio input[type=radio]:checked ~ .circle {
      border: 1px solid #36A9E3;
    }

    .form-group textarea {
      height: 180px !important;
      overflow: hidden;
    }

    .create-touchpoint__setting {
      background: #ffffff;
    }

    .radio input[type=radio]:checked ~ .check {
      background-color: #36A9E3 !important;
    }

    .form-group.is-focused .form-control {
        background-image: none !important;
    }

    .form-control, .form-group .form-control {
        height: auto;
        border: 1px solid #D8D8D8;
        border-radius: 6px !important;
        padding: 10px;
        background: #ffffff;
        box-shadow: none;
        font-style: normal;
        font-size: 15px;
        color: #616161;
    }

    .form-group input:disabled {
      background: none;
      cursor: auto;
    }

    .form-group {
      margin: 5px 0 0 0 ;
    }

    .form-group label.control-label {
        color: #4A4A4A;
        font-style: normal;
    }

    .btn-blue {
        height: 27px;   
        width: 99px;    
        border-radius: 5px; 
        background-color: #36A9E3;
        padding: 11px 31px;
        color: #ffffff;
        font-style: normal;
        height: 43px;
        width: 110px;
        display: inline-block;
    }

    .btn-blue:hover {
        color:#464646 !important;
    }

    .btn-blue[disabled] {
        color: rgba(0, 0, 0, 0.56);
        background: #e6e6e6;
    }

    .btn-white {
        height: 23px;   
        width: 95px;    
        border: 2px solid #36A9E3;
        border-radius: 5px; 
        background-color: #ffffff;
        padding: 11px 31px;
        color: #36A9E3;
        font-style: normal;
        height: 43px;
        width: 110px;
        display: inline-block;
        margin-left: 10px;
    }

    .btn-white:hover {
        color:#ffffff !important;
        background-color: #36A9E3 !important;
    }
  }

  //.tp-details__serv
  &__serv {

    //.tp-details__serv-packs
    &-packs {
      margin: 30px 0;
    }

    //.tp-details__serv-package-service
    &-service {
      margin: 0 0 30px;
    }
  }

  //.tp-details__section
  &__section {

    //.tp-details__section .touchpoint-program
    .touchpoint-program {
      margin: 20px 0;

      //.tp-details__section .touchpoint-program *
      * {
        font-size: 13px !important;
      }
    }

    .blue-check {
      color: #75bee9;
    }

  }

  //.tp-details__comments
  &__comments {
    float: right;
    position: relative;

    //.tp-details__comments-button
    &-button {
      color: white;
      font-size: 18px;
      padding: 15px 20px 20px;
      cursor: pointer;
      top: 1px;
      position: relative;

      //.tp-details__comments-button-text
      &-text {
        display: none;
        margin-right: 10px;
      }
    }

    //.tp-details__comments-detail
    &-detail {
      display: none;
      position: absolute;
      background: $white;
      width: 400px;
      border: 1px solid #ccc;
      padding: 10px 40px 50px;
      box-shadow: -2px 2px 4px -1px #ccc;
      top: 30px;
      z-index: 10;
      @include null(border-top border-right right);
    }

    &:hover {
      .tp-details__comments-button {
        color: $color_green;
        background: white;
      }
      .tp-details__comments-detail {
        display: block;
      }
      .tp-details__comments-button-text {
        display: inline-block;
      }
    }

    //.tp-details__comments .purchase-comments
    .purchase-comments {
      list-style: none;
      padding: 0;

      //.tp-details__comments .purchase-comments .purchase-page__comment-form-container
      .purchase-page__comment-form-container {
        margin: 0;
      }
    }

    //.tp-details__comments .purchase-comment
    .purchase-comment {
      font-size: 14px;
      margin: 10px 0 0;
      color: $color_textgrey;

      //.tp-details__comments .purchase-comment.purchase-comment--has-parent
      &.purchase-comment--has-parent {
        padding-left: 20px;
      }

      //.tp-details__comments .purchase-comment__title
      .purchase-comment__title {
        margin-bottom: 5px;

        //.tp-details__comments .purchase-comment__title .purchase-comment__author
        .purchase-comment__author {
          @include fontMainBold;
        }
      }

      //.tp-details__comments .purchase-comment.current-replied-comment
      &.current-replied-comment {
        background: lightyellow;
        padding: 10px;
        transition: 0.3s all;
      }

      //.tp-details__comments .purchase-comment .purchase-comment__message
      .purchase-comment__message {

        //.tp-details__comments .purchase-comment .purchase-comment__message p
        p:last-child {
          margin-bottom: 2px;
        }
      }

      //.tp-details__comments .purchase-comment .purchase-comment__reply-link
      .purchase-comment__reply-link {
        color: $color_blue;
      }
    }

    //.purchase-page__comment
    .purchase-page__comment {

      //.tp-details__comments .purchase-page__comment-title
      &-title {
        font-size: 24px;
        margin-bottom: 5px;
        @include fontMainSemiBold;
      }

      //.purchase-page__comment-form-container
      &-form-container {
        margin: 40px 0 0;

        //.purchase-page__comment-form-container label
        label {
          padding-left: 3px;
          top: -33px;
        }
      }

      //.purchase-page__comment-textarea
      &-textarea {
        background-color: #ccc;
        padding: 3px;
      }
    }
  }
}
