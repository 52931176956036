
.pending-requests-tabs {
  color: #4A4A4A;

  ul {
    color: #4A4A4A;
  }

  a {
    color: #4A4A4A;
  }
}

.spinner {
  .bounce1 {
    background: $sanofi-purple;
  }

  .bounce2 {
    background: $sanofi-purple;
  }

  .bounce3 {
    background: $sanofi-purple;
  }
}

.requests-wrapper {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 850px;
  height: 600px;
}

.search-container {

  -webkit-box-shadow: 0px 5px 10px 0px rgba(74, 74, 74, 0.3);
  -moz-box-shadow: 0px 5px 10px 0px rgba(74, 74, 74, 0.3);
  box-shadow: 0px 5px 10px 0px rgba(74, 74, 74, 0.3);

  display: flex;
  justify-content: space-between;

  input {
    width: 100%;
    border: none;
  }

  input[disabled] {
    opacity: 0.6;
    background: $white;
  }

  i {
    font-size: 30px;
    color: $sanofi-purple;
  }

  i[disabled] {
    opacity: 0.6;
  }

  background: $white;
  color: #4a4a4a;
  font-size: 15px;
  padding: 10px;
  margin: 0 10px 20px 9px; //top-right-bottom-left
}

.details-form {
  background: #fff;
  padding: 5px;
  min-height: 50vh;
}

.requests-container {

  -webkit-box-shadow: 0px 3px 3px 0px rgba(74, 74, 74, 0.15);
  -moz-box-shadow: 0px 3px 3px 0px rgba(74, 74, 74, 0.15);
  box-shadow: 0px 3px 3px 0px rgba(74, 74, 74, 0.15);

  background: $white;
  display: flex;
  justify-content: space-between;
  //padding: .1em 1em;
  padding: 4px 5px 4px 10px; //top-right-bottom-left
  vertical-align: top;
  font-size: 15px;
  align-items: center;
  margin: 10px;
  cursor: pointer;

  &.active {
    color: #CCC;
    background: $sanofi-purple;
  }

  .names-container {
    margin: 10px 10px 10px 10px; //top-right-bottom-left
    white-space: nowrap;

    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    text-align: left;

    .date {
      font-size: 12px;
      color: #CCC;

      &.active {
        background: $sanofi-purple;
      }
    }

    span {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 150px; // some width

      &.active {
        color: $white;
        background: $sanofi-purple;
      }
    }
  }

  .links {
    text-decoration: none;
    display: flex;
    flex-direction: row;
    flex: 0 0 150px;
    max-width: 150px;
    justify-content: flex-end;
    a {
      border-bottom: none;
      text-decoration: none !important;
      font-size: 15px;
      padding: 5px;
    }
  }

  i {
    background: $white;
    color: #666;
    font-size: 20px;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 10px;
  }

  .deny {
    margin-right: 10px;

    a {
      font-size: 12px;
      color: #CCC;

      &.active {
        background: $sanofi-purple;
      }
    }

    a:hover {
      color: #CCC;
      text-decoration: underline !important;
    }
  }

  .accept {
    &.bold{
      font-weight: 700;
    }
    a {
      font-size: 12px;
      color: $sanofi-purple;

      &.active {
        color: $white;
        background: $sanofi-purple;
      }
    }

    a:hover {
      color: $white;
      text-decoration: underline !important;
    }
  }

  .accreditator a {
    font-size: 12px;
    color: #CCC;
    white-space: nowrap;
    margin-right: 10px;

    &:hover {
        text-decoration: underline !important;
    }
  }
}

.requests-container:hover {

  color: $white;
  box-shadow: none;
  background: $sanofi-purple;

  .deny {
    a {
      font-size: 12px;
      color: #CCC;
    }

    a:hover {
      color: #CCC;
      text-decoration: underline !important;
    }
  }

  .accept {
    a {
      font-size: 12px;
      color: #FFF;
    }

    a:hover {
      color: #FFF;
      text-decoration: underline !important;
    }
  }
}

.form-style-6 {
  margin: auto;
  padding: 16px;
  background: #FFFFFF;
  text-align: center;

  .target-list {
    text-align: center;
    border: 1px dashed #CCC;
    max-width: 80%;
    margin: 58px auto 0;
    padding: 10px;

    i {
      font-size: 50px;
      color: $sanofi-purple;
    }

    .open-target-list {
      background: $sanofi-purple;
      color: white;
      padding: 10px;
      display: inline-block;
      text-decoration: none;
      margin-top: 10px;
    }
  }
}

.form-style-6 input[type="text"],
.form-style-6 input[type="email"],
.form-style-6 textarea,
.form-style-6 select {
  max-width: 400px;
  -webkit-transition: all 0.30s ease-in-out;
  -moz-transition: all 0.30s ease-in-out;
  -ms-transition: all 0.30s ease-in-out;
  -o-transition: all 0.30s ease-in-out;
  outline: none;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  width: 100%;
  background: #fff;
  margin: 0 auto 20px;
  border: 1px solid #ccc;
  padding: 15px;
  color: #555;
  font: 95% Arial, Helvetica, sans-serif;
  text-align: center;
}

.form-style-6 input[type="text"]:focus,
.form-style-6 input[type="email"]:focus,
.form-style-6 textarea:focus,
.form-style-6 select:focus {
  box-shadow: 0 0 5px;
  padding: 15px;
  border: 1px solid $sanofi-purple;
}

.form-style-6 input[disabled] {
  opacity: 0.6;
}

.form-style-6 label[disabled] {
  opacity: 0.6;
}

.form-style-6 input[type="submit"] {
  margin-top: 10px;
}

.form-style-6 input[type="submit"],
.form-style-6 input[type="button"] {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  max-width: 400px;
  width: 100%;
  padding: 15px;
  background: $sanofi-purple;
  border-bottom: 2px solid $sanofi-purple;
  border-top-style: none;
  border-right-style: none;
  border-left-style: none;
  color: #fff;
}

.form-style-6 input[type="submit"]:hover,
.form-style-6 input[type="button"]:hover {
  background: $sanofi-purple;
}

.form-style-6 input[type="submit"]:disabled {
  opacity: 0.6;
}
.form-style-6 input.has-error{
  border-color: red;
}
.form-style-6 label {
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-style-6 select {
  text-align: center;
  text-align-last: center;
  display: flex;
  align-items: center;
  justify-content: center;
  text-indent: 1.2em;
}

.form-style-6 select:disabled {
  opacity: 0.6;
}

.form-style-6 option {
  text-align: left;
  /* reset to left*/
}

.dropbox {
  margin-top: 100px;
  outline: 1px dashed grey; /* the dash box */
  background: $white;
  min-height: 200px; /* minimum height */
  position: relative;
  cursor: pointer;

  i {
    color: $sanofi-purple;
    font-size: 48px;
  }
}

.input-file {
  opacity: 0; /* invisible but it's there! */
  width: 100%;
  height: 200px;
  position: absolute;
  cursor: pointer;
}

.dropbox:hover {
  background: $sanofi-purple; /* when mouse over to the drop zone, change color */
  color: $white;

  i {
    color: $white;
  }
}

.dropbox p {
  text-align: center;
  padding: 50px 0;
}

.video-platform-target-list {
  .gr-container {
    background: transparent;
    box-shadow: none;

    .small-form-container {
      min-height: 500px;
      max-width: 900px;
      margin: 0 auto;
      padding: 20px 50px 50px;

      .small-form-header {
        span {
          display: block;
          padding-bottom: 30px;
        }
        ul {
          li {
            padding-bottom: 10px;
          }
        }

        a {
          color: #000000;
          text-decoration: underline;
        }
      }

      .form-section {
        background: transparent;
        padding: 0;

        .dropbox {
          margin-top: 0;
        }
      }
    }
  }
}

.modal-container {
  &.fixed-position{
    position: fixed;
    z-index: 1;
  }
  .icon-delete, .icon-error {
    font-size: 6em;
    color: #D0021B;
  }

  .close {
    opacity: 1;
  }

  .modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .3s ease;
  }

  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
  }

  .modal-content {
    font-size: 20px;
    width: 600px;
    margin: 0px auto;
    padding: 20px 24px;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
    transition: all .3s ease;
    animation-duration: 0.4s;
    animation-name: modalAnimation;

    .modal-header{
      &.p-0{
        padding: 0;
      }
    }
    .modal-body {
      margin: 20px 0;
      padding: 0;
      text-align: center;
      .deleted{
        position: relative;
      }

    }

  }



  @keyframes modalAnimation {
    0% {
      transform: translateY(-100%);
      opacity: 0;
    }
    10%{
      transform: translateY(-60%);
      opacity: 0.8;
    }
    50%{
      transform: translateY(-20%);
      opacity: 1;
    }
    100% {
      transform: translateY(0);
    }
  }
}
