.c-registration-field {
    box-shadow: 0px 2px 5px 0px rgba(74,74,74,0.3);
    background: darken($white, 5%);
    padding: 1rem;
    margin: 2rem 0;

    &__name {
        color: lighten($color_textgrey, 25%);
    }

    &__buttons {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 1rem;
        margin-left: auto;
        margin-bottom: 1rem;

        button {
            background: none;
            border: 2px solid $color_buttonBlue;
            border-radius: 100%;
            width: 3rem;
            height: 3rem;
            font-size: 1.5rem;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: color 0.25s, background-color 0.25s;

            &:hover {
                background-color: $color_buttonBlue;
                color: $white;
            }
        }

        &--danger {
            color: $color_red;
        }
    }
}
