.appointment-requests{
    &__list{
        list-style: none;
        padding: 0 !important ;
        margin-top:2em;
        display:block;
        overflow:auto;
        clear:both;
    }
    &__item{
        margin:1em 0;
    }
    &__content{
        display:flex;
    }
    &__title{
        flex:.3;
        padding:1em;
        background-color: $color_blue;
        color:$white;
        display:flex;
        flex-direction:column;
        align-items:center;
        justify-content:center;
        div{
            margin:auto;
        }
        h4{
            margin:0;
            &:first-child{
                font-weight:300;
            }
            &:last-child{
                margin-top:5px;
                font-weight:400;
            }
        }
    }
    &__description{
        flex:1;
        padding:1em;
        background:$white;
        overflow:auto;
        &.active{
            height:auto;
        }
    }
    &__description,&__title{
        height:120px;
    }
}