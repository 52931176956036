.stepped-form-numbers {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    .stepped-form-numbers__step {
        background: #d8d8d8;
        border-radius: 100%;
        text-align: center;
        font-size: 15px;
        color: white;
        width: 29px;
        height: 29px;
        display: flex;
        justify-content: center;
        align-items: center;
        user-select: none;
        &.stepped-form-numbers__step--selected {
            background: #4a4a4a;
        }
    }
    .stepped-form-numbers__line {
        border-top: 2px dashed #4A4A4A;
        width: 4em;
    }
}