// Adboard groups styles
//-------------------------------------------------------------

//.adboard-groups
.adboard-groups {
    margin-bottom: 50px;
    padding-top: 100px;
    //.adboard-groups__controls
    &__controls {
        background: #ffffff;
        min-height: 600px;
        padding: 10px 40px;
        @extend .shadow-for-box;
    }

    //.adboard-groups__boards-title
    &__boards-title {
        font-size: 20px;
    }

    //.adboard-groups__board-name
    &__board-name {
        font-size: 20px;
        color: $color_overview;
        margin-left: 40px;

        b {
            font-weight: 700;
        }
    }

    //.adboard-groups__boards-add-new-link
    &__boards-add-new-link {
        float: right;
        font-size: 12px;
        text-decoration: underline;
        cursor: pointer;
    }

    //.adboard-groups__boards-list
    &__boards-list {
        list-style: none;
        padding: 0;
    }

    //.adboard-groups__boards-list-item
    &__boards-list-item {
        &:first-child > a {
            border-top: 1px solid #ccc;
        }

        //.adboard-groups__boards-list-item .adboard-groups__boards-list-title
        .adboard-groups__boards-list-title {
            width: 100%;
            padding: 15px 0;
            font-size: 16px;
            color: black;
            display: block;
            cursor: pointer;
            transition: 0.3s all;
            border-bottom: 1px solid #ccc;
            &:hover {
                background: $color_pastelBlue;
                color: white;
                padding-left: 10px;
                padding-right: 10px;
            }
            i {
                float: right;
                margin-top: 3px;
            }
        }

        //.adboard-groups__boards-list-item ul
        ul {
            list-style: none;
            padding: 20px 5px;
            li {
                margin: 5px 0;
                font-size: 14px;
            }
        }

        //.adboard-groups__boards-list-item.group-is-opened
        &.group-is-opened {
            border-bottom: 1px solid #ccc;

            //.adboard-groups__boards-list-item.adboard-groups__boards-list-title
            .adboard-groups__boards-list-title {
                background: $color_pastelBlue;
                color: white;
                padding-left: 10px;
                padding-right: 10px;
                text-decoration: none;
            }
        }
    }

    .adboard-groups__view-all-link {
        font-size: 14px;
        position: relative;
        top: 8px;
        cursor: pointer;
    }

    &__item-list {
        font-size: 20px;
        color: $color_overview;
        width: 100%;
        list-style: circle;
    }

    &__board-item, &__board-item--version, &__board-item--comment, &__board-item--reply {
        line-height: 1.5;
        padding: 1em 1em 2em 2em;
        position: relative;
        color: $color_overview;
        border-left: 1px solid $color_overview;

        &::before {
            content: '';
            position: absolute;
            left: 2em;
            top: 1em;
            display: block;
            font-family: 'Roboto', sans-serif;
            font-weight: 700;
            font-size: .785rem;
        }

        &::after {
            width: 16px;
            height: 16px;
            display: block;
            top: 1.3em;
            position: absolute;
            left: -8px;
            border-radius: 10px;
            content: '';
            border: 1px solid $color_overview;
            background: white;
        }


        .text-item {
            color: $color_overview_item;
        }

        div.time, time {
            display: inline;
            font-size: 14px !important;
        }

        .expand {
            float: right;
            font-size: 40px;
            color: $color_overview;
            margin-top: -14px;
        }

        &--version {
            font-size: 14px;
            padding-left: 3em;
            padding-right: 1.6em;
            padding-bottom: 6em;
        }

        &--comment {
            font-size: 12px;
            padding-left: 3.6em;
            padding-right: 1.6em;
        }

        &--reply {
            font-size: 12px;
            padding-left: 3.6em;
            padding-right: 1.6em;
        }

        .version-block {
            float: left;
        }

        .message {
            font-size: 18px;
        }
    }

    &__board-item--comment:first-child {
        margin-top: 10px;
    }

    &__board-item {
        border-left: 0;
    }

    &__board-item--version::after {
        width: 12px;
        height: 12px;
        left: -7px;
    }

    &__board-item::after {
        border: 1px solid $color_overview_item;
    }

    &__board-item--version::after {
        border: 1px solid $color_pastelBlue;
    }

    &__board-item--comment::after, &__board-item--reply::after {
        width: 10px;
        height: 10px;
        left: -5px;
    }
}

.version-box {
    top: -49px;
    position: relative;
}

.version-box {
    .adboard-groups__board-item--version--last {
        padding-bottom: 6px;
    }

    .adboard-groups__board-item--version:first-child {
        padding-top: 4em;
        &:after {
            top: 4.4em;
        }
    }

}

.adboard-groups-content-actions {
    padding-top: 18px;
    a {
        cursor: pointer;
        font-size: 14px;
        margin-left: 10px;
    }
}

.mcm-box-container.board-selected {
    color: white !important;
    transition: 0.4s all;
}

//.adb-member
.adb-member {
    background: #ffffff;
    height: 120px;
    margin-bottom: 30px;
    @extend .shadow-for-box;

    //.adb-member__content
    &__content {
        padding: 0 25px;
    }

    //.adb-member.v-centered
    .v-centered {
        height: 100%;
    }
}

//.add-new-group-container__member-select
.add-new-group-container__member-select {

    //.add-new-group-container__member-select .checkbox
    .checkbox {
        margin-top: 0px;
    }

    //.add-new-group-container__member-select label
    label {
        color: $color_text;
    }

    //.add-new-group-container__member-select .add-new-group-container__member-select-box
    .add-new-group-container__member-select-box {
        padding-right: 10px;
    }
}