// Define welcome page scss
//-------------------------------------------------------------

//.welcome-page
.welcome-page {
    $bannerHeight: 262px;
    $logoHeightDesktop: 88px;
    $logoHeight: $logoHeightDesktop / 1.5;

    @function calcBottomPos($logoHeight) {
        @return -($logoHeight / 2);
    }

    @function logoWidth($logoHeight) {
        @return $logoHeight * 4;
    }

    @function logoMaxWidth($logoHeight) {
        @return logoWidth($logoHeight) / 1.2;
    }

    width: 100vw;
    height: 100vh;
    overflow-x: hidden;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% ($bannerHeight / 2);
    @include hideSiteContentPush();

    @include mq($from: mobile) {
        background-position-y: $bannerHeight / 1.5;
    }

    @include mq($from: tablet) {
        background-position-y: $bannerHeight;
    }

    //.welcome-page--banner
    &--banner {
        width: 100%;
        height: $bannerHeight / 2;
        padding: .5em;
        position: relative;
        background: linear-gradient(270deg, $republic-m-blue 0%, $republic-m-green 100%);
        background: #666;
        @include flexCenter();

        @include mq($from: mobile) {
            height: $bannerHeight / 1.5;
        }

        @include mq($from: tablet) {
            height: $bannerHeight;
        }

        @include mq($from: desktop) {
            display: block;
            padding-top: 70px;
            text-align: center;
            .welcome-page--banner-logo {
                display: inline-block;
                position: relative;
                bottom:-85px;
                img {
                    height: 100%;
                }
               // margin-left: -176px;
            }
        }

        //.welcome-page--banner h1
        h1 {
            margin: 0;
            color: $white;

            @include mq($from: desktop) {
                font-size: 4em;
            }

            //.welcome-page--banner h1 .text-uppercase
            .text-uppercase {
                font-size: .5em;
            }
        }

        //.welcome-page--banner-logo
        &-logo {
            position: absolute;
            padding: .8em 1.6em;
            border-radius: 86px;
            background-color: $white;
            box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.2);
            bottom: calcBottomPos($logoHeight * 1.5);
            @include flexCenter;
            @include size(logoWidth($logoHeight), $logoHeight);

            @include mq($from: mobile) {
                bottom: calcBottomPos($logoHeight);
            }

            @include mq($from: desktop) {
                bottom: calcBottomPos($logoHeightDesktop);
                @include size(logoWidth($logoHeightDesktop), $logoHeightDesktop);
            }

            //.welcome-page--banner-logo img
            img {
                max-width: logoMaxWidth($logoHeight);

                @include mq($from: desktop) {
                    max-width: logoMaxWidth($logoHeightDesktop);
                }
            }
        }
    }
}