.dashboard {
  .client-info-cards {
    margin: 10px 0;
  }
  .area-info-cards {
    margin: 10px 0;
  }
}

.page-title-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 20px;

  .page-title {
    font-size: 21px;
    position: relative;
    display: inline-block;
    margin: auto 0;
    @include fontMainSemiBold;
  }

  .page-title__actions {
    display: flex;
    align-items: center;
    table {
      position: relative;
      top: 10px;
      tr {
        td {
          vertical-align: middle;
        }
      }
    }
    &:nth-child(2) {
      margin-left: 10px;
    }
    .page-title__actions-text {
      font-size: 14px;
      margin-right: 5px;
    }

  }
  .page-title__action-button-container {
    align-items: flex-end;
    height: 55px;
  }
  .page-title__action-button {
    margin-left: 40px;
    a {
      cursor: pointer;
    }
  }
}
