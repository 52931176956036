.add-page-form-container{

    .form-section{
        padding-left: 10%;
        padding-right: 10%;
        .form-section__title {
            padding: 0;
        }
    }
}


.pages-listing {
    .gr-container {
        padding: 30px 5%;
    }
}




.select2-container--default .select2-selection--multiple .select2-selection__choice {
    color: white !important;
}