//----------------------
// GENERIC MCM CLASSES
//----------------------

//.mcm
.mcm {

    //.mcm-content-area
    &-content-area {
        padding: 30px 40px;

        //.mcm-content-area.mcm-content-area--sp
        &.mcm-content-area--sp {
            padding: 1em 6%;
        }

        //.mcm-content-area.sm
        &.sm {
            padding: 30px 20px;
        }
    }

    //.mcm-container-with-action-menu
    &-container-with-action-menu {
        padding-top: 50px;
    }

    //.mcm-container-with-left-menu
    &-container-with-left-menu {
        @include mq($from: tablet, $until: desktop) {
            padding-left: 60px;
            padding-right: 5%;
        }
        @include mq($from: desktop) {
            padding-left: 400px;
            padding-right: 5%;
        }

    }

    //.mcm-has-fixed-nav
    &-has-fixed-nav {
        position: relative;
        padding-top: 138px;
    }

    //.mcm-title
    &-title {
        @include fontTitle;
        font-size: 21px;
        color: $color_textgrey;
        margin: 10px 0 10px;
    }

    //.mcm-title-secondary
    &-title-secondary {
        @extend .mcm-title;
        font-size: 18px;
    }

    //.mcm-text
    &-text {
        @include fontMain;
        font-size: 16px;
        color: $color_textgrey;
    }

    //.mcm-separator-line
    &-separator-line {
        background: #BFBFBF;
        height: 1px;
        width: 100%;
    }

    //.mcm-div-table
    &-div-table {
        font-size: 16px;
        padding: 0;
        list-style: none;
        li {
            padding: 18px 8px;
            border-bottom: 1px solid #333;
            &:first-child {
                border-top: 1px solid #333;
            }
        }
    }

    //.table.mcm-table
    &-table {
        border-spacing: 0;
        thead {
            border-top: 1px solid #bfbfbf;
            > tr > th {
                border-bottom: 0;
                @include fontMainSemiBold;
            }
        }
        > tbody, > thead, > tfoot {
            > tr {
                > td, > th {
                    border-top: 1px solid #bfbfbf;
                    padding: 15px 8px;
                    vertical-align: middle;
                }
            }
        }
    }

    //.mcm-standart-list
    &-standart-list {
        list-style: none;
        @include null(padding margin);
        li {
            margin: 0.5em 0;
        }
    }

    //--------------------------------------
    // Sidebar content area contanier for admin
    // Generally used for 1-3 ratio pages
    //---------------------------------------

    //.mcm-sidebar
    &-sidebar {
        background: white;
        box-shadow: 1px 5px 7px 1px #ccc;

        //-------------------------------------------
        // When it is with a minimum height of 500px

        //.mcm-sidebar--min-h
        &--min-h {
            min-height: 500px;
        }

        //.mcm-sidebar .mcm-title
        .mcm-title {
            margin-top: 0;
        }

        //.mcm-sidebar-back-btn
        &-back-btn {
            display: block;
            cursor: pointer;
            text-align: center;
            font-size: 18px;
            line-height: 21px;
            padding: 20px;
            text-decoration: none !important;
            @include fontMainSemiBold;

            //.mcm-sidebar-back-btn.highlight
            &.highlight {
                text-decoration: none;
                padding: .6em 1em;
            }

            //.mcm-sidebar-back-btn i
            i {
                margin-right: 10px;
            }

            //.mcm-sidebar-back-btn span.highlight
            span.highlight {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: .6em 1em;
                border-radius: 3px;

                //.mcm-sidebar-back-btn span.highlight.active
                &.active {
                    color: $white;
                }
            }
        }
    }

    //--------------------------------------
    // Content area contanier for admin pages
    // Generally used for 1-3 ratio pages
    // as bigger part
    //---------------------------------------

    //.mcm-container
    &-container {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        @include mq($from: desktop) {
            flex-wrap: nowrap;
        }
    }

    //.mcm-content-section
    &-content-section {
        width: 100%;
        @include mq($from: desktop) {
            width: 720px;
        }

        //.mcm-content-section--has-bg
        &--has-bg {
            background: white;
            @extend .shadow-for-box;
        }
    }

    //.mcm-sidebar-flex
    &-sidebar-flex {
        display: none;
        width: 350px;
        @include mq($from: desktop) {
            display: block;
            margin-right: 30px;
        }
        &.active {
            display: block;
            position: absolute;
            padding: 0 24px;
            z-index: 99;
            @include null(top right bottom);
            .mcm-sidebar {
                box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.4);
            }
        }
    }

    //.mcm-image-container
    &-image-container {
        position: relative;
        overflow: hidden;
        flex: 0 0 45px;
        @include size(45px);

        //.mcm-image-container img
        img {
            border-radius: 100%;
        }

        //.mcm-image-container.lg
        &.lg {
            flex: 0 0 70px;
            @include size(70px);
        }

        //.mcm-image-container.sm
        &.sm {
            flex: 0 0 20px;
            @include size(20px);
        }

        //.mcm-image-container.icon
        &.icon {
            overflow: inherit;

            //.mcm-image-container.icon .mcm-icon-wrapper
            .mcm-icon-wrapper {
                position: absolute;
                top: 0;
                right: -10px;
                margin: auto;
            }
        }
    }

    //.mcm-icon-wrapper
    &-icon-wrapper {
        border-radius: 50%;
        padding: (17px/2);
        margin-right: 10px;
        @include size(28px);
        @include flexCenter();
        span {
            color: #fff;
            display: inline;
            line-height: 1;
        }
        &.lg {
            @include size(35px);
            span:before {
                font-size: 20px;
            }
        }
        &.sm {
            @include size(20px);
            span:before {
                font-size: 14px;
                line-height: 0;
            }
        }
    }

    //.mcm-overview-row
    &-overview-row {
        width: 100%;
        border-bottom: 1px solid #bfbfbf;
        padding: 8px;
        cursor: pointer;
        @include resetAnchor();

        //.mcm-overview-row--wrapper
        &--wrapper {
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            padding: 6px;
            @include mq($from: tablet) {
                width: 98%;
                margin: auto;
                flex-direction: row;
            }

            &:not(.no-hover):hover {
                @include box();
            }

            &.no-hover {
                cursor: default;
            }
        }

        //.mcm-overview-row--item
        &--item {
            margin-bottom: 5px;
            padding: .3em;
            word-break: break-word;
            &:last-child {
                margin-bottom: 0;
            }
            @include mq($from: tablet) {
                margin-bottom: 0;
                flex: 1;
                display: flex;
                align-items: center;
            }
        }
    }

    //.mcm-overview__search
    &__search {
        position: relative;

        //.mcm-overview__search .form-group
        .form-group {

            //.mcm-overview__search .form-group label.control-label
            label.control-label {
                color: $color_text;
            }

            //.mcm-overview__search .form-group.is-focused
            &.is-focused {

                //.mcm-overview__search .form-group.is-focused button,
                //.mcm-overview__search .form-group.is-focused label.control-label
                button, label.control-label {
                    color: #337ab7;
                }
            }
        }

        //.mcm-overview__search .form-control
        .form-control {
            margin: 0;
        }

        //.mcm-overview__search button
        button {
            background: transparent;
            border: 0;
            outline: none;
            @include abCenter(right);
        }
    }

    //.mcm-checkbox-label
    &-checkbox-label {
        font-size: 14px;
        color: #4D5254;
        margin: 0 10px;
    }

    //.mcm-profile
    &-profile {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        //.mcm-profile .mcm-image-container
        .mcm-image-container {
            margin-right: 10px;
            @include mq($from: mobile) {
                margin-right: 25px;
            }
        }

        //.mcm-profile-info
        &-info {

            //.mcm-profile-info--title
            &--title {
                margin: auto;

                &:first-child {
                    font-weight: 500;
                    font-size: 1.15em;
                }
            }
        }
    }

    //.mcm-box-container
    &-box-container {
        padding: 16px;
        margin-bottom: 8px;
        display: block;
        cursor: pointer;
        position: relative;
        color: inherit !important;
        text-decoration: none !important;
        @include box();

        //.mcm-box-container.lg
        &.lg {
            padding: 20px 16px;
        }

        //.mcm-box-container.sm
        &.sm {
            padding: 8px 16px;
        }

        //.mcm-box-container.no-hover
        &.no-hover {
            cursor: default;
        }
    }

    //.mcm-btn-square
    &-btn-square {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        border-radius: 0;
        width: 169.25px;
        height: 135px;
        color: #fff;
        box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.25);
        b {
            font-size: 1.5em;
            margin-bottom: 5px;
        }
        p {
            width: 60%;
            margin: 0 auto;
            font-weight: 300;
            font-size: .9em;
        }
    }

    //.mcm-overlay
    &-overlay {
        position: fixed;
        background: rgba(0, 0, 0, 0.6);
        z-index: 98;
        @include null(top right bottom left);

        //.mcm-overlay--background
        &.background {
            position: absolute;
            background: rgba(0, 0, 0, 0.4);
        }

    }

    //.mcm-loader
    &-loader {
        margin: 20px auto;
        border-radius: 50%;
        border: 6px solid #ddd;
        animation: loaderAnimation 2.5s infinite ease-in-out;
        @include size(50px);
        @keyframes loaderAnimation {
            0% {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(540deg);
            }
        }
    }

    //.mcm-template
    &-template {

        //.mcm-template--background
        &--background {
            background-repeat: no-repeat;
            background-size: cover;
            background-position: 50% 50%;

            @mixin changeBannerHeight($height) {
                height: $height + px;
                @include mq($from: desktop) {
                    height: $height + 50 + px;
                }
            }

            @include changeBannerHeight(250);
            &.sm {
                @include changeBannerHeight(150);
            }
        }
    }

    //.mcm-invisible
    &-invisible {
        visibility: hidden;
        @include size(0);
    }

    //.mcm-btn--fab
    &-btn--fab {

        //.mcm-btn--fab.sm
        &.sm {

            //.mcm-btn--fab.sm .btn-group-sm .btn.btn-fab
            .btn-group-sm .btn.btn-fab {
                padding: 0;
                @include null(height min-width width, 30px);
            }

            //.mcm-btn--fab.sm .material-icons
            .material-icons {
                font-size: 16px;
            }
        }
    }

    //.mcm-truncate
    &-truncate {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 80px;
    }

    //.mcm-player
    &-player {

        //.mcm-player.loading
        &.loading {

            //.mcm-player.loading iframe
            iframe {
                @include hideVisability();
            }
        }

        .mcm-player__controls {
            text-align: center;
            padding: 10px 0;
            background: white;
            position: relative;
            top: -68px;
        }

        &.mcm-player--in-exam {
            .mcm-player__controls {

                top: -7px;
            }
        }

        //.mcm-player iframe
        iframe {
            width: 100%;
            height: 300px;

            @include mq($from: tablet) {
                height: 400px;
            }

            @include mq($from: desktop) {
                height: 600px;
            }
        }
    }

    //.mcm-title-area
    &-title-area {

        margin: 40px 0;
        //.mcm-title-area__title
        &__title {
            font-size: 25px;
            line-height: 1.5;
            margin: 0 0 15px;

            //.mcm-title-area__title--overview
            &--overview {
                font-weight: 500;
                font-size: 25px;
                color: $color_overview;
            }
        }

        //.mcm-title-area__detail
        &__detail {
            font-size: 15px;
        }

        //.mcm-title-area__search
        &__search {
            margin-top: 20px;

            //.mcm-title-area__search--overview
            &--overview {
                float: right;
                padding-right: 20px;
                font-size: 35px;
                font-weight: 400;
                color: #C1C1C1;
            }

            .form-group {
                margin: 0;
            }

            i {
                font-size: 30px;
                color: #9a9a9a;
            }
        }

    }
    //mcm-has-notification
    &-has-notification {
        position: relative;
    }

    //mcm-notification-label
    &-notification-label {
        position: absolute;
        top: -15px;
        right: -30%;
        border-radius: 100%;
        height: 27px;
        width: 27px;
        background: white;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
        color: #9a9a9a;
        font-size: 15px;
        text-align: center;
        padding-top: 3px;
    }

    //.mcm-has-popover-card
    &-has-popover-card {
        position: relative;
        display: inline;
        padding-bottom: 10px;
        cursor: pointer;
        &:hover {
            .mcm-popover-card {
                display: block;
            }

            .mcm-has-popover-card__text {
                // font-weight: bold;
            }
        }
    }

    //mcm-popover-card
    &-popover-card {
        //display: none;
        background-color: $white;
        border: 1px solid $color_lightgrey;
        color: $black;
        z-index: 11;
        width: 300px;
        min-height: 150px;
        position: absolute;
        top: 30px;
        left: -100%;
        text-align: center;
        border-radius: 10px;
        padding: 30px 0 0;
        box-shadow: -1px -1px 10px -2px rgba(0, 0, 0, 0.5);
        a {
            margin-right: 0 !important;
        }

        .mCSB_inside > .mCSB_container {
            margin-right: 0;
        }

        .mcm-popover-card__nav {
            list-style: none;
            padding: 0;
            margin-top: 1em;
            max-height: 300px;
            li {
                &:first-child {
                    a {

                        border-top: 1px solid #CECECE;;
                    }
                }
                &:last-child {
                    a {

                        border-bottom: 0px;
                    }
                }
                a {
                    padding: 28px 0;
                    display: block;
                    text-decoration: none;
                    border-bottom: 1px solid #CECECE;;
                    &:hover {
                        background-color: #C1C1C1;
                        color: white;
                    }
                }
            }

            &.mcm-popover-card__nav--notifications{
                font-size: 14px;
                li {
                    a {
                        padding: 10px 20px;
                        font-size: 14px;
                    }
                }
            }
        }
    }

}

.mcm-container-with-left-menu--wider {
    padding-left: 116px;
}

.mcm_user_avatar {
    border: 1px solid #e1e1e1;
    border-radius: 100%;
}

.on {
    display: block;
}

.off {
    display: none;
}