//Speakers view page

.speaker-details {

  //.speaker-details .mcm
  .mcm {

    //.speaker-details .mcm-box-item
    &-box-item {
      &:first-child {
        margin-bottom: 5px;
      }
      &:last-child {
        font-size: .9em;
      }
    }

    //.mcm-overview-row
    &-overview-row {
      margin-bottom: 10px;
      cursor: default;
      @include null(border padding);

      //.mcm-overview-row--wrapper
      &--wrapper {
        width: auto;
        padding: 0;
      }

      //.mcm-overview-row--item
      &--item:first-child {
        flex: .5;
      }
    }

    //.mcm-profile
    &-profile {
      .mcm-image-container {
        @include mq($until: mobile) {
          margin-bottom: 10px;
        }
      }
      .btn {
        @include mq($from: mobile) {
          margin: auto 0 auto auto;
        }
      }
    }

  }

}