//.tp-edit
.tp-edit {
    margin: 30px 0;

    //.tp-edit__overview
    &__overview {
        width: 100%;
        float: left;
        margin-right: 4%;
        transition: 0.5s all;
        position: relative;
        min-height: 800px;
        background: #eeeeee;
        padding: 40px;
        // @extend .shadow-for-box;

        &.expandpanel-transition {
            overflow: auto;
        }

        .tp-details-content__detail {

            .tp-details__selected-template {
                .mcm-box-container {
                    background: #ffffff;
                    box-shadow: none;
                }
            }

            .tp-details-content__mail-table {
                border-spacing: 8px;
                border-collapse: separate;

                .tp-details-content__mail-table-tr {
                    border: 1px solid #464646;
                    box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.25);
                    border-radius: 5px;
                    border-color: #000000;
                }
            }

        }
    }

    //.tp-edit__switch-tab
    &__project-name {

        text-align: center;
        h3 {
            font-weight: 700;
            margin-top: 10px;
            margin-bottom: 20px;
        }
    }

    //.tp-edit__switch-tab
    &__back {
        display: block;
        position: absolute;
        z-index: 10;
    }
    //.tp-edit__switch-tab
    &__switch-tab {
        display: inline-block;
        width: 200px;
        margin: 0 -1px;

        @media (max-width: 1600px) {
            width: 160px;
        }

        @media (max-width: 1300px) {
            width: 130px;
        }
    }

    //.tp-edit__controls
    &__controls {
        width: 100%;
        float: left;
    }

    //.tp-edit--overview
    &--overview {

        //.tp-edit--overview .tp-edit__overview
        .tp-edit__overview {
            width: 100%;
            margin-right: 4%;
            transition: 0.3s all;
            // @extend .shadow-for-box;
        }

        //.tp-edit--overview .tp-edit__controls
        .tp-edit__controls {
            width: 100%;
            transition: 0.3s all;
        }
    }

    //.tp-edit--detail
    &--detail {

        //.tp-edit--detail .tp-edit__hide-on-detail
        .tp-edit__hide-on-detail {
            display: none;
        }

        //.tp-edit--detail .tp-edit__switches
        .tp-edit__switches {

            margin-bottom: -7px;

            //.tp-edit--detail .tp-edit__switches .tp-edit__switch
            .tp-edit__switch {
                height: 45px;
                margin-bottom: 4px;
                background: #E5E5E5;
                color: #4A4A4A;
            }

            //.tp-edit--detail .tp-edit__switches .tp-edit__switch-label
            .tp-edit__switch-label {
                width: 100%;
                background: #E5E5E5;
                color: #4A4A4A;
            }

            .tp-edit__switch-label:hover {
                background: #EEEEEE;
                color: #4A4A4A;
                font-weight: bold;
            }

            //.tp-edit--detail .tp-edit__switches .tp-edit__switch-label-title
            .tp-edit__switch-label-title {
                text-align: center;
                padding: 0 20px;
                display: flex;
                justify-content: center;
                align-items: center;

                //.tp-edit--detail .tp-edit__switches .tp-edit__switch-label-title i
                i {
                    display: inline-block;
                    font-size: 1.1em;
                    text-align: left;
                    margin-right: 10px;
                }

                //.tp-edit--detail .tp-edit__switches .tp-edit__switch-label-title .tp-edit__switch-label-icon
                .tp-edit__switch-label-icon {
                    margin-right: 10px;
                }

                //.tp-edit--detail .tp-edit__switches .tp-edit__switch-label-title .tp-edit__switch-label-text
                .tp-edit__switch-label-text {
                    font-size: 13px;
                }
            }

            //.tp-edit--detail .tp-edit__switches .tp-edit__switch-label-content > i
            .tp-edit__switch-label-content > i {
                display: none;
            }

            //.tp-edit--detail .tp-edit__switches .row
            .row {

                //.tp-edit--detail .tp-edit__switches .row .col-sm-6
                .col-sm-6 {
                    &:nth-child(odd) {
                        padding-right: 2px;
                    }
                    &:nth-child(even) {
                        padding-left: 2px;
                    }
                }
            }
        }
    }

    //.tp-edit__switch
    &__switch {
        height: 150px;
        margin-bottom: 30px;
        cursor: pointer;
        // @extend .shadow-for-box;

        //.tp-edit__switch.tp-edit__switch--selected
        &.tp-edit__switch--selected {
            background: #EEEEEE;

            //.tp-edit__switch.tp-edit__switch--selected .tp-edit__switch-label
            .tp-edit__switch-label {
                background: #EEEEEE;
                color: #4A4A4A;
                font-weight: bold;
                transition: 0.3s background;
            }
        }

        //.tp-edit__switch .tp-edit__switch-label
        &-label {
            width: 150px;
            height: 100%;
            float: left;
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
            transition: 0.3s background;
            background: #E5E5E5;
            color: white;
            font-size: 18px;
            text-decoration: none;
            align-items: center;
            text-align: center;

            @media (max-width: 1500px) {
                width: 100px;
            }

            //.tp-edit__switch .tp-edit__switch-label i
            i {
                font-size: 32px;
                flex: 1 100%;
            }

            //.tp-edit__switch .tp-edit__switch-label .tp-edit__switch-label-content
            &-content {
                text-align: center;
                flex: 1 100%;
            }

            //.tp-edit__switch .tp-edit__switch-label .tp-edit__switch-label-title
            .tp-edit__switch-label-title {
                flex: 1 100%;
            }
        }

        //.tp-edit__switch .tp-edit__switch-content
        &-content {
            width: calc(100% - 150px);
            background: white;
            height: 100%;
            float: left;
            padding: 0 10px 0 25px;
            overflow: hidden;
        }
    }

    //.tp-edit__back-btn
    &__back-btn {
        height: 46px;
        display: inline-block;
        width: auto;
        cursor: pointer;
        margin-bottom: 20px;
        color: white;
        padding: 11px 45px 0;
        text-align: center;
        font-size: 16px;
        position: relative;
        color: #4A4A4A;

        //.tp-edit__back-btn i
        i {
            position: absolute;
            left: 0px;
            top: 3px;
            font-size: 21px;
            border: 1px solid;
            padding: 7px 10px;
            border-radius: 5px;
            border-color: #D8D8D8;
        }
    }

    //.tp-edit__switch-label-title i
    &__switch-label-title i {
        display: none;
    }

}

/**
    EMAIL CAMPAIGNS
 */
.email-campaign-details-table {
    border: 1px solid #ddd;
    padding: 15px;
    table {
        margin-bottom: 0;
        td {
            padding:10px 0;
        }
    }
    .table > tbody > tr:first-child > td {
        border-top: 0;
    }
}

.email-campaign-details__title {
    color: #4A4A4A;
    font-size: 15px;
    font-weight: 700;
}

.email-campaign-details__results {
    display:flex;
    justify-content: space-around;
}

.email-campaign-details-box{
    margin: 10px 10px;
    border: 1px solid #C9C6C6;
    border-radius: 5px;
    text-align: center;
    flex:1;
    height: 105px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 5px 0;
    &:first-child {
        margin-left:0;
    }
    &:last-child {
        margin-right:0;
    }
    .email-campaign-details-box__number {
        font-size: 24px;
        font-weight: 700;
    }
}