.touchpoint-types-list {
    margin: 20px 0;

    ul {
        list-style: none;
        padding: 0;
    }

    &__tptype {
        background-color: #f5f5f5;
        padding: 30px 20px;
        margin: 10px 0;
    }

    &__settings {

    }

    &__settingstitle {
        border-bottom: 1px solid #999;
        padding-bottom: 5px;
        @include fontMainSemiBold;
        .btn {
            margin: 0;
        }
    }

    &__setting-item {
        background: $color_pastelBlue;
        //background: #457fca; /* fallback for old browsers */
        //background: -webkit-linear-gradient(to left, $color_pastelBlue+(40) , $color_pastelBlue); /* Chrome 10-25, Safari 5.1-6 */
        //background: linear-gradient(to left, $color_pastelBlue+(40) , $color_pastelBlue); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
        padding: 10px 20px;
        border: 2px solid white;
        border-bottom: 0;
        &:last-child {
            border-bottom: 1px;

        }
        color: white;
    }

    &__setting-item-image {
        margin: 0 20px 0 0;
        img {
            border: 1px solid #ccc;
            padding: 5px;
        }
    }

    &__setting-item-details {
        padding: 0 20px 0;
        width: 600px;
    }

    &__packages {
        margin: 20px 0;
        h4 {
            margin-bottom: 10px;
            .btn {
                margin: 0;
            }
        }
        ul {
            li {
                margin: 5px 10px;
                a {
                    color: #f5f5f5;
                    font-size: 16px;
                    text-decoration: none;
                    transition: 0.3s all;
                    &:hover {
                        color: #333;

                    }
                    i {
                        font-size: 14px;
                        margin: 0 5px;
                        color: $color_textgrey;
                    }
                    &.delete-btn {
                        i {
                            color: #d9534f;
                        }
                        &:hover {
                            i {

                                color: #d9534f + 20;
                            }

                        }
                    }
                }

            }
        }
    }

    &__package-price {
        margin: 0 10px;
    }
}