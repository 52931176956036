//Speakers approved page
.speakers-overview {

  @include mq($from: mobile, $until: tablet) {
    .mcm-overview-row {
      flex: 0 0 50%;
    }
  }

  @include mq($until: tablet) {
    display: flex;
    flex-wrap: wrap;
    .mcm-overview-row {
      border-right: 1px solid #bfbfbf;
    }
  }

  //.speakers-overview .mcm-overview-row--wrapper
  @include mq($from: desktop) {
    .mcm-overview-row--wrapper {
      &:not(.no-hover):hover {
        .flaticon-eye {
          display: block !important;
        }
      }
    }
  }

  //.speakers-overview .mcm-overview-row--item
  .mcm-overview-row--item {
    &:first-child {
      flex: .2;
    }
    &:nth-child(2) {
      flex: .5;
    }
    &:last-child {
      flex: .3;
    }
  }

  //.speakers-overview-content
  &-content {
    .mcm-overview-row--wrapper {
      .mcm-overview-row--item {
        &:first-child {
          flex: .5;
        }
      }
    }
  }
}

//Approved speakers options on click
#list-speakers-options {
  border-bottom: 0;
  margin-bottom: 20px;
  @include mq($until: tablet) {
    .mcm-overview-row--wrapper {
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      padding: 0 1em !important;
    }
    .mcm-overview-row--item {
      margin-bottom: 0;
    }
  }
  .btn {
    margin-right: 10px;
  }
}

//Speakers bureau overview page index
.speakers-bureau-overview {
  .mcm-sidebar {
    .mcm-profile {
      margin: 10px 0;
      .mcm-image-container {
        margin-right: 18px;
        flex: 0 0 45px;
      }
      .mcm-profile-info {
        font-size: .85em;
      }
    }
    .btn-fab {
      padding: 0;
      @include null(width height min-width, 35px);
      i {
        font-size: 18px;
      }
    }
    .btn-group-sm {
      margin: 15px 0;
    }
    .mcm-content-area {
      padding: 30px 20px;
    }
    .stack {
      margin: 0 auto;
      position: relative;
      z-index: 1000;
      height: 160px;
      padding: 0;
      @include null(pointer-events list-style, none);
      &__item {
        opacity: 0;
        overflow: hidden;
        position: absolute;
        display: flex;
        flex-direction: column;
        pointer-events: auto;
        @include size(100%);
        @include null(user-select -webkit-touch-callout, none);
        &--reject, &--accept {
          pointer-events: none;
        }
      }
      &--yuda {
        .stack__item--reject {
          animation: yudaReject 0.5s forwards;
        }
        .stack__item--accept {
          animation: yudaAccept 0.5s forwards;
          transform-origin: 50% 300%;
        }
      }
    }

    .controls {
      margin: 20px 0 0;
      text-align: center;
    }

    @keyframes yudaReject {
      to {
        opacity: 0;
        transform: translate3d(0, 200px, 0);
      }
    }
    @keyframes yudaAccept {
      to {
        opacity: 0;
        transform: rotate3d(0, 0, 1, 20deg);
      }
    }
  }
}