//
// Custom additions to bootstrap
//
.container.container--no-gutter{
	padding-right: 0;
    padding-left: 0;
}
.container.container--with-margin{
    margin-top: 50px;
    margin-bottom: 50px;
}

.row.row--no-gutter {
  margin-right: 0;
  margin-left: 0;

   > [class^="col-"],
   > [class*=" col-"] {
    padding-right: 0;
    padding-left: 0;
  }
}

.btn:disabled{
    background-color: #cccccc !important;
}

.checkbox .checkbox-material .check {
    border: 1px solid #d8d8d8;
}

.table {
    // Remove side paddings from simple bootstrap tables
    &:not(.table-bordered):not(.table-striped) {
        th:first-child,
        td:first-child {
            padding-left: 0;
        }

        th:last-child,
        td:last-child {
            padding-right: 0;
        }
    }

    // Center all cell contents vertically.
    &-center {
        td,
        th {
            vertical-align: middle !important;
        }
    }
}

.form-horizontal {
    display: flex;

    > * {
        height: 100%;
        margin: 0;
        margin-right: 10px;
    }

    .form-control {
        min-width: 80px;
    }
}
