// A section of forms where no gutter setting is used like in client add form
//---------------------------------------------------------------------------

.form-section {
    background: white;
    padding: 35px 0;
    transition: 0.2s all;

    .form-section__title {
        padding: 0 40px;
        font-size: 24px;
        line-height: 32px;
        margin: 0 0 10px;
        color: $color_textgrey;

        &.form-section__title--white {
            color: white;
        }
    }

    .select2-container {
        min-width: 90%;
    }

    .form-section__content {
        padding: 0 40px;

        .form-group label.control-label {
            font-size: 14px;
        }

        .radio-label {
            position: relative;
            top: 3px;
            display: inline-block;
            left: -10px;
        }
    }

    .form-section__help-text {
        font-size: 14px;
        color: white;
        line-height: 1.2;
        margin: 15px 0;
        @include fontMainLight;
    }

    .form-section__subtitle {
        font-size: 14px;
        color: #7b7c80;

        &.form-section__subtitle--white {
            color: white;
        }
    }

    .form-group label {
        color: $color_textgrey;
    }

    &.form-section--darkgreen {
        background: $color_secondary + (-40);
        background-image: linear-gradient(-180deg, $color_secondary + (-40) 54%, $color_secondary + (-40) 78%, $color_secondary + (-40) 100%);

        .form-group label.control-label {
            color: #f5f7ff;
        }

        .form-group .help-block {
            color: #f5f7ff;
        }

        .url-preview-container {
            background: #40b69e;
            color: white;
        }

        .form-control,
        .form-group .form-control {
            border: 0;
            background-image: linear-gradient(white, white), linear-gradient(white, white);
            color: white;

            &.textarea {
                background: #40b69e;
                background-image: linear-gradient(#40b69e, #40b69e), linear-gradient(#40b69e, #40b69e);
                margin-top: 10px;
            }
        }

        .radio label {
            color: white;
        }

        .radio input[type=radio]:checked ~ .check {
            background: white;
        }

        .radio input[type=radio] ~ .circle {
            border-color: white;
        }

        //Selector
        span.select2-selection.select2-selection--multiple {
            background: transparent;
            border: 0;
            background-size: 0 2px, 100% 1px;
            background-repeat: no-repeat;
            background-position: center bottom, center calc(100% - 1px);
            background-color: transparent;
            background-image: linear-gradient(white, white), linear-gradient(white, white);
            color: white;
        }

        .select2-container--default .select2-selection--multiple .select2-selection__choice {
            //background: #75bee9;
            color: white;
            font-size: 12px;
            line-height: 20px;
        }

        .select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
            color: white;
        }
    }

    &.form-section--peach {
        background: #F15B40;

        &.form-section--peach-lighter {
            background: #F47C66;
        }

        .checkbox input[type=checkbox]:checked + .checkbox-material .check {
            color: #f5f7ff;
            border-color: #f5f7ff;

            &:before {
                color: #f5f7ff;
            }
        }

        .form-group label.control-label {
            color: #f5f7ff;
        }

        .form-group label {
        }

        .form-group .help-block {
            color: #f5f7ff;
        }

        .form-control,
        .form-group .form-control {
            border: 0;
            background-image: linear-gradient(white, white), linear-gradient(white, white);
            color: white;
        }
    }

    &.form-section--lightgrey {
        background: $color_lightgrey;
        //Selector
        span.select2-selection.select2-selection--multiple {
            background: transparent;
            border: 0;
            background-size: 0 2px, 100% 1px;
            background-repeat: no-repeat;
            background-position: center bottom, center calc(100% - 1px);
            background-color: transparent;
            background-image: linear-gradient($color_textgrey, $color_textgrey), linear-gradient($color_textgrey, $color_textgrey);
            color: white;
        }

        .select2-container--default .select2-selection--multiple .select2-selection__choice {
            background: #75bee9;
            color: white;
            font-size: 12px;
            line-height: 20px;
        }

        .select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
            color: white;
        }

        .form-section__help-text,
        .add-touchpoint-form-container__add-more {
            color: $color_textgrey;
        }
    }

    &.form-section--blue {
        background: #5fa7e5;

        .form-group label.control-label {
            color: #f5f7ff;
        }

        .form-group .help-block {
            color: #f5f7ff;
        }

        .form-control,
        .form-group .form-control {
            border: 0;
            background-image: linear-gradient(white, white), linear-gradient(white, white);
            color: white;
        }
    }

    &.form-section--darkblue {
        background: #589bd4;
        background-image: linear-gradient(-180deg, #518FC4 54%, #5391C7 78%, #5494CB 100%);

        .form-group label.control-label {
            color: #f5f7ff;
        }

        .form-group .help-block {
            color: #f5f7ff;
        }

        .form-control,
        .form-group .form-control {
            border: 0;
            background-image: linear-gradient(white, white), linear-gradient(white, white);
            color: white;
        }
    }

    &.form-section--green {
        background: #707784;

        .form-group label.control-label {
            color: #f5f7ff;
        }

        .form-group .help-block {
            color: #f5f7ff;
        }

        .form-control,
        .form-group .form-control {
            border: 0;
            background-image: linear-gradient(white, white), linear-gradient(white, white);
            color: white;
        }
        //Selector
        span.select2-selection.select2-selection--multiple {
            background: transparent;
            border: 0;
            background-size: 0 2px, 100% 1px;
            background-repeat: no-repeat;
            background-position: center bottom, center calc(100% - 1px);
            background-color: transparent;
            background-image: linear-gradient(white, white), linear-gradient(white, white);
            color: white;
        }

        .select2-container--default .select2-selection--multiple .select2-selection__choice {
            //background: ;
            color: white;
            font-size: 12px;
            line-height: 20px;
        }

        .select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
            color: white;
        }

        .radio label {
            color: white;
        }

        .radio input[type=radio]:checked ~ .check {
            background: white;
        }

        .radio input[type=radio] ~ .circle {
            border-color: white;
        }
    }

    &.form-section--grey {
        background: #51555a;

        .form-control,
        .form-group .form-control {
            border: 0;
            background-image: linear-gradient(white, white), linear-gradient(white, white);
            color: white;
        }
    }

    .content-editor-container {
        .trumbowyg-box {
            display: block;
            background: rgba(255, 255, 255, 0.8);
            width: 100%;
            min-height: 250px;
            margin: 17px 0;
        }
    }
}

//input with no margin
.form-group--no-margin {
    margin-top: 0;
}

.form-group--half {
    width: 46%;
    float: left;
    margin-right: 3%
}

.form-on-white-bg {
    label.control-label {
        color: $color_text;
    }
}

.lighter-peach-bg {
    background: #F47C66;
}

.form-group__inline-radios {
    .radio {
        display: inline-block;
        margin-right: 10px;
    }
}

.is-searching-now {
    opacity: 0.3;
    transition: 0.3s all;
}

.form-group__inline-radios {
    .radio-label {
        position: relative;
        top: 3px;
        display: inline-block;
        left: -10px;
    }
}

.form-group {
    .form-control {
        @include changeFormControlColors($login-box-gray);
    }
    label {
        color: $login-box-gray;
        &.control-label {
            color: $login-box-gray;
        }
    }

    .selectric {
        border-bottom: 1px solid $login-box-gray;
        .button:after {
            color: $login-box-gray;
        }
    }
}
