// Adboard items styles
//-------------------------------------------------------------

//.adboard-items
.adboard-items {
    margin-bottom: 50px;

    //.adboard-items__controls
    &__controls {
        background: #ffffff;
        min-height: 600px;
        padding: 10px 40px;
        @extend .shadow-for-box;
    }

    //.adboard-items__card
    &__card {
        margin-bottom: 30px;
        display: block;
        box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.25);;
        @include resetAnchor(#fff);

        //.adboard-items__card-title-container
        &-title-container {
            background-color: rgba(255,255,255,0.26);
        }
        //.adboard-items__card-title
        &-title {
            margin: 0;
            line-height: 1.3em;
        }

        //.adboard-items__card-text
        &-text {
            font-size: .85em;
            margin-bottom: 8px;
            &.img {
                margin-top: 25px;
                img {
                    margin-right: 5px;
                }
            }
            &.status {
                i {
                    margin-left: 5px;
                    &:before {
                        font-size: 8px;
                    }
                }
            }
        }
    }

}

//.adboard-item__controls-activity
.adboard-item__controls-activity {
    list-style: none;
    padding: 0;
    li {
        margin: 5px 0;
        &:before {
            content: ' ';
            width: 10px;
            height: 10px;
            display: inline-block;
            background: $color_secondary;
            border-radius: 100%;
            margin-right: 10px;
            position: relative;
            top: -2px;
        }

        //.adboard-item__controls-activity .timeronact
        .timeronact {
            margin-left: 20px;
            font-size: 11px;
        }
    }

}

//.new-item-summary
.new-item-summary {

    //.new-item-summary__file
    &__file {
        font-weight: bold;
        margin: 15px 0;
    }

    //.new-item-summary__group
    &__group {
        margin: 10px 0;
    }
}

//.adboard-overview-content
.adboard-overview-content {

    //.adboard-overview-content__container
    &__container {
        padding: 0;
        @include mq($from: tablet) {
            &:nth-child(odd) {
                padding-left: 0;
                padding-right: 8px;
            }
            &:nth-child(even) {
                padding-right: 0;
                padding-left: 8px;
            }
        }
    }
}