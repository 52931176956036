.service-types-list {
    list-style: none;
    margin: 0px 0 20px;
    ul {
        margin: 0;
        padding: 0;
    }

    &__title {
        font-weight: bold;
        font-size: 20px;
        margin: 0 0 10px;
    }
}

.service-cards-list {
    list-style: none;
    ul {
        margin: 0;
        padding: 0;
    }
}

.service-card {
    border: 1px solid #dddddd;
    background: #f5f5f5;
    padding: 5px 10px 15px;
    margin: 10px 0;

    &.service-card--active {
        background: $color_blue;
        color: white;
        border-color: $color_blue + 50;
    }

    &__title {
        font-weight: bold;
        font-size: 16px;
    }

    &__price {
        color: $color_textgrey;
    }

    &__desc {
        color: $color_textgrey;
    }
}

.service-types-page__form {
    position: absolute;
    background: #e5e5e5;
    min-width: 400px;
    &.service-types-page__form--fixed {
        position: fixed;
        top: 100px;
    }


    label,.form-group label.control-label {
        color:#333;
    }
}