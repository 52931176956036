.file-drop-area {
    border: 1px dashed #c1c1c1;
    text-align: center;
    color: #c1c1c1;
    min-height: 100px;
    font-size: 20px;
    line-height: 100px;
    cursor: pointer;
    background: url('/img/buttons/uploade-image.png') no-repeat center center;
    border-radius: 6px;
    &.dragover {
        background: rgba(255, 255, 255, 0.4);
        border-color: green;
    }

}

.new-member-form:not(:last-child) {
    padding-bottom: 20px;
    border-bottom: 2px solid #d0d2d0;
}

.new-member-form .new-member-form--input {
    border: 2px solid #C1C1C1;
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    font-size: 20px;
    color: #9B9B9B;
}

.add-adboard-form__title {
    color: $color_textLight;
    font-size: 25px;
    @include fontMainLight;
}

.add-adboard-form__description {
    color: $color_textLight;
    font-size: 15px;
    text-align: justify;
    margin: 10px 0;
}

.create-board {
    border-radius: 50px !important;
}

.or-text {
    font-size: 25px;
    color: #ADAFB1;
}

.file-button {
    color: #6FA23A;
}

.adboard-background-actions {
    padding: 10px 0;
    a {
        margin-right: 10px;
        &.remove-background {
            color: red;
        }
    }
}