.adboard-card {
    $cardsDistance: 3%;
    $colorCardText : #4D5254;
    //------------------------
    // Calcuates flex basis for amount of cards
    @function flexBasisForCards($noOfCards) {
        @return round((100% - ($cardsDistance * ($noOfCards - 1)))/$noOfCards);
    }

    background-repeat: no-repeat;
    background-size: cover;

    flex: 0 0 100%;
    border: 0;
    border-radius: 8px;
    background-color: #FFFFFF;
    margin: 0 $cardsDistance $cardsDistance 0;
    min-height: 220px;
    color: $colorCardText;
    @include shadowForBox;

    @include mq($from: tablet) {
        flex-basis: flexBasisForCards(2);

        &:nth-child(2n) {
            margin-right: 0;
        }
    }
    @include mq($from: 1600px) {
        flex-basis: flexBasisForCards(3);

        &:nth-child(2n) {
            margin-right: $cardsDistance;
        }
        &:nth-child(3n) {
            margin-right: 0;
        }
    }


    //.adboard-card__title
    &__title {
        color: $colorCardText;
        font-size: 15px;
        line-height: 1.3em;
        margin: 0;
        background-color: rgba(255,255,255,0.7);
        padding: 7px 20px;
        height: 60px;
        width: 100%;
        @include fontMainSemiBold;
    }
    //.adboard-card__date
    &__date {
        color: #9A9A9A;
        font-size: 15px;
        position: absolute;
        bottom: 15px;
    }

    //.adboard-card__content
    &__content {
        height: 80%;
        padding: 17px 0px 0;
        position: relative;
        cursor: pointer;
        display: flex;
        align-items: flex-end;

    }

    //.adboard-card__actions
    &__actions {
        height: 20%;
        background-color: rgba(255,255,255,0.7);
        padding: 12px 20px;
        color: $colorCardText;
        border-radius: 0 0 7px 7px;
        i.fa {
            font-size: 20px;
            cursor: pointer;

            &:first-child {
                margin-right: 10px;
            }
        }
    }

    &__member-count {
        color: #4D5254;
    }

    &.adboard-card--add-new {

        text-align: center;
        padding-top: 65px;
        cursor: pointer;
        .adboard-card__add-button {
            width: 70px;
            height: 70px;
            border-radius: 100%;
            display: inline-block;
            background: #333;
            padding-top: 22px;
            i {
                font-size: 28px;
                color: white;
            }
        }
        .adboard-card__add-new-text {
            font-size: 25px;
            margin-top: 13px;
            color: #8C8D87;
        }
    }

    .adboard-card__action-bttns {
        display: inline-block;

        .adboard-card__action-bttn {
            text-decoration: none !important;
            font-size: 20px;
            color: $colorCardText;
            line-height: 1;
            &:hover {
                color:black;
            }
        }
    }

    .adboard-card__open-btn {
        height: 30px;
        width: 30px;
        border: 1px solid $colorCardText;
        background-color: transparent;
        border-radius: 100%;
        display: inline-block;
        text-align: center;
        padding-top: 2px;
        position: relative;
        top: -5px;
        cursor: pointer;
    }

    .adboard-card__open-btn-dot {
        height: 4px;
        width: 4px;
        background-color: $colorCardText;
        display: inline-block;
        border-radius: 100%;

    }

}
