//Extends mcm-sidebar class
.adboard-group-sidebar {
    $paddingLeftForSidebar: 25px;

    width: 300px;
    height: 100%;
    position: fixed;
    z-index: 6;

    .adboard-group-sidebar__content-area {
        overflow-y: auto;
        overflow-x: hidden;
        height: 90%;
        margin-top: 10px;
        .mCSB_inside > .mCSB_container {
            margin-right: 0;
        }
    }

    &--closed {
        width: 58px;
        text-align: center;

        
    }

    &--opened {

        .mcm-sidebar-back-btn {
            padding-left: $paddingLeftForSidebar;
            padding-right: 15px;
            text-align: left;
        }

        .mcm-sidebar-back-btn__img {
            background: #999;
            margin: 0 auto;
            display: inherit;
        }
    }

    .adboard-group-sidebar__title {
        font-weight: 600;
        font-size: 20px;
    }

    .mcm-separator-line {
        background: rgba(255, 255, 255, 0.5);
    }

    .mcm-sidebar-back-btn {
        color: $color_text;
        @include fontMainLight;
        padding: 20px 0;
        &:hover {
            text-decoration: none;
        }
        //icon font for <
        .lnr {
            font-size: 0.9em;
        }
    }

    .adboard-group-sidebar__open-btn {
        font-size: 35px;
        padding-left: 13px;
    }

    .adboard-group-sidebar__content {
        color: $color_text;
        // padding-left: $paddingLeftForSidebar;
        // padding-right: 15px;
    }

    .adboard-group-sidebar__users {
        list-style: none;
        padding: 0;
        margin: 20px 0;
        li {
            border-bottom: 1px solid #dcdcdc;
        }
    }

    .adboard-group-sidebar__events {
        list-style: none;
        padding: 0;
    }

    .adboard-group-sidebar__header {
        border-bottom: 1px solid #dcdcdc;
        height: 62px;
        text-align: center;
    }

}
