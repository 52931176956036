// shame css for quick fixes here .. (this sheet must be temporary. final css must be transferred to relevant sheets )
.cards-item {
  width: 31%;
  @include mq($until: desktop) {
    width: 100%;
  }
}

.modal-body {
  .trumbowyg-box {
    width: 100%;
    @include null(margin-right margin-left);
  }
  .trumbowyg-editor, .trumbowyg-textarea, .trumbowyg-box {
    min-height: auto;
  }
}

.form-control + .select2 {
  margin-top: 5px;
  .select2-selection {
    border-width: 0 0 1px 0;
    border-color: #333;
    border-radius: 0;
  }
}

.users-overview .user-overview__filter .user-overview__filter-roles li.current-item a,
.users-overview .user-overview__filter .user-overview__filter-roles li:hover a {
  color: #fff;
}

.form-is-updating {
  pointer-events: none;
  opacity: 0.5;
  transition: 0.5s all;
}

.tp-ef-countdown {
  color: #f26c54;
  text-align: center;
  td {
    padding-right: 20px;
  }
  .tp-ef-countdown__upper {
    font-size: 24px;
    font-weight: bold;
  }
}

.service-package-select {
  margin: 3px 0 0 10px;
}

.selected-package {
  label {
    color: black;
    font-weight: bold;
  }
}

.color-sample-display {
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 100%;
  flex: 0 0 25px;
  @include size(25px);
  @extend .shadow-for-box;
}

.listing-do-checkbox {
  margin: 0;

  .listing-do-checkbox__label {
    font-size: 12px;
  }
}

.tp-sub {
  .tp-sub__back-btn {
    padding: 20px 20px;
    a {
      font-size: 16px;
      i {
        font-size: 20px;
        margin: 0 10px 0 0;
        position: relative;
        top: 2px;
      }
    }
  }
}

.tp-icon-nav {
  color: white;
  a {
    text-decoration: none;
  }
  .tp-icon-nav__item {
    color: white;
    border: 1px dashed white;
    border-radius: 100%;
    text-align: center;
    padding: 5px;
    width: 40px;
    height: 40px;
    font-size: 16px;
    display: inline-block;
    float: left;
    transition: 0.3s all;
    @include flaticonFontSize(20px);

    .fa {
      font-size: 20px;
      position: relative;
      top: 3px;
    }

    &.current {
      border: 1px solid white;
    }
    &.passed, &:hover {
      border: 1px solid white;
      background: white;
      color: $color_darkgreen;
    }
  }

  .tp-icon-nav__sep {
    width: 40px;
    height: 1px;
    border-top: 1px dashed white;
    display: inline-block;
    float: left;
    position: relative;
    top: 19px;
    &.passed {
      border-top: 1px solid white;
    }
  }
}

.super-special-select .select2-container {
  width: 100% !important;
}

.speakers-card-tp {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .mcm-box-container {
    flex: 0 1 49%;
    &:nth-child(odd) {
      margin-right: 1%;
    }
  }
}

.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

@keyframes slideInRight {
  from {
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.slideInRight {
  animation-name: slideInRight;
}

// additional modal version for bigger ones

/* Large desktop */
@media (min-width: 1200px) {
  .modal-xl {  width: 1200px;  }
}

/* xLarge desktop */
@media (min-width: 1441px) {
  .modal-xl {  width: 1400px;  }
}

/* Portrait tablet to landscape and desktop */
@media (min-width: 768px) and (max-width: 979px) {
  .modal-xl {  width: 900px;  }
}


//logo for sanofi
.client-id-12 .mcm-navigation-logo {
  max-width: 260px;
}

.fa {
  vertical-align: middle;
}
