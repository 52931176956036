.add-area-form-container {
    .gr-container {
        background: white;
    }

    .add-area-form-container__btn-area {
        background: #E9E7E4;
        padding: 20px 0;
    }

    .owner-selection-list__input {
        margin-top: 10px;
    }

    .select2-container {
       // width: 90% ;
    }
    //hide auto loabel
    .select.input label {
        display: none !important;
    }

    .selectric {
        border-bottom: 1px solid #000;
    }

    .selectric .button {
        color:black;
        &:after {
            border-top-color:black;
        }
    }

}


.delete-btn-container {
    background: #E9E7E4;
    padding: 0px 20px 20px;
    a {
        color:#a70000;
    }
}