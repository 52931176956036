.appointment-reports{
    background-color:$white;
    padding:1.2em;
    overflow:auto;
    margin-top:2em;
    @extend .shadow-for-box;
    @media(min-width:$screen-sm){
        margin-top:0;
        height:500px;
    }
    h3{
        font-weight:300;
        margin: 20px 0;
        font-weight: 1.25em;
    }
    h4{
        font-size:1em;
        font-weight:600;
    }
}