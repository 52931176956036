.big-icon-with-line {
    margin: 20px 0;
    position: relative;
    .big-icon-with-line__icon-container {
        background: #fff;
        display: inline-block;
        width: 65px;
        height: 65px;
        border: 1px solid #4d5254;
        border-radius: 100%;
        @include flaticonFontSize(40px);
        text-align: center;
        z-index: 10;
        position: relative;
        padding-top: 4px;
        color: #4d5254;
    }
    .big-icon-with-line__line {
        height: 1px;
        background: #4d5254;
        width: 100%;
        position: absolute;
        top: 49%;
    }
}

.single-page-content {
    padding: 40px 50px;
}

[v-cloak] {
    display: none;
}

.change-link {
    color: $color_pastelBlue;
    font-size: 14px;
    cursor: pointer;
    text-decoration: underline;
    margin-left: 10px;
    &:hover {
        color: $color_pastelBlue +(-40);
    }
}
.delete-link {
    color: $color_pastelBlue;
    font-size: 14px;
    cursor: pointer;
    text-decoration: underline;
    margin-left: 10px;
    &:hover {
        color: $color_pastelBlue +(-40);
    }
}

.inline-edit-box {
    background: #e5e5e5;
    padding: 10px 20px;
    margin: 10px 0 20px;
    label.control-label {
        color: #333;
    }
}

.inner-back-button {
    margin: 10px 0;
    a {
        color: $color_textgrey;
    }
}

.shadow-for-box {
    box-shadow: 1px 1px 5px 1px #ccc;
}

.trumbowyg-editor, .trumbowyg-textarea {
    background: white;
}

//Vertically centered
.v-centered {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    .v-centered-content {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

/* always present */
.expand-transition {
    height: auto;
    overflow: hidden;
    transition: all .3s;
}

/* .expand-enter defines the starting state for entering */
/* .expand-leave defines the ending state for leaving */
.expand-enter, .expand-leave {
    height: 0;
    opacity: 0;
}

.expand-leave {
    height: 0;
    opacity: 0;
    transition: none;
}

/* always present */
.expandpanel-transition {
    height: auto;
    overflow: hidden;
}

/* .expand-enter defines the starting state for entering */
/* .expand-leave defines the ending state for leaving */
.expandpanel-enter, .expandpanel-leave {
    height: 0;
    opacity: 0;
    * {
        opacity: 0;
    }
}

.expandpanel-leave {
    height: 0;
    opacity: 0;
    transition: none;
}

.appearoutofnowhere-transition {
    transition: all .4s;
    transition-delay: 0.3s;
    opacity: 0;
    * {
        opacity: 0;
    }
}

.appearoutofnowhere-enter, .appearoutofnowhere-leave {

    opacity: 0;
    * {
        opacity: 0;
    }
}

.disappearoutofnowhere-transition {
    transition: all .4s;
    transition-delay: 0.3s;
    opacity: 1;
}

.disappearoutofnowhere-enter,
.disappearoutofnowhere-leave {

    opacity: 1;

}

.popover {
    border-radius: 0;
}

.popover-title {
    margin: 0;
    padding: 8px 14px;
    font-size: 14px;
    background-color: #303133;
    border-bottom: 1px solid #ebebeb;
    border-radius: 0;
}

.toggle--modal-helper-info-area {
    margin-left: 1em;

}

.checkbox label, .radio label, label {
    font-size: 14px;
    color: #4D5254;
    margin: 0 10px;
}

.close-edit-page {
    position: absolute;
    right: 0;
    font-size: 40px;
    color: #b3b3b3;
    text-decoration: none;
    cursor: pointer;
    transition: 0.2s all;
    &:hover, &:active, &:focus {
        text-decoration: none;
        color: #848282;
    }
}


.form-on-embedded-page {
}