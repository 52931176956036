.modal-backdrop {
    background: rgba(0, 0, 0, .6);
}
.modal-content{
    border-radius: 10px;
}
.modal-with-title {
    .modal-header {
        background: white !important;
        color: $color_textLight;
        border-bottom: 1px solid #e1e1e1 !important;
        text-align: center;
        padding-top: 15px;
        padding-bottom: 15px;
        border-radius: 10px 10px 0 0;
        .modal-title {
            @include fontMainBold;
            position: relative;
            top: 3px;
        }
        .close {
            color: $color_textLight;
            opacity: 1;
            font-size: 33px;
            position: absolute;
            right: 20px;
            z-index: 2;
            &:hover {
                color:$color_textLight + 20;
            }
        }
    }
}