// Core mixins
//-------------------------------------------------------------

//Set font size
@mixin fontSize($font-size, $line-height: true) {
    font-size: $font-size;
    font-size: ($font-size / $base-font-size) * 1rem;
    @if $line-height == true {
        line-height: ceil($font-size / $base-line-height) * ($base-line-height / $font-size);
    }

}

//Make columns
@mixin cssColumnMaker($no:2,$gap:20px) {
    -moz-column-count: $no;
    -moz-column-gap: $gap;
    -webkit-column-count: $no;
    -webkit-column-gap: $gap;
    column-count: $no;
    column-gap: $gap;
}

//Set flaticon font size
@mixin flaticonFontSize($fs: 1em) {
    [class^="flaticon-"]:before, [class*=" flaticon-"]:before, [class^="flaticon-"]:after, [class*=" flaticon-"]:after {
        font-size: $fs;
    }
}

//Set size
@mixin size($width:auto,$height:$width) {
    width: $width;
    height: $height;
}

//Set value to 0 || @param items = array
@mixin null($items,$size:0) {
    @each $item in $items {
        #{$item}: $size;
    }
}

//Center with margin auto
@mixin mCenter() {
    display: block;
    margin: auto;
}

//Center with absolute
@mixin abCenter($dir:null) {
    position: absolute;
    @include mCenter;
    @if $dir == null {
        @include null(top right bottom left);
    } @else {
        @include null(top bottom $dir);
    }
}

//Center with flex
@mixin flexCenter() {
    display: flex;
    justify-content: center;
    align-items: center;
}

//Reset an anchor tag
@mixin resetAnchor($color:inherit,$display:block) {
    display: $display;
    color: $color !important;
    text-decoration: none !important;
}

//Box item mixin
@mixin box() {
    border-radius: 3px;
    background: #ECECEC;
    box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.25);
}

@mixin shadowForBox() {
    box-shadow: 1px 1px 3px 1px #ccc;
    transition: 0.3s box-shadow;
    &:hover {
        box-shadow: 1px 1px 5px 3px #ccc;
    }
}

//Hide an element with visability
@mixin hideVisability() {
    visibility: hidden;
    @include size(1px);
}

//Center element in center
@mixin flexCenter() {
    display: flex;
    align-items: center;
    justify-content: center;
}

//Hide footer pusher
@mixin hideSiteContentPush() {
    + div.site-content-push {
        display: none;
    }
}

//Change bootstrap materilize border color
@mixin changeFormControlColors($color) {
    background-image: linear-gradient($color, $color), linear-gradient($color, $color);
}

//---------
// Because we have full width white lines between items
// it is generally hard to use wrapper item paddings in sidebar so
//se can use this mixin
@mixin sidebarItemPaddings{
    padding-left: 25px;
    padding-right: 15px;
}

.sidebar-pads {
    @include sidebarItemPaddings;
}