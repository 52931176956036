.add-client-form-container {

    .gr-container {
        background: white;
    }

    .add-client-form-container__btn-area {
        background: #E9E7E4;
        padding: 20px 0;
    }
    //extending form-sections
    .form-section {

        &.form-section--blue {
            background: #5fa7e5;
            .form-group label.control-label {
                color: #f5f7ff;
            }
            .form-group .help-block {
                color: #f5f7ff;
            }

            .form-control,
            .form-group .form-control {
                border: 0;
                background-image: linear-gradient(white, white), linear-gradient(white, white);
                color: white;
            }
        }
        &.form-section--darkblue {

            background: #589bd4;
            background-image: linear-gradient(-180deg, #518FC4 54%, #5391C7 78%, #5494CB 100%);

            .form-group label.control-label {
                color: #f5f7ff;
            }
            .form-group .help-block {
                color: #f5f7ff;
            }

            .form-control,
            .form-group .form-control {
                border: 0;
                background-image: linear-gradient(white, white), linear-gradient(white, white);
                color: white;
            }
        }

        &.form-section--lightgrey {

            background: #d7d6d5;

            .form-control,
            .form-group .form-control {
                border: 0;
                background-image: linear-gradient(#7b7c80, #7b7c80), linear-gradient(#7b7c80, #7b7c80);
            }

        }
        &.form-section--grey {

            background: #51555a;

            .form-control,
            .form-group .form-control {
                border: 0;
                background-image: linear-gradient(white, white), linear-gradient(white, white);
                color: white;
            }
        }

        .form-section__plain-button {
            text-transform: none;
            color: #5cb85c;
            cursor: pointer;
            margin: 10px 0 0;
            display: inline-block;
        }

        .form-section__logo-container {
            margin: 10px 0;

        }

    }

    //Selector
    span.select2-selection.select2-selection--multiple {
        background: transparent;
        border: 0;
        background-size: 0 2px, 100% 1px;
        background-repeat: no-repeat;
        background-position: center bottom, center calc(100% - 1px);
        background-color: transparent;
        background-image: linear-gradient(white, white), linear-gradient(white, white);
        color: white;
    }

    .select2-container--default .select2-selection--multiple .select2-selection__choice {
        background: $color_secondary;
        color: white;
    }

    .select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
        color: white;
    }

    .select2-container {
        width: 90% !important;
    }

    .client-form-broadcast-settings {
        list-style: none;
        ul {
            list-style: none;
            padding-left: 10px;
        }
        label {
            color: #333;
        }
    }

}
