.create-touchpoint {

    .stepped-form-numbers{
        margin: 0px 0 50px;
    }

    .duration-time-label{
        margin:0 5px;
    }
}

.create-touchpoint__types {
    width: 50%;
    margin: 20px auto;

    .btn {
        cursor: pointer;
        opacity: 0.5;
        &.btn--selected {
            opacity: 1;
        }
    }

}

.create-touchpoint__settings {
    display: flex;
    justify-content: center;
    //align-items: flex-start;
    min-height: 100%;
    flex-wrap: wrap;
    align-items: stretch;
    margin: 50px 0;
}

.create-touchpoint__setting {
    min-height: 100%;
    width: 22%;
    margin: 0 1.5%;
    flex: 1;
    border-radius: 5px;
    border: 1px solid #ccc;
    padding: 50px 10px;
    cursor: pointer;
    text-align: center;
    position: relative;
    transition: 0.3s all;
    user-select: none;
    &.selected {
        border: 1px solid #333;
    }
    h4 {
        margin:0 0 2em;
    }
    p {
        line-height: 1.5;
    }
}

.create-touchpoint__setting-check {
    position: absolute;
    top: -30px;
    left: 50%;
    margin-left: -30px;
    padding: 0 2px;
    background: white;
    .create-touchpoint-check {
        width: 60px;
        height: 60px;
        border-radius: 100%;
        background: #0d47a1;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
    }
    i {
        font-size: 20px;
    }
}
