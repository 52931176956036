.hcp-rating-wrapper{
  padding: 50px 0;
  .overflow-col{
    max-height: 550px;
    overflow-y: auto;
    margin-left: -5px;
    margin-right: -5px;
    padding-left: 5px;
    padding-right: 5px;
    //padding-top: 20px;
  }
  .question-list-col{
      .item{
        background-color: $white;
        padding: 30px 10px;
        margin: 0 0 20px;
        display: flex;
        align-items: center;
        cursor: pointer;
        transition: all 0.3 ease;
        .item-left{
          width: 40px;
          flex: 0 0 40px;
          max-width: 40px;
          text-align: center;
        }
        &.add-new-item{
          font-size: 18px;
          padding-top: 40px;
          padding-bottom: 40px;
          margin-bottom: 20px;
          .text{
            text-transform: uppercase;
          }
        }
        &.single-question-item{
          box-shadow: 0px 2px 5px 0px rgba(74,74,74,0.3);
          &:hover{
            background-color: darken($white, 5%);
          }
          .list-number{
            border: solid 1px $gray-light;
            border-radius: 5px;
            width: 30px;
            height: 30px;
            line-height: 28px;
            font-size: 18px;
          }
          .text{
            text-overflow: ellipsis;
            //white-space: nowrap;
            overflow: hidden;
            max-height: 40px;
            width: 80%; // some width

            font-size: 16px;
            line-height: 1.2em;
          }
        }
        &.active-item{
          background-color: $sanofi-purple;
          color: $white;
          &:hover{
            background-color: darken($sanofi-purple, 5%);
          }
        }
      }
  }

  .question-content{
    background-color: $white;
    padding: 30px;
    min-height: 400px;
    position: relative;
    .textarea{
      width: 100%;
      min-height: 100px;
      border: solid 1px $gray-light;
      resize: vertical;
      padding: 10px;
      font-size: 16px;
      &::-webkit-input-placeholder {
        color: $gray-light;
        text-align: center;
      }
      &:-moz-placeholder {
        color: $gray-light;
        text-align: center;
      }
      &::-moz-placeholder {
        color: $gray-light;
        text-align: center;
      }
      &:-ms-input-placeholder {
        color: $gray-light;
        text-align: center;
      }
      &:focus{
        &::-webkit-input-placeholder {
          color: transparent;
        }
        &:-moz-placeholder {
          color: transparent;
        }
        &::-moz-placeholder {
          color: transparent;
        }
        &:-ms-input-placeholder {
          color: transparent;
        }
      }
    }
    .wrap-buttons{
      padding-top: 10px;
      text-align: right;
      .btn{
        font-size: 16px;
      }
      .add-btn{
        background-color: $sanofi-purple;
        color: $white;
        border: 1px solid $sanofi-purple;
        &:focus,
        &:hover{
          background-color: darken($sanofi-purple, 5%);
        }
        &.btn-disable{
          color: $gray;
          background-color: $gray-light;
          border-color: $gray-light;
          cursor: not-allowed;
          &:focus,
          &:hover{
            color: $gray;
            background-color: $gray-light;
            border-color: $gray-light;
          }
        }
      }
      .btn-update{
        background-color: $sanofi-purple;
        color: $white;
        border: 1px solid $sanofi-purple;
        &:focus,
        &:hover{
          background-color: darken($sanofi-purple, 5%);
        }
        &.btn-disable{
          color: $gray;
          background-color: $gray-light;
          border-color: $gray-light;
          cursor: not-allowed;
          &:focus,
          &:hover{
            color: $gray;
            background-color: $gray-light;
            border-color: $gray-light;
          }
        }
      }
      .btn-delete{
        background-color: $white;
        color: $sanofi-purple;
        border: 1px solid $sanofi-purple;
        &:focus,
        &:hover{
          background-color: darken($white, 5%);
        }
      }
    }
    .wrap-loader{
      background-color: rgba(255, 255, 255, 0.7);
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;
    }
  }
}


