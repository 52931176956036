//.speakers-bureau
.speakers-bureau {
    overflow: auto;

    //.speakers-bureau .gr-container
    .gr-container {
        padding: 0;
    }

    //.speakers-bureau.speakers-bureau--touchpoints
    &.speakers-bureau--touchpoints {
        margin-top: 80px;
        margin-bottom: 60px;

        @include mq($until: desktop) {
            margin: 30px 0;
        }

        //.speakers-bureau.speakers-bureau--touchpoints .speakers-bureau__card
        .speakers-bureau__card {

            //.speakers-bureau.speakers-bureau--touchpoints .speakers-bureau__card.speakers-bureau__card--greybg
            &.speakers-bureau__card--greybg {
                background: #eeeded;
                @include null(border-top border-bottom, '1px solid #ccc');
            }

            //.speakers-bureau.speakers-bureau--touchpoints .speakers-bureau__card.speakers-bureau__content
            .speakers-bureau__content {
                padding-top: 40px;
                padding-left: 50px;
                width: 65%;
                max-width: none;
            }
        }
    }

    //.speakers-bureau__filters-container
    &__filters-container {
        margin-bottom: 20px;
        padding: 0 15px;
    }

    //.speakers-bureau__image-container
    &__image-container {
        float: left;
        display: inline-block;
        position: relative;
        @include size(150px);

        img {
            @include size(100%);
        }
    }

    //.speakers-bureau__search
    &__search {
        position: relative;

        //.speakers-bureau__search-container
        &-container {
            padding: 15px 0 0;
        }

        //.speakers-bureau__search .form-group
        .form-group {
            margin: 0;
            position: relative;
            margin-top: -10px;
        }

        //.speakers-bureau__search-button
        &-button {
            background: transparent;
            position: absolute;
            top: 10px;
            color: #75bee9;
            transition: 0.3s all;
            @include null(border right);
        }
    }

    //.speakers-bureau__card
    &__card {
        float: left;
        position: relative;
        overflow: hidden;
        background: white;
        width: 100%;

        &:hover {

            //.speakers-bureau__card .speakers-bureau__buttons
            .speakers-bureau__buttons {
                display: block;
                position: absolute;
            }
        }

        //.speakers-bureau__card .speakers-bureau__card--right
        &.speakers-bureau__card--right {
            background: #eeeded;

            //.speakers-bureau__card .speakers-bureau__card--right .speakers-bureau__image-container
            .speakers-bureau__image-container {
                float: right;
            }

            //.speakers-bureau__card .speakers-bureau__card--right .speakers-bureau__arrow
            .speakers-bureau__arrow {
                left: -1px;
                right: auto;
                top: 50%;
                color: #eeeded;

                //.speakers-bureau__card .speakers-bureau__card--right .fa-caret-left
                .fa-caret-left {
                    display: none;
                }

                //.speakers-bureau__card .speakers-bureau__card--right .fa-caret-right
                .fa-caret-right {
                    color: #eeeded;
                    display: inline-block;
                }
            }
        }

        //.speakers-bureau__card .speakers-bureau__buttons
        .speakers-bureau__buttons {
            display: none;
            position: absolute;
            @include null(bottom left, 10px);

            //.speakers-bureau__card .speakers-bureau__buttons .round-button
            .round-button {
                padding-top: 5px;

                //.speakers-bureau__card .speakers-bureau__buttons .round-button.area-info-card__button--delete
                &.area-info-card__button--delete {
                    color: red;
                }
            }
        }

        //.speakers-bureau__card .speakers-bureau__content
        .speakers-bureau__content {
            display: inline-block;
            float: left;
            padding: 25px 20px 0;
            max-width: 330px;

            //.speakers-bureau__card .speakers-bureau__content-name
            &-name {
                font-weight: 600;
                font-size: 16px;
                margin: 0 0 2px;

                //.speakers-bureau__card .speakers-bureau__content-name a
                a {
                    color: $color_text;
                }
            }

            .speakers-bureau__content-specialization {
                font-size: 14px;
                margin: 0 0 10px;
            }
        }

        //.speakers-bureau__card .speakers-bureau__arrow
        .speakers-bureau__arrow {
            color: $white;
            position: absolute;
            font-size: 30px;
            top: 50%;
            right: -1px;
            margin-top: -15px;

            //.speakers-bureau__arrow .fa-caret-right
            .fa-caret-right {
                display: none;
            }
        }
    }
}

//.speakers-details
.speaker-details {

    //.speakers-details .form-group label.control-label
    .form-group label.control-label {
        display: block;
        margin: 5px 0;
    }

    //.speaker-details__text
    &__text {
        color: $white;
        margin: 10px 0;
        font-size: 1.2em;
    }

    //.speaker-details__table
    &__table {
        margin: 30px 33px 0;

        //.speaker-details__table td, .speaker-details__table tr
        td, tr {
            border: 0;
            color: $white;
        }

        //.speaker-details__table .speaker-details__table-label
        .speaker-details__table-label {
            width: 130px;
            font-weight: bold;
        }
    }
}
